import { apiget } from "./API";
import { toast } from "react-toastify";

export const checkUserToken = async (navigate, isLoggedIn, isBackEndLogin, updateToken, getCustomerType) => {
    // Logout If Token Expire
    localStorage.clear();
    window.location.replace('/');
}

export const getWishList = async (setGetWishListItems = () => {}) => {
    const result = await apiget('wishlist/get-wishlist');
    if (result && result.data.status === false) {
        toast.error(result.data.message);
    }

    if (result && result.data.status === true) {
        setGetWishListItems(result.data.data);
    }
};

export const getCart = async (setGetCartDetails = () => {}, updateCartCount = () => {}) => {
    const result = await apiget('cart/get-cart');
    if (result && result.data.status === true) {
        // setGetCartDetails(result.data.data);
        // updateCartCount(result.data.data.total_items);
        if (typeof setGetCartDetails === 'function') {
            setGetCartDetails(result.data.data);
        }
        if (typeof updateCartCount === 'function') {
            console.log('updateCartCount');
            updateCartCount(result.data.data.total_items);
        }
        // console.log(result.data.data);
    }
};