import React, { useRef } from 'react'
import { apipost } from '../../../service/API';
import { toast } from 'react-toastify';

export default function ContactUs() {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const messageRef = useRef(null);
  const reasonForContactRef = useRef(null);

  const errorRefs = {
    name: useRef(null),
    email: useRef(null),
    phone_number: useRef(null),
    message: useRef(null),
    reason_for_contact: useRef(null),
  };

  const addContactUs = async (e) => {
    e.preventDefault();
    let data = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone_number: phoneNumberRef.current.value,
      message: messageRef.current.value,
      reason_for_contact: reasonForContactRef.current.value,
    };
    if (reasonForContactRef.current.value == 0) {
      data.reason_for_contact = "";
    } else {
      data.reason_for_contact = reasonForContactRef.current.value;
    }

    const result = await apipost("enquire/add-enquire", data);
    if (result && result.errors && result.errors.length > 0 && result.status === false) {
      Object.keys(errorRefs).forEach((field) => {
        if (errorRefs[field].current) {
          errorRefs[field].current.textContent = "";
        }
      });
      result.errors.forEach((err) => {
        if (errorRefs[err.field] && errorRefs[err.field].current) {
          errorRefs[err.field].current.textContent = err.message;
        }
      });
    } else {
      if (result && result.data && result.data.status === true) {
        Object.keys(errorRefs).forEach((field) => {
          errorRefs[field].current.textContent = "";
        });
        toast.success(result.data.message);
        nameRef.current.value = "";
        emailRef.current.value = "";
        phoneNumberRef.current.value = "";
        messageRef.current.value = "";
        reasonForContactRef.current.value = "0";
      }
    }
  };

  return (
    <React.Fragment>
      <section className="Quick-ContactForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mobile-hide">
              <img
                src="../../../asset/img/contactus-model.jpg"
                className="img-fluid rounded24"
                alt="Contact Us"
              />
            </div>
            <div className="col-lg-5 col-xl-5 col-md-6 col-sm-6 col-12">
              <h5 className="Heading-Large-2">
                Have a Query in mind? Get in touch!
              </h5>
              <p>
                We're here to help and answer any question you might have.
                We look forward to hearing from you. Any need help you
                please contact us or meet to office with coffee.
              </p>
              <form>
                <div className="row">
                  <div className="col-lg-6 col-xl-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        ref={nameRef}
                        placeholder="Enter Full Name"
                      />
                      <span
                        ref={errorRefs.name}
                        className="text-white"
                        style={{ minHeight: "20px", display: "block" }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        ref={phoneNumberRef}
                        placeholder="Mobile Number"
                      />
                      <span
                        ref={errorRefs.phone_number}
                        className="text-white"
                        style={{ minHeight: "20px", display: "block" }}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-xl-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        ref={emailRef}
                        placeholder="Email Id"
                      />
                      <span
                        ref={errorRefs.email}
                        className="text-white"
                        style={{ minHeight: "20px", display: "block" }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 col-12">
                    <div className="form-group">
                      <select
                        id="reason_for_contact"
                        name="reason_for_contact"
                        className="form-control"
                        defaultValue="0"
                        ref={reasonForContactRef}
                      >
                        <option value="0" disabled>
                          Select Reason For Contact
                        </option>
                        <option value="Channel Partners">
                          Channel Partners
                        </option>
                        <option value="Business Opportunity">
                          Business Opportunity
                        </option>
                        <option value="Feedback">Feedback</option>
                        <option value="Inquiry For Service">
                          Inquiry For Service
                        </option>
                        <option value="Inquiry For Module">
                          Inquiry For Module
                        </option>
                        <option value="Inquiry For Career">
                          Inquiry For Career
                        </option>
                      </select>
                      <span
                        ref={errorRefs.reason_for_contact}
                        className="text-white"
                        style={{ minHeight: "20px", display: "block" }}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        rows="2"
                        cols="100"
                        ref={messageRef}
                        placeholder="Enter Your Query"
                      ></textarea>
                      <span
                        ref={errorRefs.message}
                        className="text-white"
                        style={{ minHeight: "20px", display: "block" }}
                      ></span>
                    </div>
                  </div>
                </div>
                <button
                  onClick={addContactUs}
                  type="submit"
                  className="btn btn-dark w-100"
                >
                  Send your query
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
