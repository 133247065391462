import React, { useCallback, useEffect, useRef, useState } from "react";
import $ from "jquery";
import "malihu-custom-scrollbar-plugin";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../service/AuthContext";
import { apiget, apipost } from "../../../service/API";
import { toast } from "react-toastify";
import AuthModal from "../Layout/AuthModal";
import Loader from "../Layout/Loader";
import MobileHeader from "../Layout/MobileHeader";
// import MobileHeader from "../Layout/MobileHeader";

export default function ShopByCategory({ getCart, getCartDetails, getWishList, getNotifications }) {
  const { token, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate, deviceType } = useAuth();

  const { category_id, sub_category_id } = useParams();
  const activeElementRef = useRef(null);
  const hasFetchedGetItem = useRef(false);
  const hasFetchedGetSubSubCategory = useRef(false);
  const hasFetchedGetItms = useRef(false);
  const hasFetchedGetCategory = useRef(false);
  const [itemSpinner, setItemSpinner] = useState(false);
  const [subSubCategorySpinner, setSubSubCategorySpinner] = useState(false);
  const [subCategorySpinner, setSubCategorySpinner] = useState(false);
  const [categoriesSpinner, setCategoriesSpinner] = useState(false);
  const [subCategoryDetailSpinner, setSubCategoryDetailSpinner] = useState(false);
  const [cart_quantity_loder, setcartQuantityLoderLoader] = useState(false);
  const [getCategoryDetails, setGetCategoryDetails] = useState('');
  const [getSpotlightCategoryDetails, setGetSpotlightCategoryDetails] = useState('');
  const [getspotlightCategorId, setSpotlightCategorId] = useState('');
  const [getSubCategoryDetails, setGetSubCategoryDetails] = useState('');
  const [subSubCategoryIds, setSubSubCategoryIds] = useState([]);
  const [getSubSubCategoryDetails, setGetSubSubCategoryDetails] = useState('');
  const [spotlightOffersLoader, setSpotlightOffersLoader] = useState(true);
  const [addToWishlistLoader, setAddToWishlistLoader] = useState(false);
  const [getItems, setGetItems] = useState('');
  const debounceTimeout = useRef(null);
  const [inputValue, setInputValue] = useState({});
  const [initialValue, setInitialValue] = useState({});
  const [categoryName, getCategoryName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (getCategoryDetails.length > 0) {
      if(category_id === "spotlight-offers"){
        const category = getCategoryDetails.find(category => category.id === parseInt(sub_category_id));
        if (category) {
          getCategoryName(category.category_name);
        }

      } else {
        const category = getCategoryDetails.find(category => category.id === parseInt(category_id));
        if (category) {
          getCategoryName(category.category_name);
        }
      }
    }
  }, [getCategoryDetails, category_id]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  };

  useEffect(() => {
    if(deviceType === 'mobile'){
      if (activeElementRef.current) {
        activeElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  });

  const [handleQuantityChangeLoader, sethandleQuantityChangeLoader] = useState({});

  const getCategories = useCallback(async (categoryId, subCategoryId, call_next_api, loader_sub_sub_category_item, spot_light_offers_id) => {
    try {
      if (loader_sub_sub_category_item === 'loader_sub_sub_category_item') {
        setSubSubCategorySpinner(true);
      }
      else {
        setCategoriesSpinner(true);
      }
      if (token) {
        var result = await apiget('category/get-categories');
      }
      if (!token) {
        var result = await apiget('get-categories');
      }
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setCategoriesSpinner(false);
        return;
      }
      if (result && result.data && result.data.status === true) {
      setGetCategoryDetails(result.data.data);
      setSubSubCategoryIds([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if(categoryId !=="spotlight-offers"){
        getSubCategoryDetail(categoryId, subCategoryId, call_next_api);
      }
    }
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

  useEffect(() => {
    if (!hasFetchedGetCategory.current) {
      getCategories(category_id);
      if(category_id === "spotlight-offers"){
        getSpotLightOffers('spotlight-offers', undefined, 'category_loader');
      }
      hasFetchedGetCategory.current = true;
    }
  }, []);

  const getSubCategoryDetail = useCallback(async (categoryId, subCategoryId, call_next_api, category_loader) => {
    try {
      if (category_loader === 'category_loader') {
        setCategoriesSpinner(true);
      }
      if (call_next_api !== 'call_next_api') {
        // setSubCategoryDetailSpinner(true);
        setSubCategorySpinner(true);
      }
      let data = {
        category_id: categoryId ? categoryId.toString() : category_id
      }
      if (token) {
        var result = await apipost('category/get-sub-category', data);
      }
      if (!token) {
        var result = await apipost('get-sub-category', data);
      }
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setSubCategorySpinner(false);
        setSubCategoryDetailSpinner(false);
        return;
      }
      if (result && result.data && result.data.status === true) {
        setGetSubCategoryDetails(result.data.data);
        document.querySelector('#subCategory-offcanvas').click();
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (call_next_api === 'call_next_api') {
        getSubSubCategoryDetail(categoryId, subCategoryId, '', call_next_api);
        setSubSubCategoryIds([]);
      }
      setSubCategorySpinner(false);
      setSubCategoryDetailSpinner(false);
    }
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, category_id, updateUserTokenValidate, userTokenValidate]);
  
  const getSpotLightOffers = useCallback(async (categoryId, get_offer_item, category_loader) => {
      if(category_loader === "category_loader"){
        setCategoriesSpinner(true);
        setSpotlightOffersLoader(true);
      }
      setItemSpinner(true);
        
      document.querySelector('#subCategory-offcanvas').click();
      let data = {
        category_id: ''
      }
      var result = await apipost('category/get-spotlight-offers', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setItemSpinner(false);
        setCategoriesSpinner(false);
        setSpotlightOffersLoader(false);
        return;
      }
      
      if (result && result.data && result.data.status === true) {
        let spotlight_offers = result.data.data;
        let filtered_offers = spotlight_offers.filter(offer => offer.category_id === categoryId);
        
        setGetSpotlightCategoryDetails(result.data.get_category);
        setGetItems(categoryId !== "spotlight-offers" ? filtered_offers :result.data.data);
        setSpotlightCategorId(categoryId)
        setItemSpinner(false);
        setCategoriesSpinner(false);
        setSpotlightOffersLoader(false);
        setSubCategoryDetailSpinner(false);
        setGetSubCategoryDetails(false);
        setSubCategorySpinner(false);
        setItemSpinner(false);
      }
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, category_id, updateUserTokenValidate, userTokenValidate]);

  const getSubSubCategoryDetail = useCallback(async (categoryId, subCategoryId, subSubCategoryIds, call_next_api, loader_sub_sub_category_item) => {
    try {
      if (loader_sub_sub_category_item === 'loader_sub_sub_category_item') {
        setSubSubCategorySpinner(true);
      }
      let data = {
        category_id: categoryId ? categoryId.toString() : category_id,
        sub_category_id: subCategoryId !== undefined ? subCategoryId.toString() : sub_category_id
      }
      if (token) {
        var result = await apipost('category/get-sub-sub-category', data);
      }
      if (!token) {
        var result = await apipost('get-sub-sub-category', data);
      }
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setSubSubCategorySpinner(false);
      }
      if (result && result.status === false) {
        setSubSubCategoryIds([]);
        setGetSubSubCategoryDetails('');
        setTimeout(() => {
          setSubSubCategorySpinner(false);
        }, 500);
      }
      if (result && result.data && result.data.status === true) {
        setGetSubSubCategoryDetails(result.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (call_next_api === 'call_next_api') {
        getItem(categoryId, subCategoryId, subSubCategoryIds, loader_sub_sub_category_item);
        setSubSubCategoryIds([]);
      }
    }
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, category_id, updateUserTokenValidate, userTokenValidate]);

  useEffect(() => {
    
    if(category_id !== "spotlight-offers"){
      if (!hasFetchedGetSubSubCategory.current) {
        getSubSubCategoryDetail();
        hasFetchedGetSubSubCategory.current = true;
      }
    }
  }, []);

  const getItemAbortControllerRef = useRef(null);

  const getItem = useCallback(async (categoryId, subCategoryId, subSubCategoryIds, loader_item, scroll_to_top) => {
    // Cancel previous request if it exists
    if (getItemAbortControllerRef.current) {
      getItemAbortControllerRef.current.abort();
    }

    // Create a new AbortController instance for the new request
    const abortController = new AbortController();
    getItemAbortControllerRef.current = abortController;

    try {
      if (loader_item === 'loader_item') {
        setItemSpinner(true);
      }

      let data = {
        category_id: categoryId ? categoryId.toString() : category_id,
        sub_category_id: subCategoryId !== undefined ? subCategoryId.toString() : sub_category_id,
        sub_sub_category_id: subSubCategoryIds && subSubCategoryIds.join(','),
      };
      let result;
      if (token) {
        result = await apipost('category/get-items', data, { signal: abortController.signal });
      } else {
        result = await apipost('get-items', data, { signal: abortController.signal });
      }

      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setItemSpinner(false);
        return;
      }

      if (result && result.status === false) {
        if (scroll_to_top !== 'dont-scroll-to-top') {
          scrollToTop();
        }
        setItemSpinner(false);
        return;
      }

      if (result && result.data && result.data.data && result.data.status === true) {
        setGetItems(result.data.data);
        if (scroll_to_top !== 'dont-scroll-to-top') {
          scrollToTop();
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request was aborted');
      } else {
        console.error(error);
      }
    } finally {
      setCategoriesSpinner(false);

      setTimeout(() => {
        setSubSubCategorySpinner(false);
      }, getItems.length > 50 ? 2000 : 500);

      setTimeout(() => {
        setItemSpinner(false);
      }, 2000);
    }
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, category_id, updateUserTokenValidate, userTokenValidate]);

 useEffect(() => {
    if(category_id === "spotlight-offers"){
      hasFetchedGetItem.current = true;
    }
    if(category_id !== "spotlight-offers"){
    if (!hasFetchedGetItem.current) {
      getItem();
      hasFetchedGetItem.current = true;
    }
  }
  }, [getItem]);

  useEffect(() => {
    const fetchItems = async () => {
      if (!hasFetchedGetItms.current) {
        setSubCategoryDetailSpinner(true);
      }
      if (token) {
        if (!hasFetchedGetItms.current) {
          // setItemSpinner(true);
          if(getItems.length >! 0){
            
            try {
              await getCategories(category_id);
              if(category_id !== "spotlight-offers"){
              await getSubCategoryDetail();
              await getSubSubCategoryDetail();
              await getItem();
              
          }
              hasFetchedGetItms.current = true;
            } catch (error) {
            } finally {
              hasFetchedGetItms.current = true;
            }
          }
        }
      }
    };

    fetchItems();
  }, [token]);

  const debounce = (func, delay) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(func, delay);
  };

  const handleInputChange = (event, item_system_id, onFocus) => {
    let { value } = event.target;
    value = value.replace(/^0+/, '');
    if (/^\d*$/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }
      if(onFocus !== "onFocus") {
        setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));
      } else {
        setInitialValue(prevState => ({ ...prevState, [item_system_id]: value }));
      }
    }
  };

  const handleInputBlur = (event, item_system_id, sales_price_system_id, cart_id, item_no, item_sales_unit_of_measure, category_id, sub_category_id, sub_sub_category_id, item_group_code, GST_group_code, scroll_to_top) => {
    
    let { value } = event.target;
    value = value.replace(/^0+/, '');
    if (/^[0-9]*$/.test(value) && !/^0/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }
      if (initialValue[item_system_id] !== value) {
      setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));

      handleQuantityChange(item_system_id, sales_price_system_id, value > 0 ? value : 0, cart_id, item_no, item_sales_unit_of_measure, category_id, sub_category_id, sub_sub_category_id, item_group_code, scroll_to_top, GST_group_code);
      }
    }
  };

  const handleQuantityChange = (item_system_id, sales_price_system_id, newQuantity, cart_id, item_no, item_sales_unit_of_measure, category_id, sub_category_id, sub_sub_category_id, item_group_code, scroll_to_top, GST_group_code) => {
    sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: true }));
    setcartQuantityLoderLoader(true);
    if (newQuantity === 0) {
      removeItemFromCart(cart_id, scroll_to_top, item_system_id);
    } else if (newQuantity > 0) {
      newQuantity = Math.max(newQuantity, 1);
      var new_sales_price_system_id = '';
      getItems && getItems.forEach(wishlist => {
        const allValidPrice = wishlist.Prices.find(price => price.order === 1);
        if (wishlist.SystemId === item_system_id) {
          const maxPrice = allValidPrice && allValidPrice.Unit_Price;
          wishlist && wishlist.Prices.forEach(sales_price => {
            if (sales_price.Unit_Price !== maxPrice.toString() && sales_price.Minimum_Quantity !== '0' && sales_price.Minimum_Quantity !== '1') {
              if (newQuantity >= sales_price.Minimum_Quantity) {
                new_sales_price_system_id = sales_price.SystemId;
              } else {
                new_sales_price_system_id = sales_price_system_id;
              }
            } else {
              new_sales_price_system_id = sales_price_system_id;
            }
          });
        }
      });
      handleAddToCart(item_system_id, new_sales_price_system_id, newQuantity, item_no, item_sales_unit_of_measure, category_id, sub_category_id, sub_sub_category_id, item_group_code,GST_group_code, scroll_to_top);
    }
  };

  const handleAddToCart = useCallback(async (item_system_id, sales_price_system_id, quantity, item_no, item_sales_unit_of_measure, category_id, sub_category_id, sub_sub_category_id, item_group_code, GST_group_code, qty_per_unit_of_measure, item_unit_price, scroll_to_top) => {
    sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: true }));
    setcartQuantityLoderLoader(true);
    try {
      let data = {
        item_system_ids: item_system_id,
        sales_price_system_id: sales_price_system_id,
        quantity: quantity === undefined ? 1 : quantity,
        item_no: item_no,
        item_sales_unit_of_measure: item_sales_unit_of_measure,
        item_group_code: item_group_code,
        microsoft_quantity: qty_per_unit_of_measure,
        item_unit_price: item_unit_price,
        gst_group_code: GST_group_code
      }
      const result = await apipost('cart/add-to-cart', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: false }));
      }
      if (result && result.data && result.data.status === true) {
        toast.success(result.data.message);
        getCart();
        setInputValue(prevState => ({ ...prevState, [item_system_id]: quantity }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: false }));
      setInputValue(prevState => ({ ...prevState, [item_system_id]: quantity }));
      setcartQuantityLoderLoader(false);
    }
  }, [token]);

  const removeItemFromCart = async (cart_id, scroll_to_top, item_system_id) => {
    try {
      let data = {
        cart_id: cart_id
      };
      const result = await apipost('cart/delete-cart', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
      }
      if (result && result.data.status === false) {
        toast.error(result.data.message);
      }

      if (result && result.data.status === true) {
        const updatedSimilarItems = getItems && getItems.map(item =>
          item.SystemId === item_system_id ? { ...item, is_cart: 0, quantity: 0 } : item
        );
        setGetItems(updatedSimilarItems);

        toast.success(result.data.message);
        getCart();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setInputValue(prevState => ({ ...prevState, [item_system_id]: undefined }));
      sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: false }));
      setcartQuantityLoderLoader(false);
    }
  };

  const addItemToWishlist = async (item_system_id, sales_price_system_id) => {
    setAddToWishlistLoader(true);
    try {
      let data = {
        item_system_id: item_system_id,
        sales_price_system_id: sales_price_system_id
      };
      const result = await apipost('wishlist/add-to-wishlist', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
      }
      if (result && result.data && result.data.status === false) {
        toast.error(result.data.message);
      }
      if (result && result.data.status === true) {
        const updatedItems = getItems.map(item => {
          if (item.SystemId === item_system_id) {
            if (item.is_wishlist === 0) {
              return {
                ...item,
                is_wishlist: 1
              };
            } else {
              return {
                ...item,
                is_wishlist: 0
              };

            }
          }
          return item;
        });
        setGetItems(updatedItems);
        getWishList();
        toast.success(result.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally{
      setAddToWishlistLoader(false);
    }
  };

  const menuRef = useRef(null);
  const leftPointerRef = useRef(null);
  const rightPointerRef = useRef(null);

  useEffect(() => {
    if (itemSpinner === false && subSubCategorySpinner === false && subCategorySpinner === false && categoriesSpinner === false && subCategoryDetailSpinner === false && getCategoryDetails &&  getCategoryDetails.length > 0) {
      const lp = $(leftPointerRef.current);
      const rp = $(rightPointerRef.current);
      const mItems = $(menuRef.current);

      lp.click(() => {
        const sc = mItems.width() - 60;
        const pos = mItems.scrollLeft() - sc;
        mItems.animate({ scrollLeft: pos }, "slow");
      });

      rp.click(() => {
        const sc = mItems.width() - 60;
        const pos = mItems.scrollLeft() + sc;
        mItems.animate({ scrollLeft: pos }, "slow");
      });

      mItems.scroll(() => {
        const newScrollLeft = mItems.scrollLeft(),
          width = mItems.width(),
          scrollWidth = mItems.get(0).scrollWidth;
        const offset = 8;

        if (scrollWidth - newScrollLeft - width < offset) {
          rp.addClass("dis");
        } else {
          rp.removeClass("dis");
        }
        if (newScrollLeft === 0) {
          lp.addClass("dis");
        } else {
          lp.removeClass("dis");
        }
      });

      const slider1 = menuRef.current;
      let isDown = false;
      let startX;
      let scrollLeft;

      const mouseDownHandler = (e) => {
        isDown = true;
        slider1.classList.add("active");
        startX = e.pageX - slider1.offsetLeft;
        scrollLeft = slider1.scrollLeft;
      };

      const mouseLeaveHandler = () => {
        isDown = false;
        slider1.classList.remove("active");
      };

      const mouseUpHandler = () => {
        isDown = false;
        slider1.classList.remove("active");
      };

      const mouseMoveHandler = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider1.offsetLeft;
        const walk = (x - startX) * 3; // scroll-fast
        slider1.scrollLeft = scrollLeft - walk;
      };

      slider1.addEventListener("mousedown", mouseDownHandler);
      slider1.addEventListener("mouseleave", mouseLeaveHandler);
      slider1.addEventListener("mouseup", mouseUpHandler);
      slider1.addEventListener("mousemove", mouseMoveHandler);

      $(window).on("load", function () {
        $(menuRef.current).mCustomScrollbar({
          axis: "x",
          theme: "dark-3",
        });
      });

      return () => {
        slider1.removeEventListener("mousedown", mouseDownHandler);
        slider1.removeEventListener("mouseleave", mouseLeaveHandler);
        slider1.removeEventListener("mouseup", mouseUpHandler);
        slider1.removeEventListener("mousemove", mouseMoveHandler);
      };
    }
  }, []);

  return (
    <React.Fragment>
      <MobileHeader  header_title={
        <div className="col-10 p-0">
          <a type="button" data-bs-toggle={subCategoryDetailSpinner === false ? 'offcanvas' : ''} data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
            <p className="text-elipsis p-600 text-dark mb-0">
              {category_id !== "spotlight-offers" ? categoryName && categoryName : "Spotlight Offers"}
            </p>
            <p className="text-danger p-12 mb-0">Change Category<i className="bi bi-chevron-down"></i></p>
          </a>
        </div> } headerTitle="Change Category" getCartDetails={getCartDetails}
      />

      <div className="bodytop90 body-gray">
        {subCategoryDetailSpinner === false && getCategoryDetails &&  getCategoryDetails.length > 0 && (
          <section className="SubcategoryNavigation">
            <div className="container">
              <div className="HorizontalScrollnav">
                <div className="menu-item" ref={menuRef}>
                  {getCategoryDetails && getCategoryDetails.length > 0 && (
                    getCategoryDetails.map((getCategory, index) => {
                      return (
                        <React.Fragment key={getCategory.id}>
                          {getCategory.total_items >= 1 && (
                            <React.Fragment>
                              <Link key={getCategory.id} to={itemSpinner === false && categoriesSpinner === false && `/shop-by-category/${getCategory.id}`} className={`${getCategory.id === parseInt(category_id) ? "active" : ''}`} onClick={() => itemSpinner === false && categoriesSpinner === false && getSubCategoryDetail(getCategory.id, '', 'call_next_api', 'category_loader')}>{getCategory.category_name}</Link>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      );
                    })
                  )}
                  {token && ( 
                    <Link className={category_id === "spotlight-offers" ? "active" : ""} to={itemSpinner === false && categoriesSpinner === false && `/shop-by-category/spotlight-offers`} onClick={() =>itemSpinner === false && categoriesSpinner === false && getSpotLightOffers('spotlight-offers', undefined, 'category_loader')}>Spotlight Offers</Link>
                  )}
                </div>
                <span className="pointer left-pointer dis" ref={leftPointerRef}>
                  <i className="bi bi-chevron-left"></i>
                </span>
                <span className="pointer right-pointer" ref={rightPointerRef}>
                  <i className="bi bi-chevron-right"></i>
                </span>
              </div>
            </div>
          </section>
        )}
        {categoriesSpinner === false && (category_id === "spotlight-offers" ? spotlightOffersLoader === false : categoriesSpinner === false) ? (
          <section className="mob-pt-0 position-relative">
            {subCategoryDetailSpinner === false ? (
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-xl-3 col-3 mob-pl-0 position-relative">
                    {getSubCategoryDetails && getSubCategoryDetails.length > 0 && category_id !== "spotlight-offers"&& (
                      <div className="leftSidebar Mobile-fixed-sidebar">
                        <ul className="leftsidebar-nav" style={{ marginBottom: deviceType === 'mobile' ? '120px' : '' }}>
                          <li>
                            <Link to={subSubCategorySpinner === false && `/shop-by-category/${category_id}`} onClick={() => subSubCategorySpinner === false && getCategories(category_id, '', 'call_next_api', 'loader_sub_sub_category_item')} className={`${sub_category_id === undefined ? "active" : ""}`}>
                              <div className="row align-items-center">
                                <div className="col-lg-auto">
                                  <div className="icon-img">
                                    <img src="../../../asset/img/sub_category_image_all_v2.png" style={{ width: '48px', height: '48px' }} />
                                  </div>
                                </div>
                                <div className="col-lg-8 col-xl-8 col-12 p-0">
                                  All
                                </div>
                              </div>
                            </Link>
                          </li>
                          {getSubCategoryDetails && getSubCategoryDetails.map((get_sub_category, index) => (
                            <li key={get_sub_category.id}>
                              <React.Fragment>
                                <Link to={subSubCategorySpinner === false && `/shop-by-category/${get_sub_category.category_id}/${get_sub_category.id}`} className={`${get_sub_category.id === parseInt(sub_category_id) ? "active" : ""}`} key={index} onClick={() => subSubCategorySpinner === false &&getSubSubCategoryDetail(get_sub_category.category_id, get_sub_category.id, '', 'call_next_api', 'loader_sub_sub_category_item')} 
                                ref={get_sub_category.id === parseInt(sub_category_id) ? activeElementRef : null}>
                                  <div className="row align-items-center">
                                    <div className="col-lg-auto">
                                      <div className="icon-img">
                                        <img src={get_sub_category.sub_category_image ? get_sub_category.sub_category_image : '../../../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} />
                                      </div>
                                    </div>
                                    <div className="col-lg-8 col-xl-8 col-12 p-0">
                                      {get_sub_category.sub_category_name}
                                    </div>
                                  </div>
                                </Link>
                              </React.Fragment>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    
                    {getSpotlightCategoryDetails && getSpotlightCategoryDetails.length > 0 && category_id === "spotlight-offers" && token &&(
                      <div className="leftSidebar Mobile-fixed-sidebar">
                        <ul className="leftsidebar-nav" style={{ marginBottom: deviceType === 'mobile' ? '120px' : '' }}>
                          <li>
                            <Link onClick={() => getSpotLightOffers('spotlight-offers','get-offer-item')} className={`${getspotlightCategorId === "spotlight-offers" ? "active" : ""}`}>
                              <div className="row align-items-center">
                                <div className="col-lg-auto">
                                  <div className="icon-img">
                                    <img src="../../../asset/img/sub_category_image_all_v2.png" style={{ width: '48px', height: '48px' }} />
                                  </div>
                                </div>
                                <div className="col-lg-8 col-xl-8 col-12 p-0">
                                  All
                                </div>
                              </div>
                            </Link>
                          </li>
                          {getSpotlightCategoryDetails && getSpotlightCategoryDetails.map((get_spotlight_category, index) => (
                            <li key={get_spotlight_category.category_id}>
                              <React.Fragment>
                                <Link className={`${get_spotlight_category.category_id === getspotlightCategorId ? "active" : ""}`} key={index} onClick={() => getSpotLightOffers(get_spotlight_category.category_id)}>
                                  <div className="row align-items-center">
                                    <div className="col-lg-auto">
                                      <div className="icon-img">
                                        <img src={get_spotlight_category.category_image ? get_spotlight_category.category_image : '../../../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} />
                                      </div>
                                    </div>
                                    <div className="col-lg-8 col-xl-8 col-12 p-0">
                                      {get_spotlight_category.category_name}
                                    </div>
                                  </div>
                                </Link>
                              </React.Fragment>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {subSubCategorySpinner === false ? (
                    <div className="col-lg-9 col-xl-9 col-9">
                      {category_id !== "spotlight-offers" && (
                        <div className="row">
                          <div className="col-12 text-end mobile-show desktop-hide">
                            <a className="FilterModal-open" data-bs-toggle="offcanvas" href="#offcanvasFilter" role="button" aria-controls="offcanvasExample"><i className="bi bi-sliders"></i> Filters</a>
                          </div>
                          <div className="col-12 mobile-hide">
                            <ul className="Filternav">
                              {getSubSubCategoryDetails && getSubSubCategoryDetails.length > 0 &&(
                                getSubSubCategoryDetails.map((get_sub_sub_category, index) => {
                                  return (
                                    <li key={index} onClick={() => {
                                      const id = get_sub_sub_category.id;
                                      let newSubSubCategoryIds;

                                      if (subSubCategoryIds.includes(id)) {
                                        newSubSubCategoryIds = subSubCategoryIds.filter(subsubcategoryId => subsubcategoryId !== id); // Remove if exists
                                      } else {
                                        newSubSubCategoryIds = [...subSubCategoryIds, id]; // Add if not exists
                                      }

                                      setSubSubCategoryIds(newSubSubCategoryIds);
                                      getItem(parseInt(category_id), sub_category_id ? parseInt(sub_category_id) : '', newSubSubCategoryIds, 'loader_item');
                                    }} className="mb-3">
                                      <React.Fragment>
                                        <Link className={`${subSubCategoryIds.includes(get_sub_sub_category.id) ? "active" : ""}`}>
                                          {get_sub_sub_category.sub_sub_category_name} <i className="bi bi-x-lg ms-2"></i>
                                        </Link>
                                      </React.Fragment>
                                    </li>

                                  );
                                })
                              )}
                            </ul>
                          </div>
                        </div>
                      )}

                      {itemSpinner === false ? (
                        <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                          {getItems && getItems.length > 0 && (
                            getItems.map((get_item, index) => {
                              // Max Price Calculation
                              const allValidPrice = get_item.Prices.find(price => price.order === 1);

                              const maxPrice = allValidPrice && allValidPrice.Unit_Price;
                              const sales_price_system_id = allValidPrice && allValidPrice.SystemId;

                              const getCartDetail = getCartDetails && getCartDetails.get_cart.find((cart => cart.item_system_id === get_item.SystemId));

                              return (
                                maxPrice > 0 && (
                                  <div className="col-lg-4 col-xl-4 col-12 mb-4" key={get_item.SystemId}>
                                    <div className="product-item-card">
                                      {token ? (
                                        get_item && (
                                          get_item.is_wishlist === 1 ? (
                                            <a className="add-fav active" onClick={() => cart_quantity_loder === false && addToWishlistLoader === false && addItemToWishlist(get_item.SystemId, sales_price_system_id, 'dont-scroll-to-top')}><i className="bi bi-heart-fill"></i></a>
                                          ) : (
                                            <a className="add-fav" onClick={() => cart_quantity_loder === false && addToWishlistLoader === false && addItemToWishlist(get_item.SystemId, sales_price_system_id, 'dont-scroll-to-top')}><i className="bi bi-heart"></i></a>
                                          )
                                        )
                                      ) : (
                                        <Link className="add-fav" data-bs-toggle="modal" data-bs-target="#loginModal"><i className="bi bi-heart"></i></Link>
                                      )}

                                      <Link to={cart_quantity_loder === false && `/item-details/${get_item.SystemId}`} className="product-item-image text-decoration-none">
                                        <img src={get_item.image ? get_item.image : '../../../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} />
                                      </Link>
                                      <div className="card-body text-start">
                                        <Link to={cart_quantity_loder === false && `/item-details/${get_item.SystemId}`} className="product-title text-decoration-none">{get_item.Description}</Link>
                                      </div>
                                      <div className="card-footer text-start">
                                        {localStorage.getItem('customer_type') !== 'Retailer' && (
                                          <React.Fragment>
                                          {get_item && get_item.Prices.map((sales_price) => {
                                            if (sales_price.Unit_Price !== maxPrice.toString() && sales_price.Minimum_Quantity !== '0' && sales_price.Minimum_Quantity !== '1') {
                                              var is_total_quantity = 0;

                                              const total_Unit_Price = maxPrice - sales_price.Unit_Price;

                                              var discount_percentage = (total_Unit_Price * 100) / maxPrice;

                                              var cart_quantity = getCartDetail && getCartDetail.quantity;

                                              if (sales_price.Minimum_Quantity <= cart_quantity && cart_quantity.toString()) {
                                                is_total_quantity = 1;
                                                var discount_price = total_Unit_Price * cart_quantity;
                                              }
                                            }

                                            return (
                                              <React.Fragment key={sales_price.SystemId}>
                                                {token && (
                                                  sales_price.Unit_Price !== maxPrice.toString() && sales_price.Minimum_Quantity !== '0' && sales_price.Minimum_Quantity !== '1' && (
                                                    is_total_quantity === 1 ? (
                                                      <div key={sales_price.SystemId} className="offer-item-container">
                                                        <div className="product-item-offer">
                                                          <div className="row align-items-center">
                                                            <div className="col-lg-9 col-xl-9 col-9">
                                                              <p className="mb-0 text-success p-12">₹{discount_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} saved on {sales_price.Minimum_Quantity} {get_item.Base_Unit_of_Measure} (₹{sales_price.Unit_Price}/{get_item.Base_Unit_of_Measure})</p>
                                                            </div>
                                                            <div className="col-lg-3 col-xl-3 col-3 text-center">
                                                              <p className="mb-0 text-success"><i className="bi bi-check-circle-fill p-18"></i></p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <React.Fragment>
                                                        <div className="offer-item-container">
                                                          <div className="product-item-offer">
                                                            <div className="row align-items-center">
                                                              <div className="col-lg-9 col-xl-9 col-9">
                                                                <p className="mb-0 text-blue p-12">{discount_percentage && discount_percentage.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}% OFF for first {sales_price.Minimum_Quantity} (₹{sales_price.Unit_Price}/{get_item.Base_Unit_of_Measure})</p>
                                                              </div>
                                                              <div className="col-lg-3 col-xl-3 col-3 p-0 text-center">
                                                                <p className="mb-0 text-danger p-12" onClick={() => { cart_quantity_loder === false && addToWishlistLoader === false && handleAddToCart(get_item.SystemId, sales_price.SystemId, sales_price.Minimum_Quantity, get_item.No, get_item.Sales_Unit_of_Measure, category_id, sub_category_id, subSubCategoryIds, sales_price.Item_Group, get_item.GST_Group_Code, get_item.qtyPerUnitOfMeasure, sales_price.Unit_Price, 'dont-scroll-to-top') }}>Add {sales_price.Minimum_Quantity}</p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </React.Fragment>
                                                    )
                                                  )
                                                )}
                                              </React.Fragment>
                                            )
                                          })}

                                          <div className="row align-items-center card-footer-height">
                                            <div className="col-lg-7 col-xl-7 col-7">
                                              <Link to={cart_quantity_loder === false && `/item-details/${get_item.SystemId}`} className="mb-0 text-decoration-none">
                                                <strong>₹{maxPrice}/{get_item.Base_Unit_of_Measure !== '' && (
                                                  <span>{get_item.Base_Unit_of_Measure}</span>
                                                )}
                                                </strong>
                                                <p className="mb-0 text-muted">Multiples of {get_item.qtyPerUnitOfMeasure} {get_item.Base_Unit_of_Measure}</p>
                                              </Link>
                                            </div>
                                            {token ? (
                                              inputValue && inputValue[get_item.SystemId] === undefined && get_item.is_cart === 0 ? (
                                                getCartDetail && getCartDetail.quantity ? (
                                                  <Loader spinner={'button'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                                ) : inputValue && inputValue[get_item.SystemId] === undefined && get_item.is_cart === 0 && (
                                                  <div className="col-xl-5 col-lg-5 col-5">
                                                    <button type="button" className="btn add-btn w-100" onClick={() => {cart_quantity_loder === false && addToWishlistLoader === false && 
                                                      handleAddToCart(get_item.SystemId, sales_price_system_id, 1, get_item.No, get_item.Sales_Unit_of_Measure, category_id, sub_category_id, subSubCategoryIds, allValidPrice.Item_Group, get_item.GST_Group_Code, get_item.qtyPerUnitOfMeasure, allValidPrice.Unit_Price, 'dont-scroll-to-top');
                                                    }}>ADD <i className="bi bi-plus-lg ms-2"></i></button>
                                                  </div>
                                                )
                                              ) : (
                                                handleQuantityChangeLoader[get_item.SystemId] ? (
                                                  <Loader spinner={'button'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                                ) : (
                                                  <div className="col-xl-5 col-lg-5 col-5">
                                                    <div className="number">
                                                      <input type="text" className="pm-counter w-100" inputMode="numeric" pattern="[0-9]*" value={inputValue && inputValue[get_item.SystemId] !== undefined ? inputValue[get_item.SystemId] : getCartDetail && getCartDetail.quantity}  onChange={(e) => cart_quantity_loder === false && addToWishlistLoader === false && handleInputChange(e,get_item.SystemId)} onBlur={(e) => cart_quantity_loder === false && addToWishlistLoader === false && handleInputBlur(e, get_item.SystemId, sales_price_system_id, getCartDetail && getCartDetail.id, get_item.No, get_item.Sales_Unit_of_Measure, category_id, sub_category_id, subSubCategoryIds, allValidPrice.Item_Group, get_item.GST_Group_Code, get_item.qtyPerUnitOfMeasure, allValidPrice.Unit_Price, 'dont-scroll-to-top')} onFocus={(e) => handleInputChange(e, get_item.SystemId, "onFocus")} />  
                                                    </div>
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <div className="col-xl-5 col-lg-5 col-5">
                                                <button type="button" className="btn add-btn w-100" data-bs-toggle="modal" data-bs-target="#loginModal">ADD <i className="bi bi-plus-lg ms-2"></i></button>
                                              </div>
                                            )}
                                          </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              );
                            })
                          )}
                        </div>
                      ) : (
                        <Loader spinner={'item_loader'} />
                      )}
                    </div>
                  ) : (
                    <Loader spinner={'sub_sub_category_item_loader'} />
                  )}
                </div>
              </div>
            ) : (
              <Loader spinner={'sub_category_detail_loader'} />
            )}
          </section>
        ) : (
          <Loader spinner={'category_loader'} />
        )}

        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasFilter" aria-labelledby="offcanvasFilterLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasFilterLabel">Filter </h5>
            {/* <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
            <a type="button" className="close text-end mr-1 text-reset" data-bs-dismiss="offcanvas" style={{ fontSize: '20px', color: 'black' }} aria-label="Close">X</a>
          </div>
          <div className="offcanvas-body">
            <ul className="Filternav-block">
              {getSubSubCategoryDetails && getSubSubCategoryDetails.length > 0 && category_id !=="spotlight-offers" &&(
                getSubSubCategoryDetails.map((get_sub_sub_category, index) => {
                  return (
                    <li key={index} onClick={() => {
                      const id = get_sub_sub_category.id;
                      let newSubSubCategoryIds;

                      if (subSubCategoryIds.includes(id)) {
                        newSubSubCategoryIds = subSubCategoryIds.filter(subsubcategoryId => subsubcategoryId !== id); // Remove if exists
                      } else {
                        newSubSubCategoryIds = [...subSubCategoryIds, id]; // Add if not exists
                      }

                      setSubSubCategoryIds(newSubSubCategoryIds);
                      getItem(parseInt(category_id), sub_category_id ? parseInt(sub_category_id) : '', newSubSubCategoryIds, 'loader_item');
                    }} className="mb-3">
                      <React.Fragment>
                        <Link className={`${subSubCategoryIds.includes(get_sub_sub_category.id) ? "active" : ""}`}>
                          {get_sub_sub_category.sub_sub_category_name} <i className="bi bi-x-lg ms-2"></i>
                        </Link>
                      </React.Fragment>
                    </li>
                  );
                })
              )}
            </ul>
          </div>
        </div>

        <div className="offcanvas offcanvas-bottom category-offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasBottomLabel">{category_id !=="spotlight-offers" ? categoryName && categoryName :"Spotlight Offers"}</h5>
            {/* <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
            {/* <a type="button" id="subCategory-offcanvas" className="close text-end mr-1 text-reset" style={{ fontSize: '20px', color: 'black' }} data-bs-dismiss="offcanvas" aria-label="Close"></a> */}
            <a type="button" id="subCategory-offcanvas" className="close text-end mr-1" style={{ fontSize: '20px', color: 'black' }} data-bs-dismiss="offcanvas" aria-label="Close">X</a>
          </div>
          <div className="offcanvas-body small">
            <ul className="shopbycategoryList">
              {getCategoryDetails && getCategoryDetails.length > 0 && (
                getCategoryDetails.map((getCategory, index) => {
                  return (
                    <React.Fragment key={getCategory.id}>
                      {getCategory.total_items >= 1 && (
                        <React.Fragment>
                          <li>
                            <Link key={getCategory.id} to={itemSpinner === false && categoriesSpinner === false && `/shop-by-category/${getCategory.id}`}>
                              <img src={getCategory.category_image ? getCategory.category_image : '../../../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} onClick={() =>itemSpinner === false && categoriesSpinner === false &&  getSubCategoryDetail(getCategory.id, '', 'call_next_api', 'category_loader')} className="categoryIcon" />
                              <div className="categoryTitle">
                                {getCategory.category_name}
                              </div>
                            </Link>
                          </li>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })
              )}
              {token && ( 
                <li>
                  <Link  to={`/shop-by-category/spotlight-offers`}>
                    <img src={'../../../asset/img/no_image_available.jpg'}  onClick={() => itemSpinner === false && categoriesSpinner === false && getSpotLightOffers('spotlight-offers', undefined, 'category_loader')} className="categoryIcon" />
                    <div className="categoryTitle">
                      Spotlight Offers
                    </div>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {!token && (
        <AuthModal getCart={getCart} getWishList={getWishList} getNotifications={getNotifications}/>
      )}
    </React.Fragment>
  );
}
