import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";

export default function SubCategoryByCategory({ getSubCategoriesByCategoryDetail, GetCategoryAndItemCount }) {

  const OwlCarouselCategorySettings = {
    loop: false,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  return (
    <React.Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="Heading-Medium mb-5">Why choose Promot?</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div className="FlatCard">
                <div className="card-body">
                  <img src="./asset/img/quality.svg" className="mb-4" />
                  <h4>
                    <strong>Quality</strong>
                  </h4>
                  <h4>Consistency</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div className="FlatCard">
                <div className="card-body">
                  <img
                    src="./asset/img/singleVendor.svg"
                    className="mb-4"
                  />
                  <h4>
                    <strong>Leaders in cutting edge technology</strong>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
              <div className="FlatCard">
                <div className="card-body">
                  <img src="./asset/img/nextDay.svg" className="mb-4" />
                  <h4>
                    <strong>Highest electrical safety standards</strong>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 text-center">
              <h5 className="Heading-Medium">Buy just about anything</h5>
              <p className="p-22 text-muted">
                {getSubCategoriesByCategoryDetail.length} categories •{" "}
                {GetCategoryAndItemCount.count_total_item}+ products
              </p>
            </div>
          </div>

          {getSubCategoriesByCategoryDetail &&
            getSubCategoriesByCategoryDetail.length > 0 &&
            getSubCategoriesByCategoryDetail &&
            getSubCategoriesByCategoryDetail.map(
              (getItemByCategory) =>
                getItemByCategory.get_sub_category &&
                getItemByCategory.get_sub_category.length > 0 && (
                  <React.Fragment key={getItemByCategory.id}>
                    <React.Fragment>
                      <div className="row">
                        <div className="col-12">
                          <h5 className="Heading-Small">
                            <img
                              src={
                                getItemByCategory.category_image
                                  ? getItemByCategory.category_image
                                  : "./asset/img/no_image_available.jpg"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "./asset/img/no_image_available.jpg";
                              }}
                              width="46"
                            />
                            {getItemByCategory.category_name}
                            <Link
                              to={`/shop-by-category/${getItemByCategory.id}`}
                              className="float-end see-all"
                            >
                              See all
                            </Link>
                          </h5>
                        </div>
                      </div>

                      <OwlCarousel
                        className="vegetables-carousel owl-carousel owl-theme owl-loaded owl-drag"
                        {...OwlCarouselCategorySettings}
                      >
                        {getItemByCategory.get_sub_category.map(
                          (sub_category, index) => (
                            <React.Fragment key={index}>
                              <React.Fragment>
                                <Link
                                  to={`/shop-by-category/${sub_category.category_id}/${sub_category.id}`}
                                  className="text-decoration-none"
                                >
                                  <div className="item">
                                    <div className="image-card">
                                      <div className="image-containt image-card-height">
                                        <img
                                          src={
                                            sub_category.sub_category_image
                                              ? sub_category.sub_category_image
                                              : "./asset/img/no_image_available.jpg"
                                          }
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              "./asset/img/no_image_available.jpg";
                                          }}
                                          alt={
                                            sub_category.sub_category_name
                                          }
                                        />
                                      </div>
                                      <div className="category-name">
                                        {sub_category.sub_category_name}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </React.Fragment>
                            </React.Fragment>
                          )
                        )}
                      </OwlCarousel>
                    </React.Fragment>
                  </React.Fragment>
                )
            )}
        </div>
      </section>
    </React.Fragment>
  )
}
