import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from './service/AuthContext';
import './App.css';

import 'react-toastify/dist/ReactToastify.css';

// Theme Css
// import '../node_modules/bootstrap/dist/css/bootstrap.css';
// import './asset/css/style.css';
// import './asset/css/bootstrap-icons.css';

// Layout
import Header from './view/Component/Layout/Header';
import CustomerHeader from './view/Component/Layout/CustomerHeader.js';
import Footer from './view/Component/Layout/Footer';

// Home
import Home from './view/Component/Home/Home';
import HomeWithLogin from './view/Component/Home/HomeWithLogin';
import ShopByCategory from './view/Component/ShopByCategory/ShopByCategory';
import ItemDetails from './view/Component/Item/ItemDetails';
import { apiget } from './service/API';
import MyWishList from './view/Component/WishList/MyWishList';
import Cart from './view/Component/Cart/Cart';
import Address from './view/Component/Address/Address';
import AccountStatement from './view/Component/AccountStatement/account-statement.js';
import ProfileSetting from './view/Component/ProfileSetting/ProfileSetting.js';

// Check Out
import CheckOut from "./view/Component/CheckOut/CheckOut";

// My Order
import MyOrder from "./view/Component/Order/MyOrder";
import MyOrderDetail from "./view/Component/Order/MyOrderDetail";
import MyOrderItemWise from "./view/Component/Order/MyOrderItemWise";

// Customer Sales Invoice
import SalesInvoice from './view/Component/SalesInvoice/SalesInvoice';
import CustomerSalesInvoiceDetail from './view/Component/SalesInvoice/SalesInvoiceDetail';

// Customer Payment 
import SalesPayment from './view/Component/SalesPayment/SalesPayment';
import SalesPaymentDetail from './view/Component/SalesPayment/SalesPaymentDetail';
import Page from './view/Component/Page/Page';
import RequestNewProduct from './view/Component/RequestNewProduct/RequestNewProduct.js';
import ContactUs from './view/Component/ContactUs/ContactUs.js';
import Whatsapp from './view/Component/Layout/Whatsapp.js';
import CorporateAnnouncements from './view/Component/Page/corporate-announcements';
import FAQ from './view/Component/Page/FAQ';
import MyItemWiseOrderDetail from './view/Component/Order/MyItemWiseOrderDetail.js';
import PageNotFound from './view/Component/Page/PageNotFound.js';
import SearchResult from './view/Component/Page/SearchResult.js';
import AmmountToPay from './view/Component/SalesInvoice/AmmountToPay.js';
import CustomerList from './view/Component/CustomerList/CustomerList.js';
import ScrollToTop from './view/Component/Layout/ScrollToTop.js';
import AddRetailer from './view/Component/Retailer/AddRetailer.js';
import ListRetailer from './view/Component/Retailer/ListRetailer.js';

function App() {
    const { token, isLoggedIn, isBackEndLogin, checkIsLoggedIn, updateToken, userTokenValidate, updateUserTokenValidate, updateLoginUserName, backEndLogin, updateUserRoleType } = useAuth();

    const [getCartDetails, setGetCartDetails] = useState('');
    const [getWishListItems, setGetWishListItems] = useState('');
    const [notifications, setNotification] = useState('');
    const hasFetchedGetCart = useRef(false);
    const navigate = useNavigate();

    // Login From Backend
    useEffect(() => {
        isBackEndLogin('fa');
        if (backEndLogin !== 'true') {
            const params = new URLSearchParams(window.location.search);
            const user_token = params.get('token');
            const user_name = params.get('name');
            const userPhoneNo = params.get('phone_no');
            if (user_token) {
                localStorage.setItem('token', user_token);
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('isBackEndLogin', 'true');
                localStorage.setItem('role_type', 'Backend');

                updateToken(user_token);
                isLoggedIn('true');
                isBackEndLogin('true');
            }
            if (user_name) {
                localStorage.setItem('loginUserName', user_name);
                updateLoginUserName(user_name);
            }
            if (userPhoneNo) {
                localStorage.setItem('loginUserPhoneNo', userPhoneNo);
            }
            if (!hasFetchedGetCart.current) {
                getCart();
                getWishList();
                getNotifications();
                hasFetchedGetCart.current = true;
            }
            
            window.history.replaceState(null, '', window.location.pathname);
        }
      }, [updateToken, backEndLogin, isBackEndLogin, isLoggedIn, updateLoginUserName]);

    const getWishList  = useCallback(async () => {
        const result = await apiget('wishlist/get-wishlist');
        // console.log('result',result);
        if (result && result.status === false && result.error_type === 'check_token') {
            updateUserTokenValidate(false);
        }
        if (result && result.data && result.data.status === false) {
            toast.error(result.data.message);
        }

        if (result && result.data && result.data.status === true) {
            setGetWishListItems(result.data.data);
        }
    }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

    const getCart = useCallback(async () => {
        const result = await apiget('cart/get-cart');
        if (result && result.status === false && result.error_type === 'check_token') {
            updateUserTokenValidate(false);
        }
        if (result && result.data && result.data.status === true) {
            setGetCartDetails(result.data.data);
            // console.log('result', result);
        }
    }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

    // Get Notification API
    const getNotifications = useCallback(async () => {
      const getNotificationsResult = await apiget('order/get-notification');
      if (getNotificationsResult && getNotificationsResult.data && getNotificationsResult.data.status === true) {
        setNotification(getNotificationsResult.data.data);
  
      }
      if (getNotificationsResult && getNotificationsResult.data && getNotificationsResult.data.status === false) {
        toast.error(getNotificationsResult.data.message);
      }
    }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

    return (
        <React.Fragment>
            <ToastContainer />
            {localStorage.getItem('role_type') !== 'Frontend' ? (
                <React.Fragment>
                    <Header getCart={getCart} getWishList={getWishList} getCartDetails={getCartDetails} getWishListItems={getWishListItems} getNotifications={getNotifications} notifications={notifications}/>
                        <Routes>
                            {/* Home */}
                            <Route exact path="/" element={checkIsLoggedIn === 'true' ? <HomeWithLogin getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Home />} />

                            <Route exact path="/shop-by-category/:category_id?/:sub_category_id?" element={<ShopByCategory getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} getNotifications={getNotifications}/>} />

                            <Route exact path="/item-details/:item_system_id" element={<ItemDetails getCart={getCart} getWishList={getWishList} getCartDetails={getCartDetails} getWishListItems={getWishListItems} />} />

                            <Route exact path="/my-wishllist" element={checkIsLoggedIn === 'true' ? <MyWishList getCart={getCart} getCartDetails={getCartDetails}  getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />

                            <Route exact path="/my-cart" element={checkIsLoggedIn === 'true' ? <Cart getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />
                            
                            <Route exact path="/sales-invoice" element={checkIsLoggedIn === 'true' ? <SalesInvoice getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />
                            
                            <Route exact path="/sales-invoice-detail/:sales_invoice_header_system_id" element={checkIsLoggedIn === 'true' ? <CustomerSalesInvoiceDetail getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />

                            <Route exact path="/sales-payment" element={checkIsLoggedIn === 'true' ? <SalesPayment getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />

                            <Route exact path="/sales-payment-detail/:SystemId_Ledger" element={checkIsLoggedIn === 'true' ? <SalesPaymentDetail getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />

                            <Route exact path="/address" element={checkIsLoggedIn === 'true' ? <Address getCart={getCart} getWishList={getWishList} getWishListItems={getWishListItems}/> : <Navigate to="/" replace />} />

                            <Route exact path="/check-out" element={checkIsLoggedIn === 'true' ? <CheckOut getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} getNotifications={getNotifications} notifications={notifications}/> : <Navigate to="/" replace />} />

                            {/* My Order */}
                            <Route exact path="/order/my-order" element={checkIsLoggedIn === 'true' ? <MyOrder getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} /> : <Navigate to="/" replace />} />
                            <Route exact path="/order/my-order-detail/:order_id" element={checkIsLoggedIn === 'true' ? <MyOrderDetail getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getNotifications={getNotifications} notifications={notifications} />  : <Navigate to="/" replace />} />
                            <Route exact path="/order/my-order-item-wise" element={checkIsLoggedIn === 'true' ? <MyOrderItemWise getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} /> : <Navigate to="/" replace />} />

                            <Route exact path="/order/my-order-detail-item-wise/:order_detail_id" element={<MyItemWiseOrderDetail getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} />} />

                            {/* Page */}
                            <Route exact path="/page/:page_id" element={<Page getCart={getCart} getWishList={getWishList} />} />
                            <Route exact path="/page/corporate-announcements" element={<CorporateAnnouncements/>} />
                            <Route exact path="/page/FAQ" element={<FAQ/>} />

                            {/* Account Statement */}
                            <Route exact path="/account-statement" element={checkIsLoggedIn === 'true' ? <AccountStatement getCart={getCart} getWishList={getWishList} /> : <Navigate to="/" replace />} />

                            {/* Request new product */}
                            <Route exact path="/request-product" element={<RequestNewProduct getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} />} />

                            {/* Contact Us */}
                            <Route exact path="/contact-us" element={<ContactUs getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} />} />

                            {/* Profile Setting */}
                            <Route exact path="/profile-setting" element={<ProfileSetting getCartDetails={getCartDetails}/>} />

                            {/* Add Retailer */}
                            <Route exact path="/list-retailer" element={<ListRetailer getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/>} />
                            <Route exact path="/add-retailer" element={<AddRetailer getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/>} />

                            {/* Add Electrician */}
                            <Route exact path="/list-electrician" element={<ListRetailer getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/>} />
                            <Route exact path="/add-electrician" element={<AddRetailer getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/>} />

                            {/* Page Not Found */}
                            <Route path="*" element={<PageNotFound />} />

                            {/* Search Result */}
                            <Route exact path="/search-result" element={<SearchResult  getCart={getCart} getCartDetails={getCartDetails}/>} />

                            {/* Ammount To Pay */}
                            <Route exact path="/ammount-to-pay" element={<AmmountToPay  getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems}/>} />
                        </Routes>
                        <ScrollToTop />
                        <Whatsapp />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        
                    <CustomerHeader/>
                        <Routes>
                            <Route exact path="/customer-list" element={<CustomerList/>} />
                            
                            <Route exact path="/" element={localStorage.getItem('role_type') !== 'Frontend' ? <Home /> : <PageNotFound />} />

                            {/* Page Not Found */}
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                    </React.Fragment>
                )}
                <Footer getCartDetails={getCartDetails}/>
        </React.Fragment>
    );
}

export default App;
