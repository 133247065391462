import React, { useCallback, useEffect, useRef, useState, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from './service/AuthContext';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { apiget } from './service/API.js';
import Loader from './view/Component/Layout/Loader.js';

// Layout
const Header = lazy(() => import('./view/Component/Layout/Header'));
const CustomerHeader = lazy(() => import('./view/Component/Layout/CustomerHeader.js'));
const Footer = lazy(() => import('./view/Component/Layout/Footer'));
const ScrollToTop = lazy(() => import('./view/Component/Layout/ScrollToTop.js'));
const Whatsapp = lazy(() => import('./view/Component/Layout/Whatsapp.js'));
// Home
const Home = lazy(() => import('./view/Component/Home/Home'));
const HomeWithLogin = lazy(() => import('./view/Component/Home/HomeWithLogin'));
// Shop By Category
const ShopByCategory = lazy(() => import('./view/Component/ShopByCategory/ShopByCategory'));
// Item Details
const ItemDetails = lazy(() => import('./view/Component/Item/ItemDetails'));
// My WishList
const MyWishList = lazy(() => import('./view/Component/WishList/MyWishList'));
// Cart
const Cart = lazy(() => import('./view/Component/Cart/Cart'));
// Address
const Address = lazy(() => import('./view/Component/Address/Address'));
// Profile Setting
const ProfileSetting = lazy(() => import('./view/Component/ProfileSetting/ProfileSetting.js'));
// Check Out
const CheckOut = lazy(() => import("./view/Component/CheckOut/CheckOut"));
// My Order
const MyOrder = lazy(() => import("./view/Component/Order/MyOrder"));
const MyOrderDetail = lazy(() => import("./view/Component/Order/MyOrderDetail"));
const MyOrderItemWise = lazy(() => import("./view/Component/Order/MyOrderItemWise"));
const MyItemWiseOrderDetail = lazy(() => import('./view/Component/Order/MyItemWiseOrderDetail.js'));
// Customer Sales Invoice
const SalesInvoice = lazy(() => import('./view/Component/SalesInvoice/SalesInvoice'));
const CustomerSalesInvoiceDetail = lazy(() => import('./view/Component/SalesInvoice/SalesInvoiceDetail'));
// Customer Payment 
const SalesPayment = lazy(() => import('./view/Component/SalesPayment/SalesPayment'));
const SalesPaymentDetail = lazy(() => import('./view/Component/SalesPayment/SalesPaymentDetail'));
// Retailer
const ListRetailer = lazy(() => import('./view/Component/Retailer/ListRetailer.js'));
const AddRetailer = lazy(() => import('./view/Component/Retailer/AddRetailer.js'));
const EditRetailer = lazy(() => import('./view/Component/Retailer/EditRetailer.js'));
// Electrician
const ListElectrician = lazy(() => import('./view/Component/Electrician/ListElectrician.js'));
const AddElectrician = lazy(() => import('./view/Component/Electrician/AddElectrician.js'));
const EditElectrician = lazy(() => import('./view/Component/Electrician/EditElectrician.js'));
// Page
const Page = lazy(() => import('./view/Component/Page/Page'));
const CorporateAnnouncements = lazy(() => import('./view/Component/Page/corporate-announcements'));
const FAQ = lazy(() => import('./view/Component/Page/FAQ'));
// Request New Product
const RequestNewProduct = lazy(() => import('./view/Component/RequestNewProduct/RequestNewProduct.js'));
// Contact Us
const ContactUs = lazy(() => import('./view/Component/ContactUs/ContactUs.js'));
// Search Result
const SearchResult = lazy(() => import('./view/Component/Page/SearchResult.js'));
// Ammount To Pay
const AmmountToPay = lazy(() => import('./view/Component/SalesInvoice/AmmountToPay.js'));
// Page Not Found
const PageNotFound = lazy(() => import('./view/Component/Page/PageNotFound.js'));
// Customer List
const CustomerList = lazy(() => import('./view/Component/CustomerList/CustomerList.js'));

function App() {
    const { token, isLoggedIn, isBackEndLogin, checkIsLoggedIn, updateToken, updateLoginUserName, backEndLogin, updateCartCount, updateWishListCount, userRoleType } = useAuth();

    const [getCartDetails, setGetCartDetails] = useState('');
    const [getWishListItems, setGetWishListItems] = useState('');
    const [notifications, setNotification] = useState('');
    const hasFetchedGetCart = useRef(false);

    // Get Notification API
    const getNotifications = useCallback(async () => {
        const getNotificationsResult = await apiget('order/get-notification');
        if (getNotificationsResult?.data?.status === true) {
            setNotification(getNotificationsResult.data.data);
        } else {
            toast.error(getNotificationsResult.data.message);
        }
    }, []);

    // Login From Backend
    useEffect(() => {
        isBackEndLogin('fa');
        if (backEndLogin !== 'true') {
            const params = new URLSearchParams(window.location.search);
            const user_token = params.get('token');
            const user_name = params.get('name');
            const userPhoneNo = params.get('phone_no');
            if (user_token) {
                localStorage.setItem('token', user_token);
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('isBackEndLogin', 'true');
                localStorage.setItem('role_type', 'Backend');

                updateToken(user_token);
                isLoggedIn('true');
                isBackEndLogin('true');
            }
            if (user_name) {
                localStorage.setItem('loginUserName', user_name);
                updateLoginUserName(user_name);
            }
            if (userPhoneNo) {
                localStorage.setItem('loginUserPhoneNo', userPhoneNo);
            }
            if (!hasFetchedGetCart.current && token) {
                getNotifications();
                hasFetchedGetCart.current = true;
            }
            
            window.history.replaceState(null, '', window.location.pathname);
        }
    }, [token, updateToken, backEndLogin, isBackEndLogin, isLoggedIn, updateLoginUserName, getNotifications]);

    // Get Wishlist
    const getWishList = useCallback(async () => {
        const result = await apiget('wishlist/get-wishlist');
        // API Error While Get Wishlist
        if (result?.data?.status === false) {
            toast.error(result.data.message);
        }
        // Get Wishlist Successfully
        if (result?.data?.status === true) {
            updateWishListCount(result.data.data);
            setGetWishListItems(result.data.data);
        }
    }, [updateWishListCount]);

    // Get Cart
    const getCart = useCallback(async () => {
        const result = await apiget('cart/get-cart');
        // API Error While Get Cart
        if (result?.data?.status === false) {
            toast.error(result.data.message);
        }
        // Get Cart Successfully
        if (result?.data?.status === true) {
            setGetCartDetails(result.data.data);
            updateCartCount(result.data.data.total_items || 0);
        }
    }, [updateCartCount]);

    return (
        <Suspense fallback={<Loader />}>
            <ToastContainer />
            {userRoleType !== 'Frontend' ? (
                <React.Fragment>
                    <Header getCart={getCart} getWishList={getWishList} getCartDetails={getCartDetails} getWishListItems={getWishListItems} getNotifications={getNotifications} notifications={notifications}/>
                    <Routes>
                        {/* Home */}
                        <Route exact path="/" element={checkIsLoggedIn === 'true' ? <HomeWithLogin getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Home />} />

                        {/* Shop By Category */}
                        <Route exact path="/shop-by-category/:category_id?/:sub_category_id?" element={<ShopByCategory getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} getNotifications={getNotifications}/>} />

                        {/* Item Details */}
                        <Route exact path="/item-details/:item_system_id" element={<ItemDetails getCart={getCart} getWishList={getWishList} getCartDetails={getCartDetails} getWishListItems={getWishListItems} />} />

                        {/* My WishList */}
                        <Route exact path="/my-wishlist" element={checkIsLoggedIn === 'true' ? <MyWishList getCart={getCart} getCartDetails={getCartDetails}  getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />

                        {/* Cart */}
                        <Route exact path="/my-cart" element={checkIsLoggedIn === 'true' ? <Cart getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />

                        {/* Address */}
                        <Route exact path="/address" element={checkIsLoggedIn === 'true' ? <Address getCart={getCart} getWishList={getWishList} getWishListItems={getWishListItems}/> : <Navigate to="/" replace />} />

                        {/* Profile Setting */}
                        <Route exact path="/profile-setting" element={<ProfileSetting getCartDetails={getCartDetails}/>} />

                        {/* Check Out */}
                        <Route exact path="/check-out" element={checkIsLoggedIn === 'true' ? <CheckOut getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} getNotifications={getNotifications} notifications={notifications}/> : <Navigate to="/" replace />} />

                        {/* My Order */}
                        <Route exact path="/order/my-order" element={checkIsLoggedIn === 'true' ? <MyOrder getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} /> : <Navigate to="/" replace />} />
                        <Route exact path="/order/my-order-detail/:order_id" element={checkIsLoggedIn === 'true' ? <MyOrderDetail getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getNotifications={getNotifications} notifications={notifications} />  : <Navigate to="/" replace />} />
                        <Route exact path="/order/my-order-item-wise" element={checkIsLoggedIn === 'true' ? <MyOrderItemWise getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} /> : <Navigate to="/" replace />} />
                        <Route exact path="/order/my-order-detail-item-wise/:order_detail_id" element={<MyItemWiseOrderDetail getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} />} />

                        {/* Customer Sales Invoice */}
                        <Route exact path="/sales-invoice" element={checkIsLoggedIn === 'true' ? <SalesInvoice getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />
                        <Route exact path="/sales-invoice-detail/:sales_invoice_header_system_id" element={checkIsLoggedIn === 'true' ? <CustomerSalesInvoiceDetail getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />

                        {/* Customer Payment */}
                        <Route exact path="/sales-payment" element={checkIsLoggedIn === 'true' ? <SalesPayment getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />
                        <Route exact path="/sales-payment-detail/:SystemId_Ledger" element={checkIsLoggedIn === 'true' ? <SalesPaymentDetail getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems} /> : <Navigate to="/" replace />} />

                        {/* Add Retailer */}
                        <Route exact path="/list-retailer" element={checkIsLoggedIn === 'true' ? <ListRetailer getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/> : <Navigate to="/" replace />} />
                        <Route exact path="/add-retailer" element={checkIsLoggedIn === 'true' ? <AddRetailer getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/> : <Navigate to="/" replace />} />
                        <Route exact path="/edit-retailer/:SystemId" element={checkIsLoggedIn === 'true' ? <EditRetailer getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/> : <Navigate to="/" replace />} />

                        {/* Add Electrician */}
                        <Route exact path="/list-electrician" element={checkIsLoggedIn === 'true' ? <ListElectrician getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/> : <Navigate to="/" replace />} />
                        <Route exact path="/add-electrician" element={checkIsLoggedIn === 'true' ? <AddElectrician getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/> : <Navigate to="/" replace />} />
                        <Route exact path="/edit-electrician/:SystemId" element={checkIsLoggedIn === 'true' ? <EditElectrician getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList}/> : <Navigate to="/" replace />} />

                        {/* Page */}
                        <Route exact path="/page/:page_id" element={<Page getCart={getCart} getWishList={getWishList} />} />
                        <Route exact path="/page/corporate-announcements" element={<CorporateAnnouncements/>} />
                        <Route exact path="/page/FAQ" element={<FAQ/>} />

                        {/* Request new product */}
                        <Route exact path="/request-product" element={<RequestNewProduct getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} />} />

                        {/* Contact Us */}
                        <Route exact path="/contact-us" element={<ContactUs getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} />} />

                        {/* Search Result */}
                        <Route exact path="/search-result" element={<SearchResult  getCart={getCart} getCartDetails={getCartDetails}/>} />

                        {/* Ammount To Pay */}
                        <Route exact path="/ammount-to-pay" element={<AmmountToPay  getCart={getCart} getCartDetails={getCartDetails} getWishList={getWishList} getWishListItems={getWishListItems}/>} />

                        {/* Page Not Found */}
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                    {/* Scroll To Top */}
                    <ScrollToTop />
                    {/* Whatsapp */}
                    <Whatsapp />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <CustomerHeader />
                    <Routes>
                        {/* Customer List */}
                        <Route exact path="/customer-list" element={<CustomerList />} />
                        {/* Page Not Found */}
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                    {/* Scroll To Top */}
                    <ScrollToTop />
                    {/* Whatsapp */}
                    <Whatsapp />
                </React.Fragment>
            )}
            <Footer getCartDetails={getCartDetails} />
        </Suspense>
    );
}

export default App;
