import React, { useCallback, useEffect, useState, useRef } from 'react';
import { apiget, apipost } from '../../../service/API';
import { toast } from 'react-toastify';
import { useAuth } from '../../../service/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Layout/Loader';
import $ from 'jquery';
import MobileHeader from '../Layout/MobileHeader';

export default function MyOrder({ getCart, getCartDetails, getWishList }) {
  const [getOrdersOrderWise, setGetOrdersOrderWise] = useState([]);
  const [getOrdersOrderWiseLength, setGetOrdersOrderWiseLength] = useState('');
  const [orderFilter, setOrderFilter] = useState('All');
  const hasFetchedscrollToTop = useRef(false);
  const [categoriesSpinner, setCategoriesSpinner] = useState(false);
  const [getSearchMyOrderLength, setGetSearchMyOrderLength] = useState('');
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const { token, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate, deviceType } = useAuth();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  };

  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      if (token) {
        getOrderWises();
        // getCart();
        // getWishList();
      }
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop, token]);

  const getOrderWises = useCallback(async () => {
    setCategoriesSpinner(true);
    const result = await apiget('order/get-order');
    if (result && result.status === false && result.error_type === 'check_token') {
      setCategoriesSpinner(false);
      updateUserTokenValidate(false);
    }
    if (result && result.data && result.data.status === false) {
      setCategoriesSpinner(false);
      toast.error(result.data.message);
    }

    if (result && result.data && result.data.status === true) {
      setCategoriesSpinner(false);
      setGetOrdersOrderWise(result.data.data);
      setGetOrdersOrderWiseLength(result.data.data.length);
      setGetSearchMyOrderLength(result.data.data.length === 0 ? false : true);
    }
  }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

  const searchMyOrder = async () => {
    const searchMyOrder = $('#search-my-order').val();

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(async () => {
      let data = {
        sales_order_no: searchMyOrder === '' ? '' : searchMyOrder
      };
      const result = await apipost('order/search-order', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
      }

      if (result && result.data && result.data.status === true) {
        setGetOrdersOrderWise(result.data.data);
        setGetSearchMyOrderLength(true);
      }
    }, 1300);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${day}/${month}/${year}, ${hours}:${minutes} ${ampm}`;
  }

  // Filter orders based on selected filter
  const filteredOrders = getOrdersOrderWise.filter(order =>
    orderFilter === 'All' || order.order_status === orderFilter
  );
  return (
    <React.Fragment>
      {categoriesSpinner === false ? (
        <div className="bodytop90">
          <MobileHeader header_title={'My Order'} getCartDetails={getCartDetails}/>
          {getOrdersOrderWise && getOrdersOrderWise.length === 0 && getOrdersOrderWiseLength !== '' && getSearchMyOrderLength === false && (
            <section className="mob-pt-0 mob-pb-0 mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}><path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z" /></svg>
                    <h3>There Are No Order.</h3>
                  </div>
                </div>
              </div>
            </section>
          )}

          {getOrdersOrderWiseLength && getOrdersOrderWiseLength > 0 ? (
            <React.Fragment>
              <section className="mob-p-0">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <h1 className="Heading-Medium mb-5 mobile-hide">My Orders</h1>
                      <div className="menu-wrapper">
                        <div className="menu-item">
                          <Link onClick={() => setOrderFilter('All')} className={orderFilter === "All" ? "active" : ""}>All</Link>
                          <Link onClick={() => setOrderFilter('Pending')} className={orderFilter === "Pending" ? "active" : ""}>Pending</Link>
                          <Link onClick={() => setOrderFilter('Completed')} className={orderFilter === "Completed" ? "active" : ""}>Completed</Link>
                          <Link onClick={() => setOrderFilter('Cancel')} className={orderFilter === "Cancel" ? "active" : ""}>Cancelled</Link>
                        </div>
                      </div>
                      <ul className="MyorderTab mob-mb1">
                        <li><Link to="/order/my-order" className="active">Order-Wise</Link></li>
                        <li><Link to="/order/my-order-item-wise">Item-Wise</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section className="pt-0">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-xl-6 col-12">
                      <div className="form-group mb-0 mob-mb1">
                        <span className="icon-flag"><img src="../../../asset/img/searchThick.png" width="18" /></span>
                        <input type="text" id="search-my-order" className="form-control search-bar" placeholder="Search your need..." onChange={searchMyOrder} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12">
                      <div className="row align-items-center">
                        <div className="col-8">
                        </div>
                        {/* <div className="col-4">
                          <div className="form-group mb-0">
                            <select className="form-select fullrounded">
                              <option>Sort By</option>
                            </select>
                          </div>
                        </div> */}
                        <div className="col-4">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="p-0">
                <div className="container">
                  <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                  {filteredOrders.length > 0 ? (
                    filteredOrders.map((get_order_wise, index) => {
                      let  created_at = new Date(get_order_wise.created_at);
                      if (get_order_wise.order_status !== 'Pending') {
                        created_at = new Date(get_order_wise.updated_at);
                      }

                      const now = new Date();
                      const startDate = new Date(created_at.getFullYear(), created_at.getMonth(), created_at.getDate());
                      const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

                      const timeDifferenceInDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
                        return (
                          <div key={index} className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                            <Link to={`/order/my-order-detail/${get_order_wise.id}`} className="FlatCard text-start text-decoration-none">
                              <div className="card-header pe-0 ps-0 mb-3 bg-white border-bottom-dashed">
                                <div className="row align-items-center">
                                  <div className="col-4">
                                    <p className="mb-0 text-cool-gray">Order No.</p>
                                    <p className="p-12 mb-0">{get_order_wise.sales_order_no}</p>
                                  </div>
                                  <div className="col-4">
                                    <p className="mb-0 text-cool-gray">Order date</p>
                                    <p className="p-12 mb-0">{formatDate(get_order_wise.created_at)}</p>
                                  </div>
                                  <div className="col-4 text-end">
                                    <p className="mb-0 text-cool-gray">Total Amount</p>
                                    <p className="p-12 mb-0">₹ {(parseFloat(get_order_wise.total_amount)+parseFloat(get_order_wise.total_gst_amount)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                  </div>

                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <p className="mb-0 text-cool-gray">Order Qty</p>
                                  <p className="p-12 mb-0">{get_order_wise.total_order_quantity} pcs</p>
                                  <p className="p-12 mb-0">{get_order_wise.total_items} Items</p>
                                </div>
                                <div className="col-4">
                                  <p className="mb-0 text-cool-gray">{get_order_wise.order_status === "Cancel" ? 'Cancel Qty' : 'Pending Qty'}</p>
                                  <p className="p-12 mb-0">{get_order_wise.pending_quantity} Pcs</p>
                                  <p className="p-12 mb-0">{get_order_wise.total_pending_item} Items</p>
                                </div>
                                <div className="col-4 text-end">
                                  <p className="mb-0 text-cool-gray">{get_order_wise.order_status} {get_order_wise.order_status === 'Pending' ?  'since' : ''}</p>
                                  {(get_order_wise.order_status === "Completed" && <p className="p-12 mb-0">{`${get_order_wise.updated_at.split('-')[2].split(' ')[0]}/${get_order_wise.updated_at.split('-')[1]}/${get_order_wise.updated_at.split('-')[0]}`}</p> )}
                                  <p className="p-12 mb-0 text-danger">
                                    {timeDifferenceInDays === 0
                                      ? `${Math.floor((now - created_at) / (1000 * 60 * 60))} hours ago`
                                      : `${timeDifferenceInDays} days`}</p>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-4">
                                  {(get_order_wise.order_status === "Pending" &&
                                      <button className="btn btn-outline-primary btn-sm">Pending</button >
                                  )}
                                  {(get_order_wise.order_status === "Cancel" &&
                                      <button className="btn btn-outline-primary btn-sm">Cancel</button >
                                  )}
                                  {(get_order_wise.order_status === "Completed" &&
                                      <button className="btn btn-outline-primary btn-sm">Completed</button >
                                  )} 
                                </div>
                                <div className="col-4">
                                </div>
                                <div className="col-4 text-end">

                                </div>
                              </div>
                            </Link>
                          </div>
                        )
                      })
                    ) : (
                      <section className="mob-pt-0 mob-pb-0 mt-5">
                        <div className="container">
                          <div className="row">
                            <div className="col-12 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}>
                                <path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z" />
                              </svg>
                              <h3>There Are No Orders.</h3>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                </div>
              </section>
            </React.Fragment>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}
