import React from 'react';
import { useAuth } from '../../../service/AuthContext';

export default function Whatsapp() {
    const {whatsappLink, deviceType, checkIsLoggedIn} = useAuth();

    return (
        <React.Fragment>
            {/* <div className="mobile-hide"> */}
                <a className="whats-app" style={{ marginBottom: deviceType === 'mobile'?checkIsLoggedIn !== 'true' ?"":"50px":""}} href={whatsappLink} target="_blank">
                    <i className="icofont-whatsapp my-float"></i>
                </a>
            {/* </div> */}
        </React.Fragment>
    )
}
