import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../service/AuthContext';
import { toast } from 'react-toastify';
import { apiget, apipost } from '../../../service/API';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function AddRetailer({ getCart, getCartDetails, getWishList }) {
  const { token } = useAuth();

  // Loader
  const [addRetailerLoader, setAddRetailerLoader] = useState(false);

  // Get Form Dropdown Data
  const [states, setGetStates] = useState('');
  const [cities, setGetCities] = useState('');
  const [assesseeCodes, setGetAssesseeCode] = useState('');
  const [paymentTermsCodes, setGetPaymentTermsCode] = useState('');

  // Validation Error
  const [emailError, setEmailError] = useState('');
  const [mobilePhoneNoError, setMobilePhoneNoError] = useState('');
  const [errors, setErrors] = useState({});

  // Call API Only One Time
  const hasFetchedData = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      if (!hasFetchedData.current) {
        getCart();
        getWishList();

        getStates();
        getCities();
        getAssesseeCode();
        getPaymentTermsCode();
        hasFetchedData.current = true;
      }
    }
  }, [token, window.location.pathname]);

  // Get States
  const getStates = useCallback(async () => {
    try {
      const result = await apiget('address/get-states');
      if (result && result.data.status === true) {
        setGetStates(result.data.data);
      }
      if (result && result.data.status === false) {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.error(`Error while fetching states: ${error}`);
    }
  }, [token]);

  // Get Cities
  const getCities = useCallback(async () => {
    try {
      const result = await apiget('general-setting/get-cities');
      if (result && result.data.status === true) {
        setGetCities(result.data.data);
      }
      if (result && result.data.status === false) {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.error(`Error while fetching cities: ${error}`);
    }
  }, [token]);

  // Get Assessee Code
  const getAssesseeCode = useCallback(async () => {
    try {
      const result = await apiget('customer/get-assessee-code');
      if (result && result.data.status === true) {
        setGetAssesseeCode(result.data.data);
      }
      if (result && result.data.status === false) {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.error(`Error while fetching assessee code: ${error}`);
    }
  }, [token]);

  // Get Payment Terms Code
  const getPaymentTermsCode = useCallback(async () => {
    try {
      const result = await apiget('customer/get-payment-terms-code');
      if (result && result.data.status === true) {
        setGetPaymentTermsCode(result.data.data);
      }
      if (result && result.data.status === false) {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.error(`Error while fetching payment terms code: ${error}`);
    }
  }, [token]);

  // Add Retailer
  const addRetailer = useCallback(async (e) => {
    e.preventDefault();
    setAddRetailerLoader(true);
    try {
      const formData = new FormData(e.target);
      const obj = Object.fromEntries(formData.entries());
      let data = {
        Name: obj.Name,
        customer_role_id: window.location.pathname === '/add-retailer' ? '5' : '4',
        E_Mail: obj.E_Mail,
        Mobile_Phone_No: obj.Mobile_Phone_No,
        Phone_No: obj.Phone_No,
        Address: obj.Address,
        Address_2: obj.Address_2,
        State_Code: obj.State_Code,
        City: obj.City,
        Post_Code: obj.Post_Code,
        Payment_Terms_Code: obj.Payment_Terms_Code,
        Assessee_Code: obj.Assessee_Code,
        P_A_N_No: obj.P_A_N_No,
        GST_Customer_Type: obj.GST_Customer_Type,
        GST_Registration_No: obj.GST_Registration_No,
      }
      const result = await apipost('customer/add-retailer', data);
      // Show Error Email Already Exsist
      if (result?.status === false && result?.error_type === "email") {
        setErrors({});
        setEmailError(result.message);
        setMobilePhoneNoError('');
        toast.error(result.message);
      }
      // Show Error Email Already Exsist
      if (result?.status === false && result?.error_type === "Mobile_Phone_No") {
        setErrors({});
        setMobilePhoneNoError(result.message);
        setEmailError('');
        toast.error(result.message);
      }
      // Show Validation Error
      if (result?.errors && result?.errors?.length > 0 && result?.status === false) {
        const errorObject = {};
        result.errors.forEach(err => {
          errorObject[err.field] = err.message;
        });
        setErrors(errorObject);
        setAddRetailerLoader(false);
        toast.error(result.message);
      }
      // Reset form data and validation error and redirect after success
      if (result?.data?.status === true) {
        e.target.reset();
        setErrors({});
        setEmailError('');
        setMobilePhoneNoError('');
        navigate(`${window.location.pathname == '/add-retailer' ? '/list-retailer' : '/list-electrician'}`);

        toast.success(result.data.message);
      }
      // Show Notification If API Status false
      if (result?.data?.status === false) {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.error(`Error while add retailer: ${error}`);
    } finally {
      setAddRetailerLoader(false);
    }
  }, [token]);

  return (
    <React.Fragment>
      {states && cities && assesseeCodes && paymentTermsCodes ? (
        <div className="bodytop90 body-gray mb-5 mb-md-0">
          <MobileHeader header_title={'Add Retailer'} getCartDetails={getCartDetails} />
          <div className="container">
            <section className="pt-3 pb-0 mobile-hide">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-1">
                    <Link onClick={() => { navigate(-1); setTimeout(() => { window.scrollTo(0, 0); }, 100); }}>
                      <img src='../../../asset/img/Arrow-left.png' className="Heading-icon" height='70px' />
                    </Link>
                  </div>
                  <div className="col-11">
                    <h1>{`Add ${window.location.pathname === '/add-retailer' ? 'Retailer' : 'Electrician'}`}</h1>
                  </div>
                </div>
              </div>
            </section>
            <form onSubmit={addRetailer}>
              {/* General Information */}
              <div className="FlatCard bg-white text-start p-0 rounded24 mb-4">
                <div className="card-header bg-white pt-3" style={{ borderTopRightRadius: '24px', borderTopLeftRadius: '24px' }}>
                  <h5 className="Heading-vSmall">General</h5>
                </div>
                <div className="card-body height127 text-start">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Name <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="Name" placeholder="Enter Name" />
                        {errors.Name && <span className="text-danger">{errors.Name}</span>}
                      </div>
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Mobile Phone No. <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="Mobile_Phone_No" inputMode="numeric" pattern="[0-9]*" placeholder="Enter Mobile Phone No." />
                        {errors.Mobile_Phone_No && <span className="text-danger">{errors.Mobile_Phone_No}</span>}
                        {mobilePhoneNoError && <span className="text-danger">{mobilePhoneNoError}</span>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Email</label>
                        <input type="text" className="form-control" name="E_Mail" placeholder="Enter Email" />
                        {emailError && <span className="text-danger">{emailError}</span>}
                      </div>
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Phone No.</label>
                        <input type="text" className="form-control" name="Phone_No" inputMode="numeric" pattern="[0-9]*" placeholder="Enter Phone No." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Address Information */}
              <div className="FlatCard bg-white text-start p-0 rounded24 mb-4">
                <div className="card-header bg-white pt-3" style={{ borderTopRightRadius: '24px', borderTopLeftRadius: '24px' }}>
                  <h5 className="Heading-vSmall mb-2">Address</h5>
                </div>
                <div className="card-body height127 text-start">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Address <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name="Address" placeholder="Enter Address" />
                        {errors.Address && <span className="text-danger">{errors.Address}</span>}
                      </div>
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">State <span className="text-danger">*</span></label>
                        <select name="State_Code" className="form-select" defaultValue="">
                          <option value="" label="Select a State">Select a State</option>
                          {states && states.map((state, index) => (
                            <option key={index} value={state.code}>{state.description} ({state.code})</option>
                          ))}
                        </select>
                        {errors.State_Code && <span className="text-danger">{errors.State_Code}</span>}
                      </div>
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Post Code</label>
                        <input type="text" className="form-control" name="Post_Code" inputMode="numeric" pattern="[0-9]*" placeholder="Enter Post Code" />
                        {errors.Post_Code && <span className="text-danger">{errors.Post_Code}</span>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Address 2</label>
                        <input type="text" className="form-control" name="Address_2" placeholder="Enter Address 2" />
                      </div>
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">City</label>
                        <select name="City" className="form-select" defaultValue="">
                          <option value="" label="Select a City">Select a City</option>
                          {cities && cities.map((city, index) => (
                            <option key={index} value={city.city_code}>{city.city_name} ({city.city_code})</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Payments and Tax Information */}
              <div className="FlatCard bg-white text-start p-0 rounded24 mb-5">
                <div className="card-header bg-white pt-3" style={{ borderTopRightRadius: '24px', borderTopLeftRadius: '24px' }}>
                  <h5 className="Heading-vSmall">Payments And Tax Information</h5>
                </div>
                <div className="card-body height127 text-start">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Payment Terms Code <span className="text-danger">*</span></label>
                        <select name="Payment_Terms_Code" className="form-select" defaultValue="">
                          <option value="" label="Select a Payment Terms Code">Select a Payment Terms Code</option>
                          {paymentTermsCodes && paymentTermsCodes.map((paymentTermsCode, index) => (
                            <option key={index} value={paymentTermsCode.code}>{paymentTermsCode.description} ({paymentTermsCode.code})</option>
                          ))}
                        </select>
                        {errors.Payment_Terms_Code && <span className="text-danger">{errors.Payment_Terms_Code}</span>}
                      </div>
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">P. A. N. No.</label>
                        <input type="text" className="form-control" name="P_A_N_No" placeholder="Enter P. A. N. No." />
                      </div>
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">GST Registration No.</label>
                        <input type="text" className="form-control" name="GST_Registration_No" placeholder="Enter GST Registration No." />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Assessee Code</label>
                        <select name="Assessee_Code" className="form-select" defaultValue="">
                          <option value="" label="Select a Assessee Code">Assessee Code</option>
                          {assesseeCodes && assesseeCodes.map((assesseeCode, index) => (
                            <option key={index} value={assesseeCode.Code}>{assesseeCode.Description} ({assesseeCode.Code})</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">GST Customer Type</label>
                        <select name="GST_Customer_Type" className="form-select" defaultValue="">
                          <option value="" label="Select a GST Customer Type">Select a GST Customer Type</option>
                          <option value="Registered">Registered</option>
                          <option value="Unregistered">Unregistered</option>
                          <option value="Export">Export</option>
                          <option value="Deemed_Export">Deemed Export</option>
                          <option value="Exempted">Exempted</option>
                          <option value="SEZ_Development">SEZ Development</option>
                          <option value="SEZ_Unit">SEZ Unit</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {addRetailerLoader === false ? (
                    <button className="btn btn-primary float-md-end float-sm-start mb-3" type="submit">Submit</button>
                  ) : (
                    <Loader spinner={'add-retailer'} className={'btn btn-primary float-md-end float-sm-start mb-3'} />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}
