import React, { useCallback, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';
import Footer from '../Layout/Footer';

export default function Cart({ getCart, getCartDetails, getWishList }) {
  const { token, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate, deviceType } = useAuth();
  const cartItems = getCartDetails.get_cart;

  const debounceTimeout = useRef(null);
  const [cartQuantityLoader, setcartQuantityLoader] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [initialValue, setInitialValue] = useState({});
  const [handleQuantityChangeLoader, sethandleQuantityChangeLoader] = useState({});
  const hasFetchedscrollToTop = useRef(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (token) {
  //     getCart();
  //     getWishList();
  //   }
  // }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  };
  
  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);

  const updateCartItem = async (item_system_ids, sales_price_system_id, newQuantity, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price, GST_group_code) => {
    
    try {
      let data = {
        item_system_ids: item_system_ids,
        sales_price_system_id: sales_price_system_id,
        quantity: newQuantity,
        item_no: item_no,
        item_sales_unit_of_measure: item_sales_unit_of_measure,
        item_group_code: item_group_code,
        microsoft_quantity: qty_per_unit_of_measure,
        item_unit_price: item_unit_price,
        gst_group_code: GST_group_code
      };
      const result = await apipost('cart/add-to-cart', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setcartQuantityLoader(false);
      }
      if (result && result.data && result.data.status === false) {
        toast.error(result.data.message);
        setcartQuantityLoader(false);
      }

      if (result && result.data && result.data.status === true) {
        toast.success(result.data.message);
        getCart();
        setcartQuantityLoader(false);
      }
    } catch (error) {
      console.error(error);      
    }
    finally {
      setcartQuantityLoader(false);
    }
  };

  const removeItemFromCart = useCallback(async (cart_id) => {
    try {
      let data = {
        cart_id: cart_id
      };
      const result = await apipost('cart/delete-cart', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
      }
      if (result && result.data && result.data.status === false) {
        toast.error(result.data.message);
      }

      if (result && result.data && result.data.status === true) {
        toast.success(result.data.message);
        getCart();
        window.$("#cart_delete").modal("hide");
      }
    } catch (error) {
      console.error(error);      
    } finally {
      setTimeout(() => {
        setcartQuantityLoader(false);
      }, 500);
    }
  }, [token]);

  const debounce = (func, delay) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(func, delay);
  };

  const handleFocuse = (event, item_system_id) => {
    let { value } = event.target;
    value = value.replace(/^0+/, '');
    value.replace(/^0+/, '')
    if (/^[0-9]*$/.test(value) && !/^0/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }
    setInitialValue(prevState => ({ ...prevState, [item_system_id]: value }));
    }
  }

  const handleInputChange = (event, item_system_id) => {
    let { value } = event.target;
    value = value.replace(/^0+/, '');
    if (/^[0-9]*$/.test(value) && !/^0/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }

      setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));
    }
  };

  const handleInputBlur = (e, item_system_id, sales_price_system_id, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price, cart_id, GST_group_code) => {
    
    let { value } = e.target;
      value = value.replace(/^0+/, '');
      value.replace(/^0+/, '')
      if (/^[0-9]*$/.test(value) && !/^0/.test(value)) {
        if (value > 99999) {
          value = 99999;
        }
        if (initialValue[item_system_id] !== value) {
          setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));
          
          handleUpdateCartQuantityChange(item_system_id, sales_price_system_id, value > 0 ? value : 0, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price, cart_id, GST_group_code);
        }
      }
  };

  const handleUpdateCartQuantityChange = (item_system_id, sales_price_system_id, newQuantity, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price, cart_id, GST_group_code) => {
    
    setcartQuantityLoader(true);
    
    sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: true }));
    if (newQuantity === 0) {
      removeItemFromCart(cart_id);
    } else if (newQuantity > 0) {
      newQuantity = Math.max(newQuantity, 1);
      var new_sales_price_system_id = '';
      cartItems && cartItems.forEach(cartItem => {
        const allValidPrice = cartItem.get_item.Prices.find(price => price.order === 1);
        if (cartItem.item_system_id === item_system_id) {
          const maxPrice = allValidPrice && allValidPrice.Unit_Price;
          cartItem && cartItem.get_item.Prices.forEach(sales_price => {
            if (sales_price.Unit_Price !== maxPrice.toString() && sales_price.Minimum_Quantity !== '0' && sales_price.Minimum_Quantity !== '1') {
              if (newQuantity >= sales_price.Minimum_Quantity) {
                new_sales_price_system_id = sales_price.SystemId;
              } else {
                new_sales_price_system_id = sales_price_system_id;
              }
            } else {
              new_sales_price_system_id = sales_price_system_id;
            }
          });
        }
      });
      
      sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: false }));
      updateCartItem(item_system_id, new_sales_price_system_id, newQuantity, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price, GST_group_code);
    }
  };

  return (
    <React.Fragment>
      {getCartDetails ? (
        <React.Fragment>
          <div className="bodytop90 body-gray">
          {deviceType === "mobile" && (
            <MobileHeader header_title={
              <div style={{fontWeight: "bold"}}>{(getCartDetails ? getCartDetails.total_items : 0) + ' Items in cart'}</div>
            } headerTitle={getCartDetails.total_items  > 0 ? "Cart": "Hide-Icone"} getCartDetails={getCartDetails}/>
          )}
          {deviceType === "mobile" && (
            <Footer show_footer={getCartDetails.total_items  > 0 ? "" : "show-footer"} />
          )}
            {getCartDetails && getCartDetails.total_items > 0 ? (
              <section className="pt-3 pb-0 mobile-hide">
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-1">
                        <Link onClick={() => { navigate(-1); setTimeout(() => { window.scrollTo(0, 0); }, 100); }}>
                            <img src='../../../asset/img/Arrow-left.png' className="Heading-icon" height='70px'/>
                        </Link>
                    </div>
                    <div className="col-10">
                      <h1>{getCartDetails.total_items} Items in cart</h1>
                    </div>
                    {getCartDetails.total_items > 0 ? (
                      <div className="col-1 text-end">
                        <Link className="trash-btn" data-bs-toggle="modal" data-bs-target="#cart_delete">
                          <i className="bi bi-trash text-danger"></i>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
            ) : (
              <section className="mob-pt-0 mob-pb-0 mt-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}><path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z"/></svg>
                      <h3>No Item In Cart.</h3>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section className="mob-p-0">
              <div className="container">
                <div className="row">
                  {/* <!--cart items--> */}
                  <div className="col-lg-8 col-xl-8 col-12">
                    {getCartDetails && getCartDetails.get_categories.map((get_category, index) => {
                      return (
                        <div key={get_category.category_name} className="accordion" id="accordionExample" >
                          <div className="accordion-item cart-accordian-item">
                            <h2 className="accordion-header" id="cartheading-1">
                              <button className="accordion-button cart-accordian-btn" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${index}`} aria-expanded="false" aria-controls="collapse-1">
                                {get_category.category_name} ({get_category.category_count})
                              </button>
                            </h2>
                            <div id={`collapse-${index}`} className="accordion-collapse collapse show" aria-labelledby="cartheading-1" data-bs-parent="#accordionExample">
                              <div className="accordion-body"  style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                                <ul className="catitemList">
                                  {cartItems ? cartItems.map((cart_item, index) => {

                                    const validPrice = cart_item.get_item.Prices.find(System_Id => System_Id.SystemId === cart_item.sales_price_system_id);
                                    const price = validPrice && validPrice.Unit_Price;
                                    const item_total_price = validPrice && validPrice.Unit_Price * cart_item.quantity;
                                    
                                    const validPrice_Sales = cart_item.get_item.Prices.find(price => price.order === 1);
                                    
                                    const sales_price_system_id = validPrice_Sales && validPrice_Sales.SystemId;
                                    return (
                                        get_category.category_id === cart_item.get_item.category_id && (
                                          <li key={index}>
                                            <div className="row align-items-center">
                                              <div className="col-lg-2 col-xl-2 col-3 text-center">
                                                <img src={cart_item.get_item.image ? cart_item.get_item.image : "../../../asset/img/no_image_available.jpg"} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} width="52" />
                                              </div>
                                              <div className="col-lg-10 col-xl-10 col-9">
                                                <div className="row align-items-center">
                                                  <div className="col-lg-10 col-xl-10 col-sm-9 col-md-9 col-12">
                                                    <h5 className="mb-0">{cart_item.get_item.Description}</h5>
                                                    
                                                    <p className="mb-0 text-muted">Multiples of {cart_item.qtyPerUnitOfMeasure} {cart_item.get_item.Base_Unit_of_Measure}</p>

                                                    <p>₹{validPrice && validPrice.Unit_Price} * {cart_item.quantity} = ₹{item_total_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                                      {cart_item.get_item.Prices && cart_item.get_item.Prices.map((price) => {
                                                        if (price.Minimum_Quantity !== '0' && price.Minimum_Quantity !== '1') {
                                                          const prices = cart_item.get_item.Prices.map(price => parseFloat(price.Unit_Price));
                                                          const minPrice = prices.length > 0 ? Math.max(...prices) : 0;
                                                          let is_total_quantity = 0;
                                                          const total_Unit_Price = minPrice - price.Unit_Price;

                                                          var discount_price = total_Unit_Price * cart_item.quantity;

                                                          const discount_total_price = total_Unit_Price * price.Minimum_Quantity;
                                                          if (price.SystemId === (cart_item && cart_item.sales_price_system_id)) {
                                                            is_total_quantity = 1;
                                                            var discount_price = total_Unit_Price * cart_item.quantity;
                                                          }
                                                          return (
                                                            price.Unit_Price !== minPrice.toString() && price.Minimum_Quantity !== '0' && price.Minimum_Quantity !== '1' && (
                                                              is_total_quantity === 1 ? (
                                                                <span key={price.SystemId} className="text-success ml-1">
                                                                  Savings ₹{discount_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </span>
                                                              ) : (
                                                                <span key={price.SystemId} className="text-blue ml-1">
                                                                  Save ₹{parseInt(discount_total_price)} by adding {parseInt(price.Minimum_Quantity - cart_item.quantity)} {cart_item.get_item.Base_Unit_of_Measure}
                                                                </span>
                                                              )
                                                            )
                                                          );
                                                        }
                                                      })}
                                                    </p>
                                                  </div> 
                                                    { handleQuantityChangeLoader[cart_item.get_item.SystemId] ? (
                                                      <div className="col-lg-2 col-xl-2 col-sm-3 col-md-3 col-7">
                                                        <div className="number mob-mb1">
                                                          <Loader spinner={'cart-loader'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                                        </div>
                                                      </div>
                                                      ) : (
                                                        <div className="col-lg-2 col-xl-2 col-sm-3 col-md-3 col-7">
                                                          <div className="number mob-mb1">
                                                            <input 
                                                              type="text" inputMode="numeric" 
                                                              className="pm-counter w-100" value={inputValue && inputValue[cart_item.item_system_id] !== undefined ? inputValue[cart_item.item_system_id] : cart_item.quantity}
                                                              onBlur={(e) => handleInputBlur(e, cart_item.item_system_id, sales_price_system_id, cart_item.get_item.No, cart_item.item_sales_unit_of_measure, cart_item.item_group_code, cart_item.qtyPerUnitOfMeasure, price, cart_item.id, cart_item.get_item.GST_Group_Code)}
                                                              onChange={(e) => handleInputChange(e, cart_item.item_system_id, sales_price_system_id, cart_item.get_item.No, cart_item.item_sales_unit_of_measure, cart_item.item_group_code, cart_item.qtyPerUnitOfMeasure, price, cart_item.id)} 
                                                              onFocus={(e) => handleFocuse(e, cart_item.item_system_id, "onFocus")}
                                                              disabled={cartQuantityLoader === true}
                                                              />
                                                          </div>
                                                        </div>
                                                      ) 
                                                    }
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                    )
                                  }) : null}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  {/* <!--Product Gallery--> */}
                  {getCartDetails && getCartDetails.total_items >= 1 && (
                    <div className="col-lg-4 col-xl-4 col-12 mobile-hide">
                      <div className="cartSummary-table">
                        <div className="card-body">
                          <div className="no-gutters">
                            <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                              <div className="d-flex align-items-center">
                                <p className="mb-0">Item total</p>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="mb-0">₹{getCartDetails && getCartDetails.total_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                              </div>
                            </div>

                            <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                              <div className="d-flex align-items-center">
                                <p className="mb-0">Product discount</p>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="mb-0 text-success">- ₹00.00</p>
                              </div>
                            </div>

                            <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                              <div className="d-flex align-items-center">
                                <p className="mb-0">GST + Cess</p>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="mb-0">₹{getCartDetails && getCartDetails.total_gst_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                              </div>
                            </div>

                            <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                              <div className="d-flex align-items-center">
                                <p className="mb-0">Delivery charge</p>
                              </div>
                              <div className="d-flex align-items-center">
                                <p className="mb-0"><img src="../../../asset/img/truck_free.png" width="24" /> FREE</p>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer bg-white">
                            <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                              <div className="d-flex align-items-center">
                                <h5><strong>Total</strong></h5>
                              </div>
                              <div className="d-flex align-items-center">
                                <h5><strong>₹{(parseFloat(getCartDetails.total_amount)+parseFloat(getCartDetails.total_gst_amount)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></h5>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap">
                              <Link to="/check-out" className="btn btn-primary w-100">
                                <div className="d-flex flex-wrap justify-content-between">
                                  <div className="d-flex align-items-center">Checkout</div>
                                  <div className="d-flex align-items-center">
                                    <i className="bi bi-chevron-right"></i>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>

            <div className="modal fade" id="cart_delete" tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                <div className="modal-content">
                  {/* <button type="button" className="btn-close mobile-hide" data-bs-dismiss="modal" aria-label="Close"></button> */}

                  <a type="button" className="close text-end mr-1" style={{ fontSize: '20px', color: 'black' }} data-bs-dismiss="modal" aria-label="Close">X</a>
                  <div className="modal-body h4">
                    Are you sure you want to empty your cart ?
                  </div>
                  <div className="modal-footer">
                    <button onClick={() => removeItemFromCart('')} type="submit" className="btn btn-primary w-100">Delete Cart</button>
                  </div>
                </div>
              </div>
            </div>
          
            {/*cart summary Bottom Modal*/}
            <div className="offcanvas offcanvas-bottom cart-offcanvas-bottom" tabIndex="-1" id="cartsummaryBottom" aria-labelledby="cartsummaryBottomLabel">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="cartsummaryBottomLabel">Bill Details</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body small">
                <div className="no-gutters">
                  <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                    <div className="d-flex align-items-center">
                      <p className="mb-0">Item total</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="mb-0">₹{getCartDetails.total_amount && getCartDetails.total_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                    <div className="d-flex align-items-center">
                      <p className="mb-0">Product discount</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 text-success">- ₹00.00</p>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                    <div className="d-flex align-items-center">
                      <p className="mb-0">GST + Cess</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="mb-0">₹{getCartDetails && getCartDetails.total_gst_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                    <div className="d-flex align-items-center">
                      <p className="mb-0">Delivery charge</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="mb-0"><img src="../../../asset/img/truck_free.png" width="24"/> FREE</p>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                    <div className="d-flex align-items-center">
                      <h5><strong>Total</strong></h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <h5><strong>₹{(parseFloat(getCartDetails.total_amount)+parseFloat(getCartDetails.total_gst_amount)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getCartDetails.total_items  > 0 && (
              <footer className="mobile-footer-nav">
                <div className="row pt-2 pb-2 ps-3 pe-4 align-items-center">
                  <div className="col-5">
                    <h5 className="mb-0"><strong>₹{(parseFloat(getCartDetails.total_amount)+parseFloat(getCartDetails.total_gst_amount)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></h5>
                    <a href="#" data-bs-toggle="offcanvas" data-bs-target="#cartsummaryBottom" aria-controls="cartsummaryBottom">See Details</a>
                  </div>
                  <div className="col-7">
                    <Link to="/check-out" className="btn btn-primary w-100">
                      <div className="d-flex flex-wrap justify-content-between">
                      <div className="d-flex align-items-center p-12">Proceed to Checkout</div>
                        <div className="d-flex align-items-center"><i className="bi bi-chevron-right"></i></div>
                      </div>
                    </Link>
                  </div>
                </div> 
              </footer>
            )}
          </div>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}