import React from 'react';
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useAuth } from '../../../service/AuthContext';

const Banner = React.memo(({getBanner }) => {
  const { deviceType } = useAuth();

  return (
    <React.Fragment>
      <Swiper
        className="StaticHero-banner"
        style={{
          "--swiper-navigation-color": "#E03546",
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={deviceType === "mobile" ? 1 : 1}
        navigation
      >
        {getBanner && getBanner.length > 0 && getBanner.map((get_banners) => {
          if (get_banners.banner_type === "Without Login") {
            return (
              <SwiperSlide key={get_banners.id}>
                <div className="StaticHero-banner-image">
                  <img
                    src={get_banners.image ? get_banners.image : '../asset/img/no_image_available.jpg'}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '../asset/img/no_image_available.jpg';
                    }}
                    className="static-bannerclip"
                  />
                  <div className="row justify-content-center banner-call-action"></div>
                </div>
              </SwiperSlide>
            );
          }
          return null; // Ensure a return statement
        })}
      </Swiper>
    </React.Fragment>
  );
});

export default Banner;
