import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { apiget, apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../Layout/Loader';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import MobileHeader from '../Layout/MobileHeader';

export default function ItemDetails({ getCart, getCartDetails, getWishList, getWishListItems }) {
  const { token, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate, deviceType } = useAuth();
  const { item_system_id } = useParams();
  const [spinner, setSpinner] = useState(true);
  const [itemByCategoriesSpinner, setItemByCategoriesSpinner] = useState(false);
  const hasFetchedscrollToTop = useRef(false);
  const [getItemDetail, setGetItemDetails] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [cartItemQuantity, setCartItemQuantity] = useState(0);
  const [minPrice, setMinPrice] = useState('');
  const [ItemGroup, setItemGroup] = useState('');
  const [allPrice, setAllPrice] = useState('');
  const [currentUnitPrice, setCurrentUnitPrice] = useState(1);
  const [sales_price_system_id, setSlesPriceSystemId] = useState(1);
  const [handleQuantityChangeLoader, sethandleQuantityChangeLoader] = useState({});
  const [cart_quantity_loder, setcartQuantityLoderLoader] = useState(false);
  const [getItemBySubCategoryDetail, setGetItemBySubCategoryDetail] = useState('');
  const [itemCategoryId, setItemCategoryId] = useState('');
  const [itemSubCategoryId, setItemSubCategoryId] = useState('');
  const [inputValue, setInputValue] = useState({});
  const [initialValue, setInitialValue] = useState({});
  const [cartId, setCartId] = useState('');
  const navigate = useNavigate();
  const hasFetchedGetCategory = useRef(false);
  const hasFetchedItemDetails = useRef(false);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [addToWishlistLoader, setAddToWishlistLoader] = useState(false);
  

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  };
  
  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);
  
  const changeSalesPrice = useCallback(() => {
    if (getItemDetail) {
      const validPrices = getItemDetail && getItemDetail.Prices.filter(price =>
        price &&
        price.Starting_Date < new Date().toISOString().slice(0, 10) &&
        (price.Ending_Date > new Date().toISOString().slice(0, 10) || price.Ending_Date === '0001-01-01')
      ).flat();

      const prices = validPrices.map(price => parseFloat(price.Unit_Price));
      const minPrice = prices.length > 0 ? Math.max(...prices) : 0;
      const defaultItemDetail = validPrices.find(price => currentUnitPrice && parseFloat(currentUnitPrice) === parseFloat(price.Unit_Price));

      const currentItemDetail = validPrices.find(price => minPrice === parseFloat(price.Unit_Price));
      const Minimum_Quantity = defaultItemDetail ? defaultItemDetail.Minimum_Quantity : currentItemDetail.Minimum_Quantity;
      const Item_Group = currentItemDetail.Item_Group;

      // Wishlist
      const get_wishlist_details = getWishListItems && getWishListItems.get_wishlist && getWishListItems.get_wishlist.find(get_wishlist_detail => get_wishlist_detail.item_system_id === item_system_id);
      const get_wishlist_item_system_id = get_wishlist_details && get_wishlist_details.item_system_id;
      const get_wishlist_id = get_wishlist_details && get_wishlist_details.id;
      setQuantity(Minimum_Quantity > 0 ? Minimum_Quantity : 1);
      setSlesPriceSystemId(currentItemDetail.SystemId);
      setMinPrice(minPrice);
      setItemGroup(Item_Group);
      setAllPrice(validPrices);
    }
  }, [getItemDetail, currentUnitPrice]);

  const getItemCartDetails = useCallback(async () => {
    const result = await apiget('cart/get-cart');
      if (result && result.status === false && result.error_type === 'check_token') {
          updateUserTokenValidate(false);
      }
      if (result && result.data && result.data.status === true) {
          const get_cart_details = result.data.data && result.data.data.get_cart.find(get_cart_detail => get_cart_detail.item_system_id === item_system_id);
          const cart_id = get_cart_details && get_cart_details.id;
          const get_item_quantity = get_cart_details && get_cart_details.quantity;
          setCartItemQuantity(get_item_quantity);
          setCartId(cart_id);
      }
  });

  const ItemDetails = useCallback(async (sales_price_system_id, Unit_Price, not_call_spinner) => {
    try {
      if(not_call_spinner !== "not_call_spinner"){
        setSpinner(true);
      }
      const data = {
        item_system_id: item_system_id,
        sales_price_system_id: sales_price_system_id
      };
      if (token) {
        var result = await apipost('item/get-item-detail', data);
      } else {
        var result = await apipost('get-item-detail', data);
      }
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setSpinner(false);
      }
      if (result && result.data && result.data.data && result.data.status === true) {
        setGetItemDetails(result.data.data[0]);
        setCurrentUnitPrice(Unit_Price);
        setItemCategoryId(result.data.data[0].category_id);
        setItemSubCategoryId(result.data.data[0].sub_category_id);

        // Cart
        const get_cart_details = getCartDetails && getCartDetails.get_cart.find(get_cart_detail => get_cart_detail.item_system_id === item_system_id);
        const cart_id = get_cart_details && get_cart_details.id;
        const get_item_quantity = get_cart_details && get_cart_details.quantity;
        setCartItemQuantity(get_item_quantity);
        setCartId(cart_id);
        setSpinner(false);
        // if(not_call_spinner !== "not_call_spinner"){
        //   getItemCartDetails();
        // }
      }
    } catch (error) {
      console.error(error);
    } finally {
      changeSalesPrice();
      if (result && result.data && result.data.data && result.data.status === true) {
        if (!hasFetchedGetCategory.current) {
          getItemByCategories(result.data.data[0].SystemId, result.data.data[0].category_id, result.data.data[0].sub_category_id);
          hasFetchedGetCategory.current = true;
        }
      }
      setSpinner(false);
    }
  }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate, item_system_id]);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setInputValue();
      setCurrentPath(location.pathname);
      hasFetchedItemDetails.current = false;
    }

    if (!hasFetchedItemDetails.current) {
      ItemDetails();
      scrollToTop();
      hasFetchedItemDetails.current = true;
    }
  }, [location.pathname, currentPath]);

  // useEffect(() => {   
  //     ItemDetails();
  //     scrollToTop();
  // }, [ItemDetails]);

  useEffect(() => {
    changeSalesPrice();
  }, [changeSalesPrice]);

  // useEffect(() => {
  //   if (token) {
  //     getCart();
  //   }
  // }, [token]);

  // useEffect(() => {
  //   if (token) {
  //     getWishList();
  //   }
  // }, [token]);

  const removeItemFromCart = async (item_system_id, cart_id, item_detail_api) => {
    try {
      let data = {
        cart_id: cart_id
      };
      const result = await apipost('cart/delete-cart', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setcartQuantityLoderLoader(false);
      }
      if (result && result.data.status === false) {
        toast.error(result.data.message);
      }

      if (result && result.data.status === true) {
        toast.success(result.data.message);

        if (item_detail_api === 'item_detail_api') {
          let updatedItemDetail = getItemDetail;
          if (getItemDetail.SystemId === item_system_id) {
            updatedItemDetail = {
              ...getItemDetail,
              is_cart: getItemDetail.is_cart = 0,
              quantity: 0
            };
          }
          
          const updatedSimilarItems = getItemBySubCategoryDetail && getItemBySubCategoryDetail.map(item =>
            item.SystemId === item_system_id ? { ...item, is_cart: 0, quantity: 0 } : item
          );

          setGetItemBySubCategoryDetail(updatedSimilarItems);
          setCartItemQuantity(0);
          setGetItemDetails(updatedItemDetail);
          getCart();
          setInputValue();
          setCartId('');
          setcartQuantityLoderLoader(false);
        }

        if (item_detail_api === 'get_items') {
          const updatedSimilarItems = getItemBySubCategoryDetail && getItemBySubCategoryDetail.map(item =>
            item.SystemId === item_system_id ? { ...item, is_cart: 0, quantity: 0 } : item
          );

          setGetItemBySubCategoryDetail(updatedSimilarItems);

          getItemByCategories(item_system_id, itemCategoryId, itemSubCategoryId, "not_call_spinner");
          getCart();
          setCartId('');
          setcartQuantityLoderLoader(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: false }));
    }
  };

  const handleInputChange = (event, item_system_id, onFocus) => {
    let { value } = event.target;
    value = value.replace(/^0+/, '');
    if (/^\d*$/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }
      if(onFocus !== "onFocus") {
        setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));
      } else {
        setInitialValue(prevState => ({ ...prevState, [item_system_id]: value }));
      }
    }
  };

  const handleInputBlur = (event, item_system_id, sales_price_system_id, cart_id, item_no, item_sales_unit_of_measure, qty_per_unit_of_measure, item_group_code, item_detail_api, type_of_quantity, GST_group_code) => {
    let { value } = event.target;
    
    value = value.replace(/^0+/, '');
    value.replace(/^0+/, '')
    if (/^[0-9]*$/.test(value) && !/^0/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }
      if (initialValue[item_system_id] !== value) {
        setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));
        
        handleQuantityChange(item_system_id, sales_price_system_id, cart_id, value > 0 ? value : 0,item_no, item_sales_unit_of_measure, qty_per_unit_of_measure, item_group_code, item_detail_api, type_of_quantity, GST_group_code);
      }
    }
  };

  const handleQuantityChange = (item_system_id, sales_price_system_id, cart_id, currentQuantity, item_no, item_sales_unit_of_measure, qty_per_unit_of_measure, item_group_code, item_detail_api, type_of_quantity, GST_group_code) => {
    sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: true }));
    setcartQuantityLoderLoader(true);
    const get_cart_details = getCartDetails && getCartDetails.get_cart.find(get_cart_detail => get_cart_detail.item_system_id === item_system_id);
    var get_item_quantity = currentQuantity;
    if (type_of_quantity === 'increase_quantity') {
      if (currentQuantity !== undefined) {
        get_item_quantity = parseFloat(currentQuantity) + 1;
      } else {
        get_item_quantity = get_cart_details && get_cart_details.quantity + 1;
      }
    }

    if (type_of_quantity === 'quantity') {
      get_item_quantity = currentQuantity;
    }

    if (type_of_quantity === 'decrease_quantity') {
      if (currentQuantity !== undefined) {
        if (currentQuantity === 0) {
          get_item_quantity = currentQuantity;
        } else {
          get_item_quantity = parseFloat(currentQuantity) - 1; 
        }
      } else {
        get_item_quantity = get_cart_details && get_cart_details.quantity - 1;
      }
    }
    if (currentQuantity !== '' && item_detail_api !== 'get_items') {
      setCartItemQuantity(get_item_quantity);
    }
    if (get_item_quantity === 0) {
      removeItemFromCart(item_system_id, cart_id, item_detail_api);
    } else if (get_item_quantity > 0) {
      var new_sales_price_system_id = '';
      if(item_detail_api !== 'get_items'){
          allPrice.forEach(price => {
            if (price.Unit_Price !== minPrice.toString() && price.Minimum_Quantity !== '0' && price.Minimum_Quantity !== '1') {
              if (Number(get_item_quantity) >= Number(price.Minimum_Quantity)) {
                new_sales_price_system_id = price.SystemId;
              } else {
                new_sales_price_system_id = sales_price_system_id;
              }
            } else {
              new_sales_price_system_id = sales_price_system_id;
            }
        });
      } else {
        getItemBySubCategoryDetail && getItemBySubCategoryDetail.forEach(item => {
          const validPrice = item.Prices.find(price => price.order === 1);
          if (item.SystemId === item_system_id) {
            const maxPrice = validPrice && validPrice.Unit_Price;
            item && item.Prices.forEach(sales_price => {
              if (sales_price.Unit_Price !== maxPrice.toString() && sales_price.Minimum_Quantity !== '0' && sales_price.Minimum_Quantity !== '1') {
                if (Number(currentQuantity) >= Number(sales_price.Minimum_Quantity)) {
                  new_sales_price_system_id = sales_price.SystemId;
                } else {
                  new_sales_price_system_id = sales_price_system_id;
                }
              } else {
                new_sales_price_system_id = sales_price_system_id;
              }
            });
          }
        })
      }
      
      handleAddToCart(item_system_id, new_sales_price_system_id, get_item_quantity, item_no, item_sales_unit_of_measure, qty_per_unit_of_measure, item_group_code, item_detail_api, GST_group_code);

      if (currentQuantity !== undefined) {
        setCartId(cart_id);
      }
    }
  };

  // Add Item To Cart API
  const handleAddToCart = async (item_system_ids, salesPriceSystemId, Quality, item_no, item_sales_unit_of_measure, qty_per_unit_of_measure, item_group_code, item_detail_api, GST_group_code) => {
    
    sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_ids]: true }));
    setcartQuantityLoderLoader(true);
    let data = {
      item_system_ids: item_system_ids,
      sales_price_system_id: salesPriceSystemId ? salesPriceSystemId : sales_price_system_id,
      quantity: Quality === undefined ? 1 : Quality.toString(),
      item_no: item_no,
      item_sales_unit_of_measure: item_sales_unit_of_measure,
      microsoft_quantity: qty_per_unit_of_measure,
      item_group_code: item_group_code,
      gst_group_code: GST_group_code,
    }
    const result = await apipost('cart/add-to-cart', data);
    
    if (result && result.status === false && result.error_type === 'check_token') {
      updateUserTokenValidate(false);
      setcartQuantityLoderLoader(false);
      sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_ids]: false }));
    }
    if (result && result.data.status === true) {
      if (item_detail_api === 'item_detail_api') {
        let updatedItemDetail = getItemDetail;
        if (getItemDetail.SystemId === item_system_id) {
          updatedItemDetail = {
            ...getItemDetail,
            is_cart: getItemDetail.is_cart = 1,
            quantity: Quality
          };
        }
        setGetItemDetails(updatedItemDetail);

        const updatedSimilarItems = getItemBySubCategoryDetail && getItemBySubCategoryDetail.map(item =>
          item.SystemId === item_system_ids ? { ...item, is_cart: 1, quantity: Quality } : item
        );
        setGetItemBySubCategoryDetail(updatedSimilarItems);
        
        setInputValue(prevState => ({ ...prevState, [item_system_ids]: Quality }));
        setcartQuantityLoderLoader(false);
        sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_ids]: false }));
        setCartItemQuantity(Quality);
        getCart();
        getItemCartDetails();
      }
      if (item_detail_api === 'get_items') {
        // setCartItemQuantity(Quality);
        const updatedSimilarItems = getItemBySubCategoryDetail && getItemBySubCategoryDetail.map(item =>
          item.SystemId === item_system_ids ? { ...item, is_cart: 1, quantity: Quality } : item
        );
        
        setInputValue(prevState => ({ ...prevState, [item_system_ids]: Quality }));
        sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_ids]: false }));
        setGetItemBySubCategoryDetail(updatedSimilarItems);
        getCart();
        setcartQuantityLoderLoader(false);
        getItemCartDetails();
        // getItemByCategories(item_system_ids, itemCategoryId, itemSubCategoryId);
      }
      toast.success(result.data.message);
    }
  };

  // Add Item To Wishlist API
  const addItemToWishlist = async (item_system_id, sales_price_system_id, item_details) => {
    setAddToWishlistLoader(true);
    try {
      let data = {
        item_system_id: item_system_id,
        sales_price_system_id: sales_price_system_id
      };
      const result = await apipost('wishlist/add-to-wishlist', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
      }
      if (result && result.data && result.data.status === false) {
        toast.error(result.data.message);
      }
      if (result && result.data.status === true) {
        if (item_details !== undefined) {
          let updatedItemDetail = getItemDetail;
          if (getItemDetail.SystemId === item_system_id) {
            updatedItemDetail = {
              ...getItemDetail,
              is_wishlist: getItemDetail.is_wishlist === 0 ? 1 : 0
            };
          }
          setGetItemDetails(updatedItemDetail);
          
          const updatedSimilarItems = getItemBySubCategoryDetail.map(item =>
            item.SystemId === item_system_id ? { ...item, is_wishlist: item.is_wishlist === 0 ? 1 : 0 } : item
          );
          setGetItemBySubCategoryDetail(updatedSimilarItems);
          getWishList(item_system_id);

        } else {
          const updatedSimilarItems = getItemBySubCategoryDetail.map(item =>
            item.SystemId === item_system_id ? { ...item, is_wishlist: item.is_wishlist === 0 ? 1 : 0 } : item
          );
          setGetItemBySubCategoryDetail(updatedSimilarItems);
          getWishList(item_system_id);
        }
        toast.success(result.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAddToWishlistLoader(false);
    }
  };

  const getItemByCategories = useCallback(async (SystemId, category_id, sub_category_id, not_call_spinner) => {
    try {
      if(not_call_spinner !== "not_call_spinner"){
        setItemByCategoriesSpinner(true);
      }
      let getItemResult = '';
      const data = {
        category_id: category_id === undefined ? itemCategoryId.toString() : category_id.toString(),
        sub_category_id: sub_category_id === undefined ? itemSubCategoryId.toString() : sub_category_id.toString(),
        limit: 7
      };
      
      if (token) {
        getItemResult = await apipost('category/get-items', data);
      } else {
        getItemResult = await apipost('get-items', data);
      }
      if (getItemResult && getItemResult.status === false && getItemResult.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setItemByCategoriesSpinner(false);
      }

      if (getItemResult && getItemResult.data && getItemResult.data.status === false) {
        toast.error(getItemResult.data.message);
        setItemByCategoriesSpinner(false);
      }

      if (getItemResult && getItemResult.data && getItemResult.data.data && getItemResult.data.status === true) {
        
        setGetItemBySubCategoryDetail(getItemResult.data.data);
        getItemCartDetails();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setItemByCategoriesSpinner(false);
    }
  }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate]);

  return (
    <React.Fragment>
      <MobileHeader header_title={'Item Details'} getCartDetails={getCartDetails}/>
      {spinner === false  && itemByCategoriesSpinner === false ? (
        <div className="bodytop90 body-gray">
          <section className="pt-2 pb-2 position-relative">
            <div className="container">
              <div className="product-details-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-xl-6 col-12">
                      <div className="product-image-slider mob-mb1">
                        <div id="carouselProductImageSlider" className="carousel slide" data-bs-ride="carousel">
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img src={getItemDetail.image ? getItemDetail.image : '../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../asset/img/no_image_available.jpg'; }} className="w-75 m-auto d-block" alt={getItemDetail.Description} />
                            </div>
                          </div>
                          <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselProductImageSlider" data-bs-slide-to="0" className="active thumbnail" aria-current="true" aria-label="Slide 1">
                              <img src={getItemDetail.image ? getItemDetail.image : '../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../asset/img/no_image_available.jpg'; }} className="d-block w-100" alt={getItemDetail.Description} />
                            </button>
                          </div>
                          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12">
                      <div className="product-description-details">
                        <div className="row">
                          <div className="col-10">
                            <h2 className="product-Tile">{getItemDetail.Description}</h2>
                            <p className="text-muted p-18">{getItemDetail.Base_Unit_of_Measure} 1</p>
                            <p className="text-muted p-18">Category : {getItemDetail.category_name}</p>
                            <p className="text-muted p-18">Sub Category : {getItemDetail.sub_category_name}</p>
                            <p className="text-muted p-18">Sub Sub Category : {getItemDetail.sub_sub_category_name}</p>
                          </div>
                          {token ? (
                            getItemDetail.is_wishlist === 1 ? (
                              <a className="add-fav2 active" onClick={() => cart_quantity_loder === false && addToWishlistLoader === false && addItemToWishlist(getItemDetail.SystemId, sales_price_system_id, 'item-detail-api')}><i className="bi bi-heart-fill"></i></a>
                            ) : (
                              <a className="add-fav2" onClick={() => cart_quantity_loder === false &&addToWishlistLoader === false && addItemToWishlist(getItemDetail.SystemId, sales_price_system_id, 'item-detail-api')}><i className="bi bi-heart"></i></a>
                            )
                          ) : (
                            <a className="add-fav2" data-bs-toggle="modal" data-bs-target="#loginModal"><i className="bi bi-heart"></i></a>
                          )}
                        </div>
                        <div className="pricetag-container">
                          {localStorage.getItem('customer_type') !== 'Retailer' && (
                            <React.Fragment>
                            {allPrice && allPrice.map((price) => {
                              if (price.Unit_Price !== minPrice.toString() && price.Minimum_Quantity !== '0' && price.Minimum_Quantity !== '1') {
                                var is_total_quantity = 0;
                                const total_Unit_Price = minPrice - price.Unit_Price;
                                var discount_percentage = (total_Unit_Price * 100) / minPrice;
                                if (price.Minimum_Quantity <= cartItemQuantity) {
                                  is_total_quantity = 1;
                                  var discount_price = total_Unit_Price * cartItemQuantity;
                                }
                              }
                              return (
                                <React.Fragment key={price.SystemId}>
                                  {token && (
                                    price.Unit_Price !== minPrice.toString() && price.Minimum_Quantity !== '0' && price.Minimum_Quantity !== '1' && (
                                      <div className="row align-items-center">
                                        {is_total_quantity === 1 ? (
                                          <React.Fragment>
                                            <div className="col-lg-9 col-xl-9 col-9">
                                              <h5 className="mb-0 text-success">₹{discount_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} saved on {price.Minimum_Quantity} {getItemDetail.Base_Unit_of_Measure} (₹{price.Unit_Price}/{getItemDetail.Base_Unit_of_Measure}) </h5>
                                            </div>
                                            <div className="col-lg-3 col-xl-3 col-3 text-center">
                                              <p className="mb-0 text-success"><i className="bi bi-check-circle-fill p-18"></i></p>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div className="col-lg-9 col-xl-9 col-9">
                                              <h5 className="mb-0 text-blue">{discount_percentage && discount_percentage.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}% OFF for first {price.Minimum_Quantity} (₹{price.Unit_Price}/{getItemDetail.Base_Unit_of_Measure})</h5>
                                            </div>
                                            {token ? (
                                              <div className="col-lg-3 col-xl-3 col-3 p-0 text-center">
                                                <Link><h5 className="mb-0 text-danger" onClick={() =>cart_quantity_loder === false &&  handleAddToCart(getItemDetail.SystemId, price.SystemId, price.Minimum_Quantity, getItemDetail.No, getItemDetail.Sales_Unit_of_Measure, getItemDetail.qtyPerUnitOfMeasure, ItemGroup, 'item_detail_api', getItemDetail.GST_Group_Code)}>Add {price.Minimum_Quantity}</h5></Link>
                                              </div>
                                            ) : (
                                              <div className="col-lg-3 col-xl-3 col-3 p-0 text-center">
                                                <h5 className="mb-0 text-danger" data-bs-toggle="modal" data-bs-target="#loginModal">Add {price.Minimum_Quantity}</h5>
                                              </div>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </div>
                                    )
                                  )}
                                </React.Fragment>
                              )
                            })}
                            <div className="row align-items-center mt-4">
                              <div className="col-lg-9 col-xl-9 col-7">
                                <h3>₹{minPrice}/<span className="p-18">{getItemDetail.Base_Unit_of_Measure}</span></h3>
                                <p className="mb-0 text-muted">Multiples of {getItemDetail.qtyPerUnitOfMeasure} {getItemDetail.Base_Unit_of_Measure}</p>
                              </div>
                              {token ? (
                                getItemDetail.is_cart === 0 ? (
                                  <div onClick={() => cart_quantity_loder === false && handleAddToCart(getItemDetail.SystemId, sales_price_system_id, 1, getItemDetail.No, getItemDetail.Sales_Unit_of_Measure, getItemDetail.qtyPerUnitOfMeasure, ItemGroup, 'item_detail_api', getItemDetail.GST_Group_Code)} className="col-lg-3 col-xl-3 col-5">
                                    <button type="button" className="btn add-btn w-100">ADD <i className="bi bi-plus-lg ms-2"></i></button>
                                  </div>
                                  ) : (
                                    handleQuantityChangeLoader[getItemDetail.SystemId] ? (
                                      <Loader spinner={'item_cart_button'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                    ) : (
                                    <div className="col-lg-3 col-xl-3 col-5">
                                      <div className="number">
                                        <input type="text" className="pm-counter w-100" inputMode="numeric" pattern="[0-9]*" min='0' max='99999' value={inputValue && inputValue[getItemDetail.SystemId] !== undefined ? inputValue [getItemDetail.SystemId] : cartItemQuantity && cartItemQuantity} onChange={(e) => cart_quantity_loder === false && handleInputChange(e, getItemDetail.SystemId)} onBlur={(e) => {
                                          const value = Math.max(0, Math.min(99999, Number(e.target.value)));
                                          let quantity = '';
                                          if (value === 0) {
                                            quantity = 'decrease_quantity';
                                          } else {
                                            quantity = 'quantity';
                                          }
                                          cart_quantity_loder === false && handleInputBlur(e, getItemDetail.SystemId, sales_price_system_id, cartId, getItemDetail.No, getItemDetail.Sales_Unit_of_Measure, getItemDetail.qtyPerUnitOfMeasure, ItemGroup, 'item_detail_api', quantity, getItemDetail.GST_Group_Code)
                                        }} onFocus={(e) => handleInputChange(e, getItemDetail.SystemId, "onFocus")} />
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <div className="col-lg-3 col-xl-3 col-5">
                                  <button type="button" className="btn add-btn w-100" data-bs-toggle="modal" data-bs-target="#loginModal">ADD <i className="bi bi-plus-lg ms-2"></i></button>
                                </div>
                              )}
                            </div>
                            </React.Fragment>
                          )}
                        </div>
                        <div>
                          {getItemDetail.product_detail && (
                            <React.Fragment>
                              <h2 className="product-Tile mb-3">Product Details</h2>
                              <ul className="product-description-bullet">
                                <li><p>{getItemDetail.product_detail}</p></li>
                              </ul>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {getItemBySubCategoryDetail && getItemBySubCategoryDetail.length > 0 && (
            <section>
              <div className="container" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}  >
                <div className="row align-items-center">
                  <div className="col-12">
                    <h5 className="Heading-Small">Similar Item</h5>
                  </div>
                </div>
                <Swiper
                  
                  style={{
                    "--swiper-navigation-color": "#E03546",
                  }}
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={2}
                  slidesPerView={deviceType === 'mobile' ? 1 : 4}
                  navigation
                >
                  {getItemBySubCategoryDetail && getItemBySubCategoryDetail.map((get_item_by_sub_category_detail, index) => {
                    const validPrice = get_item_by_sub_category_detail.Prices.find(price => price.order === 1);

                    const price = validPrice && validPrice.Unit_Price;
                    const sales_price_system_id = validPrice && validPrice.SystemId;

                    const getCartDetail = getCartDetails && getCartDetails.get_cart.find((cart => cart.item_system_id === get_item_by_sub_category_detail.SystemId));
                    return (
                      price > 0 && (
                        item_system_id && item_system_id !== get_item_by_sub_category_detail.SystemId && (
                        <SwiperSlide key={get_item_by_sub_category_detail.SystemId}>
                          <div className="item">
                            <div className="product-item-card">
                              {token ? (
                                get_item_by_sub_category_detail.is_wishlist === 1 ? (
                                  <a className="add-fav active" onClick={() => cart_quantity_loder === false &&addToWishlistLoader === false && addItemToWishlist(get_item_by_sub_category_detail.SystemId, sales_price_system_id)}><i className="bi bi-heart-fill"></i></a>
                                ) : (
                                  <a className="add-fav" onClick={() => cart_quantity_loder === false && addToWishlistLoader === false && addItemToWishlist(get_item_by_sub_category_detail.SystemId, sales_price_system_id)}><i className="bi bi-heart"></i></a>
                                )
                              ) : (
                                <a className="add-fav" data-bs-toggle="modal" data-bs-target="#loginModal"><i className="bi bi-heart"></i></a>
                              )}
                              <Link to={cart_quantity_loder === false && `/item-details/${get_item_by_sub_category_detail.SystemId}`} className="text-decoration-none">
                                <div className="product-item-image">
                                  <img src={get_item_by_sub_category_detail.image ? get_item_by_sub_category_detail.image : '../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../asset/img/no_image_available.jpg'; }} />
                                </div>
                              </Link>
                              <div className="card-body height127 text-start">
                                <Link to={cart_quantity_loder === false && `/item-details/${get_item_by_sub_category_detail.SystemId}`} className="text-decoration-none">
                                  <p className="product-title">{get_item_by_sub_category_detail.Description}</p>
                                </Link>
                              </div>
                              <div className="card-footer text-start">
                                {localStorage.getItem('customer_type') !== 'Retailer' && (
                                  <React.Fragment>
                                  {get_item_by_sub_category_detail.Prices && get_item_by_sub_category_detail.Prices.map((multi_price) => {
                                    if (multi_price.Unit_Price !== price.toString() && multi_price.Minimum_Quantity !== '0' && multi_price.Minimum_Quantity !== '1') {
                                      var is_total_quantity = 0;
                                      const total_Unit_Price = price - multi_price.Unit_Price;
                                      var discount_percentage = (total_Unit_Price * 100) / price;
                                      var cart_quantity = getCartDetail && getCartDetail.quantity;
                                      if (multi_price.Minimum_Quantity <= cart_quantity) {
                                        is_total_quantity = 1;
                                        var discount_price = total_Unit_Price * cart_quantity;
                                      }
                                    }
                                    return (
                                      multi_price.Unit_Price !== price.toString() && multi_price.Minimum_Quantity !== '0' && multi_price.Minimum_Quantity !== '1' && (
                                        <React.Fragment key={multi_price.SystemId}>
                                          {token && (
                                            is_total_quantity === 1 ? (
                                              <div className="offer-item-container">
                                                <div className="product-item-offer">
                                                  <div className="row align-items-center">
                                                    <div className="col-lg-9 col-xl-9 col-9">
                                                      <p className="mb-0 text-success p-12">₹{discount_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} saved on {multi_price.Minimum_Quantity} {get_item_by_sub_category_detail.Base_Unit_of_Measure} (₹{multi_price.Unit_Price}/{get_item_by_sub_category_detail.Base_Unit_of_Measure})</p>
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3 col-3 text-center">
                                                      <p className="mb-0 text-success"><i className="bi bi-check-circle-fill p-18"></i></p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="offer-item-container">
                                                <div className="product-item-offer">
                                                  <div className="row align-items-center">
                                                    <div className="col-lg-9 col-xl-9 col-9">
                                                      <p className="mb-0 text-blue p-12">{discount_percentage && discount_percentage.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}% OFF for first {multi_price.Minimum_Quantity} (₹{multi_price.Unit_Price}/{get_item_by_sub_category_detail.Base_Unit_of_Measure})</p>
                                                    </div>
                                                    {token ? (
                                                      <div className="col-lg-3 col-xl-3 col-3 p-0 text-center">
                                                        <p className="mb-0 text-danger p-12" onClick={() => cart_quantity_loder === false && handleAddToCart(get_item_by_sub_category_detail.SystemId, multi_price.SystemId, multi_price.Minimum_Quantity, get_item_by_sub_category_detail.No, get_item_by_sub_category_detail.Sales_Unit_of_Measure, get_item_by_sub_category_detail.qtyPerUnitOfMeasure, validPrice && validPrice.Item_Group, 'get_items', get_item_by_sub_category_detail.GST_Group_Code)}>Add {multi_price.Minimum_Quantity}</p>
                                                      </div>
                                                    ) : (
                                                      <div className="col-lg-3 col-xl-3 col-3 p-0 text-center">
                                                        <p className="mb-0 text-danger p-12" data-bs-toggle="modal" data-bs-target="#loginModal">Add {multi_price.Minimum_Quantity}</p>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </React.Fragment>
                                      )
                                    )
                                  })}
                                  <div className="row align-items-center card-footer-height">
                                    <div className="col-lg-7 col-xl-7 col-7 mob-mb1">
                                      <Link to={`/item-details/${get_item_by_sub_category_detail.SystemId}`} className="text-decoration-none">
                                        <h5 className="mb-0"><strong>₹{price}/{get_item_by_sub_category_detail.Base_Unit_of_Measure}</strong></h5>
                                        <p className="mb-0 text-muted">Multiples of {get_item_by_sub_category_detail.qtyPerUnitOfMeasure} {get_item_by_sub_category_detail.Base_Unit_of_Measure}</p>
                                      </Link>
                                    </div>
                                    {token ? (
                                      get_item_by_sub_category_detail.is_cart === 0 ? (
                                        handleQuantityChangeLoader[get_item_by_sub_category_detail.SystemId]  ? (
                                          <Loader spinner={'button'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                        ) : get_item_by_sub_category_detail.is_cart === 0 && (
                                          <div className="col-xl-5 col-lg-5 col-5">
                                            <button type="button" className="btn add-btn w-100" onClick={() => cart_quantity_loder === false && handleAddToCart(get_item_by_sub_category_detail.SystemId, sales_price_system_id, 1, get_item_by_sub_category_detail.No, get_item_by_sub_category_detail.Sales_Unit_of_Measure, get_item_by_sub_category_detail.qtyPerUnitOfMeasure, validPrice && validPrice.Item_Group, 'get_items', get_item_by_sub_category_detail.GST_Group_Code)}>ADD <i className="bi bi-plus-lg ms-2"></i></button>
                                          </div>
                                          )
                                        ) : (
                                          handleQuantityChangeLoader[get_item_by_sub_category_detail.SystemId] ? (
                                            <Loader spinner={'button'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                          ) : (
                                            <div className="col-xl-5 col-lg-5 col-5">
                                              <div className="number">
                                                <input type="text" className="pm-counter w-100"  inputMode="numeric" pattern="[0-9]*" min='0' max='99999'
                                                  value={inputValue && inputValue[get_item_by_sub_category_detail.SystemId] !== undefined ? inputValue [get_item_by_sub_category_detail.SystemId] : getCartDetail && getCartDetail.quantity}   onChange={(e) => cart_quantity_loder === false && handleInputChange(e, get_item_by_sub_category_detail.SystemId)}
                                                  onBlur={(e) => cart_quantity_loder === false && handleInputBlur(e, get_item_by_sub_category_detail.SystemId, sales_price_system_id, getCartDetail && getCartDetail.id,get_item_by_sub_category_detail.No, get_item_by_sub_category_detail.Sales_Unit_of_Measure,get_item_by_sub_category_detail.qtyPerUnitOfMeasure, validPrice && validPrice.Item_Group, 'get_items', quantity, get_item_by_sub_category_detail.GST_Group_Code)} onFocus={(e) => handleInputChange(e, get_item_by_sub_category_detail.SystemId, "onFocus")} />
                                              </div>
                                            </div>
                                          ) 
                                        )
                                      ) : (
                                        <div className="col-xl-5 col-lg-5 col-5">
                                          <button type="button" className="btn add-btn w-100" data-bs-toggle="modal" data-bs-target="#loginModal">ADD <i className="bi bi-plus-lg ms-2"></i></button>
                                        </div>
                                      )}
                                  </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    )
                    )
                  })}
                </Swiper>
              </div>
            </section>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}
