import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../service/AuthContext';
import Loader from '../Layout/Loader';

export default function Page({ getCart, getWishList }) {
    const { page_id } = useParams();

    const { token, pageDetail } = useAuth();

    const page_details = pageDetail && pageDetail.find(page => page.id === parseInt(page_id));

    useEffect(() => {
        if (token) {
            getCart();
            getWishList();
        }
    }, [token, getCart, getWishList]);

    return (
        <React.Fragment>
            {page_details ? (
                <div className="bodytop90">
                    <section>
                        <div className="container">
                            <article>
                                <header>
                                    <h1 className="docTitle_Oumm">{page_details.page_name}</h1>
                                </header>
                                <div className="markdown">
                                    <p dangerouslySetInnerHTML={{ __html: page_details.description }} />
                                </div>
                            </article>
                        </div>
                    </section>
                </div>
            ) : (
                <Loader />
            )}
        </React.Fragment>
    )
}
