import React, { useCallback, useEffect, useState, useRef } from 'react';// Images
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../service/AuthContext';
import { apipost } from '../../../service/API';
import $ from 'jquery';

export default function MobileHeader({ header_title, headerTitle, getCartDetails  }) {
  const { token, isBackEndLogin, updateToken, isLoggedIn, updateUserTokenValidate, userTokenValidate } = useAuth();
  const navigate = useNavigate();
  const [getSearchItem, setGetSearchItem] = useState('');
  const [searchItem, setSearchItem] = useState('');
  
  const [inputValue, setInputValue] = useState('');
  const [getHeader, setGetHeader] = useState('');
  const timeoutRef = useRef(null);
  const headerRef = useRef(null);
  const searchInputRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (headerRef.current && !headerRef.current.contains(event.target)) {
          setGetSearchItem('');
          setGetHeader("");
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

  useEffect(() => {
    if (getHeader === 'search' && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [getHeader]);

  const getHeaders = useCallback(async () => {
      setGetHeader("search");
  })
  const searchitem = useCallback(async (searchItem) => {
    setSearchItem(searchItem);
    setInputValue(searchItem);
    try{
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(async () => {
        let data = {
          Item_Name: searchItem === '' ? '' : searchItem
        };
        const result = await apipost('item/search-item', data);
        if (result && result.status === false && result.error_type === 'check_token') {
            updateUserTokenValidate(false);
            setGetSearchItem("");
        }
        if (result && result.status === false) {
          setGetSearchItem("");
        }
        if (result && result.data && result.data.status === true) {
          setGetSearchItem(result.data.data);
        }
    }, 600);
    } catch (error){
      console.error(error);
    } finally {
    }
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate, getHeader]);

useEffect(() => {
  $('#search-item').val('');
  setGetSearchItem('');
  setGetHeader("");
}, [location.pathname]);
  
  return (
    <React.Fragment>
      <header className="login-header desktop-hide mobile-show">
        <nav className="nabar">
          <div className="container-fluid">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-lg-8 col-xl-8 col-12">
                <div className="row align-items-center">
                  {getHeader !== "search" ? (
                    <React.Fragment>
                      <div className="col-lg-8 col-xl-8 col-7 change-category">
                        <div className="row align-items-center">
                          <div className="col-2">
                          {headerTitle !== "Change Category" && headerTitle !== "Cart" ? (
                            <Link onClick={() => navigate(-1)} className="text-dark"><i className="bi bi-chevron-left"></i></Link>
                          ):(
                            <Link to="/" className="text-dark"><i className="bi bi-chevron-left"></i></Link>
                          )}
                          </div>
                          <div className="col-10 p-0">
                            {headerTitle !== "Change Category" && headerTitle !== "Cart"  ? (
                              <Link type="button">
                                <p className="text-elipsis p-600 text-dark mb-0">{header_title}</p>
                              </Link>
                            ):(
                              header_title
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-4 col-5 text-end">
                        {headerTitle !== "Hide-Icone" && (
                          <nav>
                            {header_title !== "Address" && headerTitle !== "Cart" && token ? (
                              <ul className="iconNavigation">
                                <li className="mobile-search-btn">
                                    <Link style={{ lineHeight: '40px' }}>
                                      <img src="../../../asset/img/searchThick.png" width="18" alt="Search" 
                                      onClick={(e) => getHeaders()}
                                      />
                                    </Link>
                                </li>
                                <li>
                                  <Link to="/my-cart" className="active">
                                    <i className="bi bi-cart2"></i>
                                    <div className="cart-counter">{getCartDetails ? getCartDetails.total_items : 0}</div>
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              <ul className="iconNavigation">
                                {headerTitle !== "Cart" && token ? (
                                  <a className="float-end" data-bs-toggle="offcanvas" data-bs-target="#addressAdd"
                                  aria-controls="addressAdd">Add New</a>
                                ):(
                                  token ? (
                                    <li className="mobile-search-btn">
                                      <Link className="trash-btn" data-bs-toggle="modal" data-bs-target="#cart_delete">
                                        <i className="bi bi-trash text-danger"></i>
                                      </Link>
                                    </li>
                                  ) : (
                                    <div className="col-lg-10 col-xl-10 col-md-11 col-sm-8 col-11 d-flex justify-content-end">
                                      <Link className="btn btn-primary btn-rounded13 text-center" data-bs-toggle="modal" data-bs-target="#loginModal">
                                        Login/Signup
                                      </Link>
                                   </div>
                                  )
                                )}
                              </ul>
                            )}
                          </nav>
                        )}
                      </div>
                    </React.Fragment>
                   ) :(
                  <div className="col-lg-8 col-xl-8 col-12">
                    <div className="row align-items-center">
                      <div className="col-lg-8 col-xl-8 col-9" ref={headerRef}>
                        <div className="form-group mb-0">
                            <span className="icon-flag">
                                <img src="/../../../asset/img/searchThick.png" width="18" alt="Search Icon" />
                            </span>
                            <input
                                type="text"
                                className="form-control search-bar"
                                placeholder="Search your need..."
                                value={inputValue && inputValue  !== undefined ? inputValue :searchItem}
                                onChange={(e) => searchitem(e.target.value)}
                                id="search-item" ref={searchInputRef}
                            />
                            {getSearchItem && getSearchItem.length > 0 &&(
                              <div className='search-results'>
                                <ul>
                                  {getSearchItem.map((get_search_item, index) => (
                                    <Link to={`/item-details/${get_search_item.SystemId}`} className="text-decoration-none" key={index} onClick={() => searchitem('hideResults')}>
                                      <li className="search-item">
                                        <div className="search-item-content">
                                          <img src={get_search_item.image ? get_search_item.image : '/../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '/../asset/img/no_image_available.jpg'; }} width="60px"/>
                                          <span>{get_search_item.Description}</span>
                                        </div>
                                      </li>
                                    </Link>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-4 col-3 text-end">
                        <nav>
                          <ul className="iconNavigation">
                            <li>
                              <Link to="/my-cart" className="active">
                                <i className="bi bi-cart2"></i>
                                <div className="cart-counter">{getCartDetails ? getCartDetails.total_items : 0}</div>
                              </Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                   )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  )
}
