import React, { useEffect,useRef } from 'react';

export default function CorporateAnnouncements({ }) {
    const hasFetchedscrollToTop = useRef(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    };
    
    useEffect(() => {
        if (!hasFetchedscrollToTop.current) {
        scrollToTop();
        hasFetchedscrollToTop.current = true;
        }
    }, [scrollToTop]);

    return (
        <React.Fragment>
            <div className="bodytop90">
                <section>
                    <div className="container">
                        <article>
                            <header>
                                <h4 className="docTitle_Oumm">Corporate Announcements</h4>
                            </header>
                            <div className="markdown">
                                <h1 className="docTitle_Oumm">Coming Soon...</h1>
                            </div>
                        </article>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}
