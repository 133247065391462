import React from 'react';
import { useAuth } from '../../../../service/AuthContext';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import Loader from '../../Layout/Loader';

export default function ItemByCategory({ getItemByCategoryDetail, getCartDetails, addItemToWishlist, handleAddToCart, handleInputChange, handleInputBlur, inputValue, cart_quantity_loder, handleQuantityChangeLoader }) {
  // Custom Hook
  const { token, deviceType } = useAuth();

  return (
    <React.Fragment>
      {getItemByCategoryDetail && getItemByCategoryDetail.length > 0 && token && (
        getItemByCategoryDetail && getItemByCategoryDetail.map((getItemByCategory) => (
          getItemByCategory.get_items && getItemByCategory.get_items.length > 0 && (
            <React.Fragment key={getItemByCategory.id}>
              <section>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-9">
                      <div className="row align-items-center">
                        <div className="col-lg-auto col-xl-auto col-2">
                          <img src={getItemByCategory.category_image ? getItemByCategory.category_image : './asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = './asset/img/no_image_available.jpg'; }} alt={`${getItemByCategory.category_name} image`} className="Heading-icon" />
                        </div>
                        <div className="col-lg-10 col-xl-10 col-10">
                          <h5 className="Heading-Small text-elipsis mb-0">{getItemByCategory.category_name}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 text-end">
                      <Link to={`/shop-by-category/${getItemByCategory.id}`} className="float-end see-all">See all</Link>
                    </div>
                  </div>
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#E03546",
                    }}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={2}
                    slidesPerView={deviceType === 'mobile' ? 1 : 4}
                    navigation
                  >
                    {getItemByCategory.get_items.map((item) => {
                      const validPrice = item.Prices.find(price => price.order === 1);

                      const price = validPrice && validPrice.Unit_Price;
                      const sales_price_system_id = validPrice && validPrice.SystemId;
                      const Item_Group = validPrice && validPrice.Item_Group;
                      const Unit_Price = validPrice && validPrice.Unit_Price;

                      const getCartDetail = getCartDetails && getCartDetails.get_cart.find((cart => cart.item_system_id === item.SystemId));

                      return (
                        <SwiperSlide key={item.SystemId}>
                          <React.Fragment>
                            {price > 0 && (
                              <div className="item">
                                <div className="product-item-card">
                                  {item.is_wishlist === 0 ? (
                                    <span onClick={() => cart_quantity_loder === false && addItemToWishlist(item.SystemId, sales_price_system_id, item.category_id)} className="add-fav"><i className="bi bi-heart"></i></span>
                                  ) : (
                                    <span onClick={() => cart_quantity_loder === false && addItemToWishlist(item.SystemId, sales_price_system_id, item.category_id)} className="add-fav active"><i className="bi bi-heart-fill"></i></span>
                                  )}

                                  <Link to={cart_quantity_loder === false && `item-details/${item.SystemId}`} className="text-decoration-none">
                                    <div className="product-item-image">
                                      <img src={item.image ? item.image : './asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = './asset/img/no_image_available.jpg'; }} />
                                    </div>
                                  </Link>
                                  <div className="card-body height127 text-start">
                                    <Link to={cart_quantity_loder === false && `item-details/${item.SystemId}`} className="text-decoration-none">
                                      <div className="product-title">{item.Description}</div>
                                    </Link>
                                  </div>

                                  <div className="card-footer text-start">
                                    {localStorage.getItem('customer_type') !== 'Retailer' && (
                                      <React.Fragment>
                                        {item && item.Prices.map((multi_price) => {
                                          if (multi_price.Unit_Price !== price.toString() && multi_price.Minimum_Quantity !== '0' && multi_price.Minimum_Quantity !== '1') {
                                            var is_total_quantity = 0;
                                            const total_Unit_Price = price - multi_price.Unit_Price;
                                            var discount_percentage = (total_Unit_Price * 100) / price;
                                            var cart_quantity = getCartDetail && getCartDetail.quantity;
                                            if (multi_price.Minimum_Quantity <= cart_quantity) {
                                              is_total_quantity = 1;
                                              var discount_price = total_Unit_Price * cart_quantity;
                                            }
                                          }
                                          return (
                                            multi_price.Unit_Price !== price.toString() && multi_price.Minimum_Quantity !== '0' && multi_price.Minimum_Quantity !== '1' && (
                                              <React.Fragment key={multi_price.SystemId}>
                                                {token && (
                                                  is_total_quantity === 1 ? (
                                                    <div className="offer-item-container">
                                                      <div className="product-item-offer">
                                                        <div className="row align-items-center">
                                                          <div className="col-lg-9 col-xl-9 col-9">
                                                            <p className="mb-0 text-success p-12">₹{discount_price.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} saved on {multi_price.Minimum_Quantity} {item.Base_Unit_of_Measure} (₹{multi_price.Unit_Price}/{item.Base_Unit_of_Measure})</p>
                                                          </div>
                                                          <div className="col-lg-3 col-xl-3 col-3 text-center">
                                                            <p className="mb-0 text-success"><i className="bi bi-check-circle-fill p-18"></i></p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="offer-item-container">
                                                      <div className="product-item-offer">
                                                        <div className="row align-items-center">
                                                          <div className="col-lg-9 col-xl-9 col-9">
                                                            <p className="mb-0 text-blue p-12">{discount_percentage && discount_percentage.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}% OFF for first {multi_price.Minimum_Quantity} (₹{multi_price.Unit_Price}/{item.Base_Unit_of_Measure})</p>
                                                          </div>
                                                          {token ? (
                                                            <div className="col-lg-3 col-xl-3 col-3 p-0 text-center">
                                                              <p className="mb-0 text-danger p-12" onClick={() => cart_quantity_loder === false && handleAddToCart(item.SystemId, multi_price.SystemId, multi_price.Minimum_Quantity, item.No, item.Sales_Unit_of_Measure, item.category_id, multi_price.Item_Group, item.qtyPerUnitOfMeasure, multi_price.Unit_Price, item.GST_Group_Code,)}>Add {multi_price.Minimum_Quantity}</p>
                                                            </div>
                                                          ) : (
                                                            <div className="col-lg-3 col-xl-3 col-3 p-0 text-center">
                                                              <p className="mb-0 text-danger p-12" data-bs-toggle="modal" data-bs-target="#loginModal">Add {multi_price.Minimum_Quantity}</p>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </React.Fragment>
                                            )
                                          )
                                        })}

                                        <div className="row align-items-center card-footer-height">
                                          <div className="col-lg-7 col-xl-7 col-7">
                                            <h5 className="mb-0"><strong>₹{price}</strong>/{item.Base_Unit_of_Measure}</h5>
                                            <p className="mb-0 text-muted">Multiples of {item.qtyPerUnitOfMeasure} {item.Base_Unit_of_Measure}</p>
                                          </div>
                                          {inputValue && inputValue[item.SystemId] === undefined && item.is_cart === 0 ? (
                                            getCartDetail && getCartDetail.quantity ? (
                                              <Loader spinner={'button'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                            ) : inputValue && inputValue[item.SystemId] === undefined && item.is_cart === 0 && (
                                              <div className="col-xl-5 col-lg-5 col-5">
                                                <button type="button" onClick={() => cart_quantity_loder === false && handleAddToCart(item.SystemId, sales_price_system_id, 1, item.No, item.Sales_Unit_of_Measure, item.category_id, Item_Group, item.qtyPerUnitOfMeasure, Unit_Price, item.GST_Group_Code)} className="btn add-btn w-100">ADD <i className="bi bi-plus-lg ms-2"></i></button>
                                              </div>
                                            )
                                          ) : (
                                            handleQuantityChangeLoader[item.SystemId] ? (
                                              <Loader spinner={'button'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                            ) : (
                                              <div className="col-xl-5 col-lg-5 col-5">
                                                <div className="number">
                                                  <input type="text" className="pm-counter w-100" min="0" max="99999" inputMode="numeric" pattern="[0-9]*" value={inputValue && inputValue[item.SystemId] !== undefined ? inputValue[item.SystemId] : getCartDetail && getCartDetail.quantity} onChange={(e) => cart_quantity_loder === false && handleInputChange(e, item.SystemId)} onBlur={(e) => cart_quantity_loder === false && handleInputBlur(e, item.SystemId, sales_price_system_id, getCartDetail && getCartDetail.id, item.No, item.Sales_Unit_of_Measure, item.category_id, Item_Group, item.qtyPerUnitOfMeasure, Unit_Price, '', item.GST_Group_Code)} onFocus={(e) => handleInputChange(e, item.SystemId, "onFocus")} />
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </section>
            </React.Fragment>
          )
        ))
      )}
    </React.Fragment>
  )
}
