import React from 'react';
import OwlCarousel from 'react-owl-carousel';

export default function Testimonial({ testimonialDetails }) {
  // Testimonial Owl Carousel Setting
  const TestimonialOwlCarouselSetting = {
    loop: false,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 2
      }
    }
  }

  return (
    <React.Fragment>
      <section className="body-gray">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <h6 className="Heading-Medium"> Stories from partners </h6>
            </div>
          </div>
          <OwlCarousel className="stories-carousel owl-carousel owl-theme owl-loaded owl-drag" {...TestimonialOwlCarouselSetting}>
            {testimonialDetails && testimonialDetails.map((getTestimonialDetail) => {
              return (
                <div className="item" key={getTestimonialDetail.id}>
                  <div className="Testimonial-card">
                    <div className="testimonial-card-body">
                      <p className="p-18">{getTestimonialDetail.testimonial}</p>
                    </div>
                    <div className="testimonial-card-footer">
                      <div className="row align-items-center">
                        <div className="col-lg-2 col-xl-2 col-4">
                          <div className="avtar42">
                            <img src={getTestimonialDetail.image ? getTestimonialDetail.image : '../../../asset/img/sidemenu_user_icon.png'} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/sidemenu_user_icon.png'; }} alt="testimonial-image" />
                          </div>
                        </div>
                        <div className="col-lg-10 col-xl-10 col-8">
                          <p className="p-22">{getTestimonialDetail.name}</p>
                          <p className="text-muted">{getTestimonialDetail.designation}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </OwlCarousel>
        </div>
      </section>
    </React.Fragment>
  )
}
