import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { apiget, apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function MyWishList({ getCart, getCartDetails, getWishList }) {

  const navigate = useNavigate();

  const { token, isLoggedIn, updateToken, isBackEndLogin, updateUserTokenValidate, userTokenValidate, deviceType } = useAuth();
  const [getCategoryId, setCategoryId] = useState('');
  const [get_WishList, setGetWishListItems] = useState('');
  const [addToCartLoader, setAddToCartLoader] = useState({});
  const [initialValue, setInitialValue] = useState({});
  const debounceTimeout = useRef(null);
  const [inputValue, setInputValue] = useState({});
  const [cart_quantity_loder, setcartQuantityLoderLoader] = useState(false);
  const [removeToWishlistLoader, setRemoveToWishlistLoader] = useState({});
  const hasFetchedscrollToTop = useRef(false);
  const hasWishListByCategory = useRef(false);

  useEffect(() => {
    if(!hasWishListByCategory.current)
    {
      if (token) {
        getWishListByCategory(getCategoryId);
        hasWishListByCategory.current = true;
      }
    }
  }, [token]);

  // useEffect(() => {
  //   getCart();
  // }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  };
  
  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);

  const removeItemToWishlist = async (wishlist_id) => {
    setRemoveToWishlistLoader(prevState => ({ ...prevState, [wishlist_id]: true }));
    try{
      let data = {
        wishlist_id: wishlist_id,
      };
      const result = await apipost('wishlist/delete-wishlist', data);
      if (result?.status === false && result?.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setRemoveToWishlistLoader(false);
      }
      if (result?.data?.status === false) {
        toast.error(result.data.message);
        setRemoveToWishlistLoader(false);
      }

      if (result?.data?.status === true) {
        toast.success(result.data.message);
        getWishListByCategory(getCategoryId);
        getWishList();
        setRemoveToWishlistLoader(prevState => ({ ...prevState, [wishlist_id]: false }));
      }
    } catch {

    } finally {
    }
  };

  const getWishListByCategory = useCallback(async (category_id) => {
    try {
      const result = await apiget('wishlist/get-wishlist');

      if (result?.status === false && result?.error_type === 'check_token') {
        updateUserTokenValidate(false);
        return;
      }

      if (result?.data?.status === false) {
        toast.error(result.data.message);
        return;
      }

      if (result?.data?.status === true) {
        let wishlistItems = result.data.data;

        if (category_id && category_id !== '') {
          wishlistItems = {
            ...wishlistItems,
            get_wishlist: wishlistItems.get_wishlist.filter(item => item.get_item.category_id === category_id)
          };
        }

        setGetWishListItems(wishlistItems);
        setCategoryId(category_id || '');
      }
    } catch (error) {
      toast.error("An error occurred while fetching the wishlist.");
    }
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

  const debounce = (func, delay) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(func, delay);
  };

  const handleInputChange = (event, item_system_id, onFocus) => {
    let { value } = event.target;
    value = value.replace(/^0+/, '');
    if (/^\d*$/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }
      if(onFocus !== "onFocus") {
        setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));
      } else {
        setInitialValue(prevState => ({ ...prevState, [item_system_id]: value }));
      }
    }
  }

  const handleInputBlur = (event, item_system_id, sales_price_system_id, cart_id, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price, GST_Group_Code) => {
    let { value } = event.target;
    if (/^[0-9]*$/.test(value) && !/^0/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }
      if (initialValue[item_system_id] !== value) {
        setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));
    
        handleQuantityChange(item_system_id, sales_price_system_id, value > 0 ? value : 0, cart_id, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price, GST_Group_Code);
      }
    }
  };
  

  const handleQuantityChange = (item_system_id, sales_price_system_id, newQuantity, cart_id, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price, GST_Group_Code) => {
    setAddToCartLoader(prevState => ({ ...prevState, [item_system_id]: true }));
    setcartQuantityLoderLoader(true);
    if (newQuantity === 0) {
      removeItemFromCart(item_system_id, cart_id);
    } else if (newQuantity > 0) {
      newQuantity = Math.max(newQuantity, 1);
      let new_sales_price_system_id = '';
      get_WishList && get_WishList.get_wishlist.forEach(wishlist => {
        const allValidPrice = wishlist.get_item.Prices.find(price => price.order === 1);
        if (wishlist.item_system_id === item_system_id) {
          const maxPrice = allValidPrice && allValidPrice.Unit_Price;
          wishlist && wishlist.get_item.Prices.forEach(sales_price => {
            if (sales_price.Unit_Price !== maxPrice.toString() && sales_price.Minimum_Quantity !== '0' && sales_price.Minimum_Quantity !== '1') {
              if (newQuantity >= sales_price.Minimum_Quantity) {
                new_sales_price_system_id = sales_price.SystemId;
              } else {
                new_sales_price_system_id = sales_price_system_id;
              }
            } else {
              new_sales_price_system_id = sales_price_system_id;
            }
          });
        }
      });
      handleAddToCart(item_system_id, new_sales_price_system_id, newQuantity, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price,GST_Group_Code);
    }
  };

  const handleAddToCart = async (item_system_id, sales_price_system_id, quantity, item_no, item_sales_unit_of_measure, item_group_code, qty_per_unit_of_measure, item_unit_price, GST_Group_Code) => {
    setAddToCartLoader(prevState => ({ ...prevState, [item_system_id]: true }));
    setcartQuantityLoderLoader(true);
    try {

      let data = {
        item_system_ids: item_system_id,
        sales_price_system_id: sales_price_system_id,
        quantity: quantity === undefined ? 1 : quantity,
        item_no: item_no,
        item_sales_unit_of_measure: item_sales_unit_of_measure,
        item_group_code: item_group_code,
        microsoft_quantity: qty_per_unit_of_measure,
        item_unit_price: item_unit_price,
        gst_group_code: GST_Group_Code,
      };
      const result = await apipost('cart/add-to-cart', data);

      if (result?.status === false && result?.error_type === 'check_token') {
        updateUserTokenValidate(false);
      }

      if (result?.data?.status === true) {
        toast.success(result.data.message);
        
        const updatedWishlist = get_WishList.get_wishlist.map(item => {
          if (item.get_item.SystemId === item_system_id) {
            return {
              ...item,
              is_cart: item.is_cart = 1
            };
          }
          return item;
        });
        
        getWishListByCategory(getCategoryId);
        getCart();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setcartQuantityLoderLoader(false);
      setInputValue(prevState => ({ ...prevState, [item_system_id]: quantity }));
      setAddToCartLoader(prevState => ({ ...prevState, [item_system_id]: false }));
    }
  };

  const removeItemFromCart = useCallback(async (item_system_id, cart_id) => {
    try {
      const data = { cart_id: cart_id };
      const result = await apipost('cart/delete-cart', data);

      if (result?.status === false && result?.error_type === 'check_token') {
        updateUserTokenValidate(false);
        return;
      }

      if (result?.data?.status === false) {
        toast.error(result.data.message);
        return;
      }

      if (result?.data?.status === true) {
        toast.success(result.data.message);

        const updatedCartItems = get_WishList.get_wishlist && get_WishList.get_wishlist.map(item => {
          if (item.get_item.SystemId === item_system_id) {
            return { ...item, is_cart: 0 };
          }
          return item;
        });

        setcartQuantityLoderLoader(false);
        setGetWishListItems({ ...get_WishList, get_wishlist: updatedCartItems });
        getWishListByCategory(getCategoryId);
        getCart();
        setInputValue();
      }
    } catch (error) {
      toast.error("An error occurred while removing the item from the cart.");
    }
  }, [token, get_WishList, getWishListByCategory, getCategoryId, getCart, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

  return (
    <React.Fragment>
      {get_WishList ? (
        <React.Fragment>
          <div className="bodytop90 body-gray">
            <MobileHeader header_title={'My List'} getCartDetails={getCartDetails}/>
            {get_WishList.total_wishlist > 0 ? (
              <section className="pt-3 pb-0 mobile-hide">
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-1">
                        <Link onClick={() => { navigate(-1); setTimeout(() => { window.scrollTo(0, 0); }, 100); }}>
                            <img src='../../../asset/img/Arrow-left.png' className="Heading-icon" height='70px'/>
                        </Link>
                    </div>
                    <div className="col-11">
                      <h1>{get_WishList.total_wishlist} Items In Wish List</h1>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className="mob-pt-0 mob-pb-0 mt-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}><path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z"/></svg>
                      <h3>No Item In Wish List.</h3>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section className="mob-pt-0 position-relative">
              <div className="container">
                <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                  { get_WishList.total_wishlist > 0 && (
                    <div className="col-lg-3 col-xl-3 col-3 mob-pl-0 position-relative">
                      <div className="leftSidebar Mobile-fixed-sidebar">
                        <ul className="leftsidebar-nav" style={{ marginBottom: deviceType === 'mobile' ? '120px' : '' }}>
                          <li>
                            <Link onClick={() => getWishListByCategory("")} className={getCategoryId === '' ? "active" : ""}>
                              <div className="row align-items-center">
                                <div className="col-lg-auto">
                                  <div className="icon-img">
                                    <img src="../../../asset/img/sub_category_image_all_v2.png" />
                                  </div>
                                </div>
                                <div className="col-lg-8 col-xl-8 col-12 p-0">
                                  All
                                </div>
                              </div>
                            </Link>
                          </li>
                          {get_WishList && get_WishList.get_category.map((category, index) => {
                            return (
                              <li key={category.category_id}>
                                <Link className={category.category_id === getCategoryId ? 'active' : ''}>
                                  <div onClick={() => getWishListByCategory(category.category_id)} >
                                    <div className="row align-items-center">
                                      <div className="col-lg-auto">
                                        <div className="icon-img">
                                          <img src={category.category_image ? category.category_image : '../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../asset/img/no_image_available.jpg'; }} />
                                        </div>
                                      </div>
                                      <div className="col-lg-8 col-xl-8 col-12 p-0">
                                        {category.category_name}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-9 col-xl-9 col-9">
                    <div className="row">
                      {get_WishList && get_WishList.get_wishlist.map((wishlist, index) => {

                        // Max Price Calculation
                        const allValidPrice = wishlist.get_item.Prices.find(price => price.order === 1);

                        const maxPrice = allValidPrice && allValidPrice.Unit_Price;
                        const sales_price_system_id = allValidPrice && allValidPrice.SystemId;
                        const Item_Group = allValidPrice && allValidPrice.Item_Group;

                        const getCartDetail = getCartDetails && getCartDetails.get_cart.find((cart => cart.item_system_id === wishlist.get_item.SystemId));

                        return (
                          maxPrice > 0 && (
                            <div className="col-lg-4 col-xl-4 col-12 mb-4" key={wishlist.item_system_id}>
                              <div className="product-item-card">
                                <a className="add-fav active" onClick={() => cart_quantity_loder === false &&  removeToWishlistLoader && removeToWishlistLoader[wishlist.id] !== undefined ? removeToWishlistLoader[wishlist.id] === false : cart_quantity_loder === false && removeItemToWishlist(wishlist.id)}><i className="bi bi-heart-fill"></i></a>
                                <Link to={cart_quantity_loder === false && `/item-details/${wishlist.item_system_id}`} className="text-decoration-none">
                                  <div className="product-item-image">
                                    <img src={wishlist.get_item.image ? wishlist.get_item.image : '../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../asset/img/no_image_available.jpg'; }} alt={wishlist.get_item.Description} />
                                  </div>
                                </Link>
                                <Link to={cart_quantity_loder === false && `/item-details/${wishlist.item_system_id}`} className="text-decoration-none">
                                  <div className="card-body height127 text-start">
                                    <p className="product-title">{wishlist.get_item.Description}</p>
                                  </div>
                                </Link>
                                <div className="card-footer text-start">
                                  {localStorage.getItem('customer_type') !== 'Retailer' && (
                                    <React.Fragment>
                                    {wishlist && wishlist.get_item.Prices.map((sales_price) => {
                                      if (sales_price.Unit_Price !== maxPrice.toString() && sales_price.Minimum_Quantity !== '0' && sales_price.Minimum_Quantity !== '1') {
                                        var is_total_quantity = 0;

                                        const total_Unit_Price = maxPrice - sales_price.Unit_Price;

                                        var discount_percentage = (total_Unit_Price * 100) / maxPrice;

                                        var cart_quantity = getCartDetail && getCartDetail.quantity;
                                        var cart_sales_price_system_id = getCartDetail && getCartDetail.sales_price_system_id;
                                        if (sales_price.SystemId == cart_sales_price_system_id) {
                                          is_total_quantity = 1;
                                          var discount_price = total_Unit_Price * cart_quantity;
                                        }
                                      }

                                      return (
                                        <React.Fragment key={sales_price.SystemId}>
                                          { sales_price.Unit_Price !== maxPrice.toString() && sales_price.Minimum_Quantity !== '0' && sales_price.Minimum_Quantity !== '1' && (
                                            is_total_quantity === 1 ? (
                                              <div className="offer-item-container">
                                                <div className="product-item-offer">
                                                  <div className="row align-items-center">
                                                    <div className="col-lg-9 col-xl-9 col-9">
                                                      <p className="mb-0 text-success p-12">₹{discount_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} saved on {sales_price.Minimum_Quantity} {wishlist.get_item.Base_Unit_of_Measure} (₹{sales_price.Unit_Price}/{wishlist.get_item.Base_Unit_of_Measure})</p>
                                                    </div>
                                                    <div className="col-lg-3 col-xl-3 col-3 text-center">
                                                      <p className="mb-0 text-success"><i className="bi bi-check-circle-fill p-18"></i></p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <React.Fragment>
                                                <div className="offer-item-container" key={sales_price.SystemId}>
                                                  <div className="product-item-offer">
                                                    <div className="row align-items-center">
                                                      <div className="col-lg-9 col-xl-9 col-9">
                                                        <p className="mb-0 text-blue p-12">{discount_percentage && discount_percentage.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}% OFF for first {sales_price.Minimum_Quantity} (₹{sales_price.Unit_Price}/{wishlist.get_item.Base_Unit_of_Measure})</p>
                                                      </div>
                                                      <div className="col-lg-3 col-xl-3 col-3 p-0 text-center">
                                                        <p className="mb-0 text-danger p-12" onClick={() => { cart_quantity_loder === false && handleAddToCart(wishlist.get_item.SystemId, sales_price.SystemId, sales_price.Minimum_Quantity, wishlist.get_item.No, wishlist.get_item.Sales_Unit_of_Measure, Item_Group, wishlist.get_item.qtyPerUnitOfMeasure, maxPrice, wishlist.get_item.GST_Group_Code) }}>Add {sales_price.Minimum_Quantity}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            )
                                          )}
                                        </React.Fragment>
                                      )
                                    })}
                                    <div className="row align-items-center card-footer-height">
                                      <div className="col-lg-7 col-xl-7 col-7">
                                        <Link to={cart_quantity_loder === false && `/item-details/${wishlist.item_system_id}`} className="text-decoration-none">
                                          <h5 className="mb-0">
                                            <strong>
                                              ₹{maxPrice}/{wishlist.get_item.Base_Unit_of_Measure !== '' && (
                                                <span>{wishlist.get_item.Base_Unit_of_Measure}</span>
                                              )}
                                            </strong>
                                          </h5>
                                          <p className="mb-0 text-muted">Multiples of {wishlist.get_item.qtyPerUnitOfMeasure} {wishlist.get_item.Base_Unit_of_Measure}</p>
                                        </Link>
                                      </div>
                                      {wishlist.get_item.is_cart === 0 ? (
                                        getCartDetail && getCartDetail.quantity ? (
                                          <Loader spinner={'button'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                        ) : wishlist.get_item.is_cart === 0 && (
                                          <div className="col-xl-5 col-lg-5 col-5">
                                            <button type="button" className="btn add-btn w-100" onClick={() => cart_quantity_loder === false && handleAddToCart(wishlist.get_item.SystemId, sales_price_system_id, 1, wishlist.get_item.No, wishlist.get_item.Sales_Unit_of_Measure, Item_Group, wishlist.get_item.qtyPerUnitOfMeasure, maxPrice, wishlist.get_item.GST_Group_Code)}>ADD <i className="bi bi-plus-lg ms-2"></i></button>
                                            
                                          </div>
                                        )
                                      ) : (
                                        addToCartLoader[wishlist.get_item.SystemId] ? (
                                          <Loader spinner={'button'} className={'col-xl-5 col-lg-5 col-5 btn add-btn w-100 div button'} />
                                        ) : (
                                          <div className="col-xl-5 col-lg-5 col-5">
                                            <div className="number">
                                              <input type="text" className="pm-counter w-100" inputMode="numeric" pattern="[0-9]*" value={inputValue && inputValue[wishlist.item_system_id] !== undefined ? inputValue[wishlist.item_system_id] : getCartDetail && getCartDetail.quantity}  onChange={(e) => cart_quantity_loder === false && handleInputChange(e, wishlist.item_system_id)} onBlur={(e) => cart_quantity_loder === false && handleInputBlur(e, wishlist.item_system_id, wishlist.sales_price_system_id, getCartDetail.id, wishlist.get_item.No, wishlist.get_item.Sales_Unit_of_Measure, Item_Group, wishlist.get_item.qtyPerUnitOfMeasure, maxPrice, wishlist.get_item.GST_Group_Code)} onFocus={(e) => handleInputChange(e, wishlist.item_system_id, "onFocus")}/>  
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}
