import React from 'react'

export default function FAQ({ faqDetails }) {

  return (
    <React.Fragment>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <h6 className="Heading-Medium mb-5">
                {" "}Frequently asked questions{" "}
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="accordion accordion-flush" id="accordianFAQs">
                {faqDetails &&
                  faqDetails.map((getFaq) => {
                    return (
                      <div className="accordion-item" key={getFaq.id}>
                        <h2
                          className="accordion-header"
                          id={`Faqs-one_${getFaq.id}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse_${getFaq.id}`}
                            aria-expanded="false"
                            aria-controls={`flush-collapse_${getFaq.id}`}
                          >
                            {getFaq.title}
                          </button>
                        </h2>
                        <div
                          id={`flush-collapse_${getFaq.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`Faqs-one_${getFaq.id}`}
                          data-bs-parent="#accordianFAQs"
                        >
                          <div className="accordion-body">
                            <div>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: getFaq.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
