import React, { useCallback, useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Loader from '../Layout/Loader';

import { apiget, apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';

import Banner from './HomeWithLogin/Banner';
import ShopByCategory from './HomeWithLogin/ShopByCategory';
import ItemByCategory from './HomeWithLogin/ItemByCategory';
import Testimonial from './HomeWithLogin/Testimonial';
import { scrollToTop } from '../../../service/ScrollToTop';

export default function HomeWithLogin({ getCart, getCartDetails, getWishList }) {
  // Custom Hooks 
  const { token, isLoggedIn, updateToken, deviceType, isBackEndLogin, updateLoginUserName, backEndLogin } = useAuth();
  // Has API Call
  const hasFetchedGetCategory = useRef(false);
  const hasFetchedApiCall = useRef(false);
  // Banner
  const [banners, setBanners] = useState('');
  // Category
  const [categories, setCategories] = useState('');
  // Item By Category Detail
  const [getItemByCategoryDetail, setGetItemByCategoryDetail] = useState('');
  // Testimonial
  const [testimonialDetails, setTestimonialDetails] = useState('');
  const [inputValue, setInputValue] = useState({});
  const [initialValue, setInitialValue] = useState({});
  // Loaders
  const [handleQuantityChangeLoader, sethandleQuantityChangeLoader] = useState({});
  const [cart_quantity_loder, setcartQuantityLoderLoader] = useState(false);

  // Get Banner API
  const getBanner = useCallback(async () => {
    try {
      const getBannerResult = await apiget('general-setting/get-banner');
      // Get Banner Successfully
      if (getBannerResult?.data?.status === true) {
        setBanners(getBannerResult.data.data);
      }
      // API Error While Get Banner
      if (getBannerResult?.data?.status === false) {
        toast.error(getBannerResult.data.message);
      }
    } catch (error) {
      console.error(`Error while fetching banners: ${error}`);
    }
  }, []);

  // Get Item By Category
  const getItemByCategories = useCallback(async (getCategoryResult) => {
    try {
      // Get Category Successfully
      if (getCategoryResult?.status === true) {
        const updatedCategories = await Promise.all(getCategoryResult.data.map(async (category) => {
          const data = {
            category_id: JSON.stringify(category.id),
            limit: 10
          };
          // Get Item
          const getItemResult = await apipost('category/get-items', data);
          // API Error While Get Item
          if (getItemResult?.data?.status === false || getCategoryResult?.status === false) {
            toast.error(getItemResult.data.message);
          }
          // Get Item Successfully And Push Item According To Category Id
          if (getItemResult?.data?.data && getItemResult?.data?.status === true) {
            return {
              ...category,
              get_items: getItemResult.data.data.filter(item => item.category_id === category.id)
            };
          }
        }));
        setGetItemByCategoryDetail(updatedCategories);
        hasFetchedGetCategory.current = true;
      }
    } catch (error) {
      console.error(`Error while fetching category: ${error}`);
    }
  }, []);

  // Get Category API
  const getCategory = useCallback(async () => {
    try {
      const getCategoryResult = await apiget('category/get-categories');
      // Get Category Successfully
      if (getCategoryResult?.data?.status === true) {
        setCategories(getCategoryResult.data);
        getItemByCategories(getCategoryResult.data);
      }
      // API Error While Get Category
      if (getCategoryResult?.data?.status === false) {
        toast.error(getCategoryResult.data.message);
      }
    } catch (error) {
      console.error(`Error while fetching category: ${error}`);
    }
  }, [getItemByCategories]);

  // Login From Backend
  useEffect(() => {
    isBackEndLogin('fa');
    if (localStorage.getItem('isBackEndLogin') === 'true') {
      const params = new URLSearchParams(window.location.search);
      const user_token = params.get('token');
      const user_name = params.get('name');
      const userPhoneNo = params.get('phone_no');
      if (user_token) {
        localStorage.setItem('token', user_token);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('isBackEndLogin', 'true');

        updateToken(user_token);
        isLoggedIn('true');
        isBackEndLogin('true');
      }
      if (user_name) {
        localStorage.setItem('loginUserName', user_name);
        updateLoginUserName(user_name);
      }
      if (userPhoneNo) {
        localStorage.setItem('loginUserPhoneNo', userPhoneNo);
      }
      if (!hasFetchedGetCategory.current) {
        getCategory();
      }

      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [updateToken, backEndLogin, isBackEndLogin, isLoggedIn, updateLoginUserName, getCategory]);

  // Get Testimonial API
  const getTestimonial = useCallback(async () => {
    try {
      const getTestimonialResult = await apiget('general-setting/get-testimonial');
      // Get Testimonial Successfully
      if (getTestimonialResult && getTestimonialResult.data.status === true) {
        setTestimonialDetails(getTestimonialResult.data.data);
      }
      // API Error While Get Testimonial
      if (getTestimonialResult && getTestimonialResult.data.status === false) {
        toast.error(getTestimonialResult.data.message);
      }
    } catch (error) {
      console.error(`Error while fetching testimonial: ${error}`);
    }
  }, []);
  
  // Handle Input Field Change
  const handleInputChange = (event, item_system_id, onFocus) => {
    let { value } = event.target;
    value = value.replace(/^0+/, '');
    if (/^\d*$/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }
      if(onFocus !== "onFocus") {
        setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));
      }
      else {
        setInitialValue(prevState => ({ ...prevState, [item_system_id]: value }));
      }
    }
  };

  // Handle Input Max Quantity
  const handleInputBlur = (event, item_system_id, sales_price_system_id, cart_id, item_no, item_sales_unit_of_measure, category_id, item_group_code, qty_per_unit_of_measure, item_unit_price, scroll_to_top, GST_Group_Code) => {
    let { value } = event.target;
    value = value.replace(/^0+/, '');
    if (/^[0-9]*$/.test(value) && !/^0/.test(value)) {
      if (value > 99999) {
        value = 99999;
      }
      if (initialValue[item_system_id] !== value) {
        setInputValue(prevState => ({ ...prevState, [item_system_id]: value }));
        
        // Handle Quantity Change
        handleQuantityChange(item_system_id, sales_price_system_id, value > 0 ? value : 0, cart_id, item_no, item_sales_unit_of_measure, category_id, item_group_code, qty_per_unit_of_measure, item_unit_price, scroll_to_top, GST_Group_Code);
      }
    }
  };
  
  // Handle Quantity Change
  const handleQuantityChange = (item_system_id, sales_price_system_id, newQuantity, cart_id, item_no, item_sales_unit_of_measure, category_id, item_group_code, qty_per_unit_of_measure, item_unit_price, scroll_to_top, GST_Group_Code) => {
    sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: true }));
    setcartQuantityLoderLoader(true);
    if (newQuantity === 0) {
      // Remove Item From Cart
      removeItemFromCart(item_system_id, cart_id, category_id);
    } else if (newQuantity > 0) {
      newQuantity = Math.max(newQuantity, 1);
      var new_sales_price_system_id = '';

      // Loop For Get Item By Category Detail
      getItemByCategoryDetail.forEach(getItemByCategory => {
        if (getItemByCategory?.get_items) {

          // Loop For Items
          getItemByCategory && getItemByCategory.get_items.forEach(item => {
            const validPrice = item.Prices.find(price => price.order === 1);
            if (item.SystemId === item_system_id) {
              const maxPrice = validPrice && validPrice.Unit_Price;

              // Loop For Multiple Sales Price
              item && item.Prices.forEach(sales_price => {
                if (sales_price.Unit_Price !== maxPrice.toString() && sales_price.Minimum_Quantity !== '0' && sales_price.Minimum_Quantity !== '1') {
                  if (newQuantity >= sales_price.Minimum_Quantity) {
                    new_sales_price_system_id = sales_price.SystemId;
                  } else {
                    new_sales_price_system_id = sales_price_system_id;
                  }
                } else {
                  new_sales_price_system_id = sales_price_system_id;
                }
              });
            }
          })
        }
      });
      // Handle Add To Cart
      handleAddToCart(item_system_id, new_sales_price_system_id, newQuantity, item_no, item_sales_unit_of_measure, category_id, item_group_code, qty_per_unit_of_measure, item_unit_price, GST_Group_Code);
    }
  };

  // Handle Add To Cart
  const handleAddToCart = async (item_system_id, sales_price_system_id, quantity = 1, item_no, item_sales_unit_of_measure, category_id, item_group_code, qty_per_unit_of_measure, item_unit_price, GST_Group_Code) => {
    sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: true }));
    setcartQuantityLoderLoader(true);

    let data = {
      item_system_ids: item_system_id,
      sales_price_system_id: sales_price_system_id,
      quantity: quantity,
      item_no: item_no,
      item_sales_unit_of_measure: item_sales_unit_of_measure,
      item_group_code: item_group_code,
      microsoft_quantity: qty_per_unit_of_measure,
      item_unit_price: item_unit_price,
      gst_group_code: GST_Group_Code,
    };
    const result = await apipost('cart/add-to-cart', data);
    // Add To Cart Successfully
    if (result && result.data && result.data.status === true) {
      toast.success(result.data.message);
      // Push is_cart In Get Item By Category Detail
      const updatedCategories = getItemByCategoryDetail.map(category => {
        if (category.id === category_id) {
          const updatedItems = category.get_items.map(item => {
            if (item.SystemId === item_system_id) {
              return {
                ...item,
                is_cart: 1
              };
            }
            return item;
          });
          return {
            ...category,
            get_items: updatedItems
          };
        }
        return category;
      });

      setGetItemByCategoryDetail(updatedCategories);
      sethandleQuantityChangeLoader(prevState => ({ ...prevState, [item_system_id]: false }));
      setcartQuantityLoderLoader(false);
      setInputValue(prevState => ({ ...prevState, [item_system_id]: quantity }));
      getCart();
    }
  };

  // Remove Item From Cart
  const removeItemFromCart = useCallback(async (item_system_id, cart_id, category_id) => {
    try {
      let data = {
        cart_id: cart_id
      };
      const result = await apipost('cart/delete-cart', data);
      // API Error While Delete Cart
      if (result?.data?.status === false) {
        toast.error(result.data.message);
      }
      // Delete Cart Successfully
      if (result?.data?.status === true) {
        toast.success(result.data.message);
        const updatedCategories = getItemByCategoryDetail.map(category => {
          if (category.id === category_id) {
            const updatedItems = category.get_items.map(item => {
              if (item.SystemId === item_system_id) {
                return {
                  ...item,
                  is_cart: 0,
                  quantity: 0
                };
              }
              return item;
            });
            return {
              ...category,
              get_items: updatedItems
            };
          }
          return category;
        });
        setGetItemByCategoryDetail(updatedCategories);
        getCart();
        setInputValue();
      }
    } catch (error) {
      console.error(`Error while delete cart: ${error}`);
    } finally {
      setInputValue(prevState => ({ ...prevState, [item_system_id]: undefined }));
      setcartQuantityLoderLoader(false);
    }
  }, [getItemByCategoryDetail, setGetItemByCategoryDetail]);

  // Add Item To Wish List
  const addItemToWishlist = async (item_system_id, sales_price_system_id, category_id) => {
    try {
      let data = {
        item_system_id: item_system_id,
        sales_price_system_id: sales_price_system_id
      };
      const result = await apipost('wishlist/add-to-wishlist', data);
      // API Error While Add To Wishlist
      if (result && result.data.status === false) {
        toast.error(result.data.message);
      }
      // Add To Wishlist Successfully
      if (result && result.data.status === true) {
        // Loop For Get Item By Category Detail
        const updatedCategories = getItemByCategoryDetail.map(category => {
          if (category.id === category_id) {
            // Loop For Get Item
            const updatedItems = category.get_items.map(item => {
              if (item.SystemId === item_system_id) {
                if (item.is_wishlist === 0) {
                  return {
                    ...item,
                    is_wishlist: 1
                  };
                } else {
                  return {
                    ...item,
                    is_wishlist: 0
                  };

                }
              }
              return item;
            });
            return {
              ...category,
              get_items: updatedItems
            };
          }
          return category;
        });
        setGetItemByCategoryDetail(updatedCategories);
        getWishList();
        toast.success(result.data.message);
      }
    } catch (error) {
      console.error(`Error while add to wishlist: ${error}`);
    }
  };

  useEffect(() => {
    if (!hasFetchedApiCall.current) {
      scrollToTop();

      getBanner();
      getCategory();
      getItemByCategories();
      getTestimonial();

      if (token) {
        getWishList();
        getCart();
      }
      hasFetchedApiCall.current = true;
    }
  }, [getBanner, getCategory, getItemByCategories, getTestimonial]);

  return (
    <React.Fragment>
      {banners && categories && getItemByCategoryDetail && testimonialDetails ? (
        <React.Fragment>
          <div className="bodytop90 body-padding-bottom body-gray">
            {banners && banners.length > 0 && (
              <Banner banners={banners} />
            )}

            <section className="p-0">
              <div className="container">
                <div className="USP-section">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12 border-end mob-mb1">
                        <div className="row align-items-center">
                          <div className="col-lg-2 col-xl-2 col-4">
                            <img src="../../../asset/img/quick-refund.png" alt="quick-refund" className="img-fluid" />
                          </div>
                          <div className="col-lg-10 col-xl-10 col-8">
                            <p className="mb-0"><strong>Get quick refunds xxc{deviceType}</strong></p>
                            <p className="text-muted mb-0">faster complaint resolution</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12 border-end mob-mb1">
                        <div className="row align-items-center">
                          <div className="col-lg-2 col-xl-2 col-4">
                            <img src="../../../asset/img/doorstep-delivery.png" alt="doorstep-delivery" className="img-fluid" />
                          </div>
                          <div className="col-lg-10 col-xl-10 col-8">
                            <p className="mb-0"><strong>Enjoy doorstep delivery</strong></p>
                            <p className="text-muted mb-0">quick & relailbe</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                        <div className="row align-items-center">
                          <div className="col-lg-2 col-xl-2 col-4">
                            <img src="../../../asset/img/beest-price-discount.png" alt="beest-price-discount" className="img-fluid" />
                          </div>
                          <div className="col-lg-10 col-xl-10 col-8">
                            <p className="mb-0"><strong>Pay later</strong></p>
                            <p className="text-muted mb-0">instant credit for 7 days</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <ShopByCategory categories={categories} />

            <ItemByCategory getItemByCategoryDetail={getItemByCategoryDetail} getCartDetails={getCartDetails} addItemToWishlist={addItemToWishlist} handleAddToCart={handleAddToCart} handleInputChange={handleInputChange} handleInputBlur={handleInputBlur} inputValue={inputValue} cart_quantity_loder={cart_quantity_loder} handleQuantityChangeLoader={handleQuantityChangeLoader} />
          </div>

          <Testimonial testimonialDetails={testimonialDetails} />
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}
