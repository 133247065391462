import React, { useCallback, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../service/AuthContext";
import { apiget, apipost } from "../../../service/API";
import Loader from "../Layout/Loader";
import MobileHeader from '../Layout/MobileHeader';
import { scrollToTop } from "../../../service/ScrollToTop";

export default function ListRetailer({ getCartDetails }) {
  // Custom Hook
  const { token, deviceType } = useAuth();
  // Has API Call
  const hasFetchedscrollToTop = useRef(false);
  // const hasFetchedGetCustomer = useRef(false);

  const [getRetailers, setGetRetailers] = useState("");
  const timeoutRef = useRef(null);

  const navigate = useNavigate();

  // Get Retailer
  const getRetailer = useCallback(async () => {
    try {
      let data = {
        customer_role_id: window.location.pathname == '/list-retailer' ? '5' : '4'
      };
      
      const result = await apipost("customer/get-retailer", data);
      if (result && result.data && result.data.status === false) {
        toast.error(result.data.message);
      }
      if (result && result.data && result.data.status === true) {
        setGetRetailers(result.data.data);
      }
    } catch (error) {
      console.error(`Error while fetching retailer: ${error}`);
    }
  }, [token]);

  useEffect(() => {
    // if (!hasFetchedGetCustomer.current) {
      getRetailer();
    //   hasFetchedGetCustomer.current = true;
    // }
    
  }, [getRetailer, window.location.pathname]);

  // Search Retailer
  const searchRetailer = useCallback(async (retailer_name) => {
    try {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(async () => {
          let data = {
            retailer_name: retailer_name === '' ? '' : retailer_name,
            customer_role_id: window.location.pathname === '/list-retailer' ? '5' : '4'
          };
          const result = await apipost('customer/search-retailer', data);
          if (result && result.data && result.data.status === true) {
            setGetRetailers(result.data.data);
          }
      }, 500);
    } catch (error) {
      console.error(`Error while searching retailer: ${error}`);
    }
}, [token]);

  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);

  return (
    <React.Fragment>
      {getRetailers ? (
        <div className="bodytop90">
          <MobileHeader header_title={`Your ${window.location.pathname === '/list-retailer' ? 'Retailer' : 'Electrician'} `} getCartDetails={getCartDetails} />
            <section className="pt-3 pb-0 mobile-hide">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-1">
                    <Link onClick={() => { navigate(-1); setTimeout(() => { window.scrollTo(0, 0); }, 100); }}>
                      <img src='../../../asset/img/Arrow-left.png' className="Heading-icon" height='70px' />
                    </Link>
                  </div>
                  <div className="col-11">
                    <div className="row">
                      <div className="col-4">
                        <h1>{`Your ${window.location.pathname === '/list-retailer' ? 'Retailer' : 'Electrician'} `}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          <section className="mob-pt-0 mob-pb-0">
            <div className="container">
              <div className="pt-0">
                <div className="row align-items-center mob-mb1">
                  <div className="col-lg-10 col-xl-10 col-12">
                    <div className="form-group mb-0 mob-mb1">
                      <span className="icon-flag"><img src="../../../asset/img/searchThick.png" width="18" /></span>
                      <input type="text" className="form-control search-bar" placeholder="Search your need..." onChange={(e) => searchRetailer(e.target.value)} />
                      {deviceType === 'mobile' && (
                        <Link to={`/add-${window.location.pathname === '/list-retailer' ? 'retailer' : 'electrician'} `} className="btn btn-primary mt-3">{`Add ${window.location.pathname === '/list-retailer' ? 'Retailer' : 'Electrician'} `}</Link>
                      )}
                    </div>
                  </div>
                  {deviceType !== 'mobile' && (
                    <div className="col-lg-2 col-xl-2 col-2 text-end">
                      <div className="form-group mb-0 mob-mb1">
                        <Link to={`/add-${window.location.pathname === '/list-retailer' ? 'retailer' : 'electrician'} `} className="btn btn-primary">{`Add ${window.location.pathname === '/list-retailer' ? 'Retailer' : 'Electrician'} `}</Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row align-items-center mob-mb1 mt-3">
                <ul className="MyorderTab mob-mb1">
                  <li><Link to="">{`Scheme ${window.location.pathname === '/list-retailer' ? 'Retailers' : 'Electricians'} `}</Link></li>
                  <li><Link to="" className="active">{`Your ${window.location.pathname === '/list-retailer' ? 'Retailers' : 'Electricians'} `}</Link></li>
                </ul>
              </div>
            </div>
          </section>

          {getRetailers.length > 0 ? (
            <React.Fragment>
              <section className="p-0">
                <div className="container">
                  <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                    {getRetailers.map((get_retailer) => (
                      <div key={get_retailer.id} className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="FlatCard text-start">
                          <div className="card-body bg-white">
                            <div className="row">
                              <div className="col-3">
                                <img src='../../../asset/img/no_image_available.jpg' onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} className="img-fluid w-100" />
                              </div>
                              <div className="col-7 text-start">
                                <h4 className="mb-0">{get_retailer.Name}</h4>
                                <p className="mb-0">{get_retailer.Address}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </React.Fragment>
          ) : (
            <section className="mob-pt-0 mob-pb-0 mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}>
                      <path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z" />
                    </svg>
                    <h3>There Are No {`Your ${window.location.pathname === '/list-retailer' ? 'Retailers' : 'Electricians'} `}.</h3>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}
