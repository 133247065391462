import React, { useCallback, useEffect, useRef, useState } from "react";
import { apiget, apipost } from '../../../service/API';
import { toast } from 'react-toastify';
import { scrollToTop } from "../../../service/ScrollToTop";
import Loader from "../Layout/Loader";
import Banner from "./Banner";
import SubCategoryByCategory from "./SubCategoryByCategory";
import Testimonials from "./Testimonials";
import FAQ from "./FAQ";
import ContactUs from "./ContactUs";

export default function Home() {
  const [bannerLoader, setBannerLoader] = useState(false);
  const [subCategoryByCategoryLoader, setSubCategoryByCategoryLoader] = useState(false);
  const [testimonialsLoader, setTestimonialsLoader] = useState(false);
  const [FAQLoader, setFAQLoader] = useState(false);
  const [getBanners, setGetBanners] = useState('');
  const hasFetchedBanners = useRef(false);
  const [getSubCategoriesByCategoryDetail, setGetSubCategoriesByCategoryDetail] = useState("");
  const [GetCategoryAndItemCount, setGetCategoryAndItemCount] = useState("");
  const hasFetchedSubCategoryByCategory = useRef(false);
  const hasFetchedGetTestimonial = useRef(false);
  const [testimonialDetails, setTestimonialDetails] = useState("");
  const hasFetchedGetFAQ = useRef(false);
  const [faqDetails, setFaqDetails] = useState("");
  const hasFetchedscrollToTop = useRef(false);

  // Get Banner API
  const getBanner = useCallback(async () => {
    setBannerLoader(true);
    try {
      const getBannerResult = await apiget('general-setting/get-banner');
      if (getBannerResult && getBannerResult.data.status === true) {
        setGetBanners(getBannerResult.data.data);
      } else if (getBannerResult && getBannerResult.data.status === false) {
        toast.error(getBannerResult.data.message);
      }
    } catch (error) {
      console.error(`Error : ${error}`);
    } finally {
      setBannerLoader(false);
    }
  }, [setBannerLoader]);

  useEffect(() => {
    if (!hasFetchedBanners.current) {
      getBanner();
      hasFetchedBanners.current = true;
    }
  }, [getBanner]);

  // Get Category
  const getCategory = useCallback(async () => {
    setSubCategoryByCategoryLoader(true);
    try {
      const getCategoryResult = await apiget("get-categories");
      // Show Error Message
      if (getCategoryResult?.data?.status === false) {
        toast.error(getCategoryResult.data.message);
        setSubCategoryByCategoryLoader(false);
      }
      // Set Category Data
      if (getCategoryResult?.data?.status === true) {
        setGetCategoryAndItemCount(getCategoryResult.data);
        subCategoryByCategory(getCategoryResult.data);
      }
    } catch (error) {
      console.error(`Error : ${error}`);
    }
  }, []);

  useEffect(() => {
    if (!hasFetchedSubCategoryByCategory.current) {
      getCategory();
      hasFetchedSubCategoryByCategory.current = true;
    }
  }, [getCategory]);

  // Get Sub Category By Category
  const subCategoryByCategory = useCallback(async (categories) => {
    setSubCategoryByCategoryLoader(true);
    try {
      const updatedCategories = await Promise.all(
        categories?.data.map(async (category) => {
          const data = {
            category_id: JSON.stringify(category.id),
          };
          const getSubCategoriesResult = await apipost("get-sub-category", data);
          // Show Error Message
          if ((getSubCategoriesResult?.data?.status === false) || (categories?.data?.status === false)) {
            toast.error(getSubCategoriesResult.data.message);
          }
          // Push Sub Category Data By Category
          if (getSubCategoriesResult?.data?.data && getSubCategoriesResult?.data?.status === true) {
            return {
              ...category,
              get_sub_category: getSubCategoriesResult.data.data.filter((sub_category) => sub_category.category_id === category.id),
            };
          }
          return category;
        })
      );
      setGetSubCategoriesByCategoryDetail(updatedCategories);
    } catch (error) {
      console.error(`Error : ${error}`);
    } finally {
      setSubCategoryByCategoryLoader(false);
    }
  }, [setSubCategoryByCategoryLoader]);

  // Get Testimonial API
  const getTestimonial = useCallback(async () => {
    setTestimonialsLoader(true);
    try {
      const getTestimonialResult = await apiget("general-setting/get-testimonial");
      if (getTestimonialResult && getTestimonialResult.data.status === true) {
        setTestimonialDetails(getTestimonialResult.data.data);
      }
      if (getTestimonialResult && getTestimonialResult.data.status === false) {
        toast.error(getTestimonialResult.data.message);
      }
    } catch (error) {
      console.error(`Error : ${error}`);
    } finally {
      setTestimonialsLoader(false);
    }
  }, []);

  useEffect(() => {
    if (!hasFetchedGetTestimonial.current) {
      getTestimonial();
      hasFetchedGetTestimonial.current = true;
    }
  }, [getTestimonial]);


  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);

  // Get FAQ API
  const getFAQ = useCallback(async () => {
    setFAQLoader(true);
    try {
      const getFaqResult = await apiget("general-setting/get-faq");
      if (getFaqResult && getFaqResult.data.status === true) {
        setFaqDetails(getFaqResult.data.data);
      }
      if (getFaqResult && getFaqResult.data.status === false) {
        toast.error(getFaqResult.data.message);
      }
    } catch (error) {
      console.error(`Error : ${error}`);
    } finally {
      setFAQLoader(false);
    }
  }, []);

  useEffect(() => {
    if (!hasFetchedGetFAQ.current) {
      getFAQ();
      hasFetchedGetFAQ.current = true;
    }
  }, []);

  return (
    <React.Fragment>
      {bannerLoader === false && subCategoryByCategoryLoader === false && testimonialsLoader === false && FAQLoader === false ? (
        <React.Fragment>
          <Banner getBanner={getBanners}/>
          <SubCategoryByCategory getSubCategoriesByCategoryDetail={getSubCategoriesByCategoryDetail} GetCategoryAndItemCount={GetCategoryAndItemCount} />
          <Testimonials testimonialDetails={testimonialDetails} />
          <FAQ faqDetails={faqDetails} />
          <ContactUs />
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}
