import React, { useState, useEffect, useRef } from 'react';
import { apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import { toast } from 'react-toastify';
import Loader from '../Layout/Loader';
import phone from '../../../asset/img/phone.png';
import envelope from '../../../asset/img/envelope.png';
import { Link } from 'react-router-dom';
import MobileHeader from '../Layout/MobileHeader';

export default function ContactUs({ getCartDetails }) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [message, setmessage] = useState('');
    const [reason_for_contact, setReasonForContact] = useState('');
    const [errors, setErrors] = useState({});
    const hasFetchedscrollToTop = useRef(false);
    const [addContactUsLoader, setaddContactUsLoader] = useState('');
    const [get_loder, setLoder] = useState(true);
    const hasFetchedSpinner = useRef(false);

    const { companyPhoneNo, companyEmail, updateUserTokenValidate, deviceType } = useAuth();
    
    useEffect(() => {
        if (!hasFetchedSpinner.current) {
            hasFetchedSpinner.current = true;
            setLoder(true);
            setTimeout(() => {
                setLoder(false);
                hasFetchedSpinner.current = true;
            }, 500);
        }
    }, []);

    const addContactUs = async (e) => {
        setaddContactUsLoader(true);
        e.preventDefault();
        let data = {
            name: name,
            email: email,
            phone_number: phone_number,
            message: message,
            reason_for_contact: reason_for_contact,
        };
        const result = await apipost('contactus/add-contact-us', data);
        if (result && result.status === false && result.error_type === 'check_token') {
            updateUserTokenValidate(false);
            setaddContactUsLoader(false);
        }
        if (result && result.errors && result.errors.length > 0 && result.status === false) {
            const errorObject = {};
            result.errors.forEach(err => {
                errorObject[err.field] = err.message;
            });
            setErrors(errorObject);
            setaddContactUsLoader(false);
        } else {
            if (result && result.data && result.data.status === true) {
                toast.success(result.data.message);
                setName('');
                setEmail('');
                setPhoneNumber('');
                setReasonForContact('');
                setmessage('');
                setErrors('');
                setaddContactUsLoader(false);
            }
        }
    };

    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    };
    
    useEffect(() => {
      if (!hasFetchedscrollToTop.current) {
        scrollToTop();
        hasFetchedscrollToTop.current = true;
      }
    }, [scrollToTop]);

    return (
        <React.Fragment>
        <style>
            {`
                .accordion-item {
                    background-color: #fff;
                    border: none;
                    box-shadow: 0 4px 16px 0 rgba(39, 49, 68, .04);
                    margin-bottom: 24px;
                    border-radius: 4px !important;
                    border: 1px solid #cfcfcf !important;
                }

                .accordion-button:not(.collapsed) {
                    color: #000;
                    background-color: #FFF;
                    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
                    border-bottom-left-radius: 0px !important;
                    border-bottom-right-radius: 0px !important;
                }
            `}
        </style>
        <div className="bodytop90">
            <MobileHeader header_title={'Contact Us'} getCartDetails={getCartDetails}/>
            {get_loder === false ? (
                <React.Fragment>
                    <section className="pt-3 pb-0">
                        <div className="container">
                            <h1 className="mb-3">Help</h1>
                            <p><img src={phone} width="16" className="me-2"/> <Link to={'tel:'+companyPhoneNo}>{companyPhoneNo}</Link></p>
                            <p><img src={envelope} width="16" className="me-2"/> <Link to={'mailto:'+companyEmail}>{companyEmail}</Link></p>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <h1 className="Heading-Medium mb-4">Have a Query in mind? Get in touch!</h1>
                            <p className="p-18">We're here to help and answer any question you might have. We look forward to hearing from you. Any need help you please contact us or meet to office with coffee.</p>
                            <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                                <form className="row" onSubmit={addContactUs}>
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 col-12">          
                                            <div className="form-group">
                                                <p className="p-18">Enter Full Name <span className="text-danger">*</span></p>
                                                <input type="text" className="form-control form-control-lg" value={name} onChange={(e) => setName(e.target.value)}  placeholder="Enter Full Name"/>
                                                {errors.name && <span className="text-danger"> {errors.name} </span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <div className="form-group">
                                                <p className="p-18">Enter Mobile Number <span className="text-danger">*</span></p>
                                                <input type="text" inputMode="numeric" pattern="[0-9]*"      className="form-control form-control-lg" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)}  placeholder="Enter Mobile Number"/>
                                                {errors.phone_number && <span className="text-danger"> {errors.phone_number} </span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <div className="form-group">
                                                <p className="p-18">Enter Email Address <span className="text-danger">*</span></p>
                                                <input type="text" className="form-control form-control-lg"  value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email Id"/>
                                                {errors.email && <span className="text-danger"> {errors.email} </span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 col-12">
                                            <div className="form-group">
                                                <p className="p-18">Enter Your Query <span className="text-danger">*</span></p>
                                                <textarea type="text" className="form-control form-control-lg" vrows="2" cols="100" value={message} onChange={(e) => setmessage(e.target.value)} placeholder="Enter Your Query"></textarea>
                                                {errors.message && <span className="text-danger"> {errors.message} </span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                {addContactUsLoader === true ? (
                                                    <Loader spinner={'request-product'} className={'btn btn-primary pe-1 ps-1 me-2'} />    
                                                ) : (
                                                    <button type="submit" className="btn btn-primary me-2">Submit</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </React.Fragment>   
             ) : (
                <Loader />
             )}
        </div>
        </React.Fragment>
    )
}
