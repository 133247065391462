import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../service/AuthContext';
import { toast } from 'react-toastify';
import { apipost } from '../../../service/API';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function MyItemWiseOrderDetail({ getCart, getCartDetails, getWishList }) {
  const { token, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate, deviceType } = useAuth();
  const { order_detail_id } = useParams();
  const hasFetchedscrollToTop = useRef(false);
  const hasOrdersItemDetailWise = useRef(false);

  const [getOrdersItemDetailWise, setGetOrdersItemDetailWise] = useState('');
  const [getTotalAmount, setGetTotalAmount] = useState('');

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  };

  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);

  // useEffect(() => {
  //   if (token) {
  //     getCart();
  //     getWishList();
  //   }
  // }, [token, getCart, getWishList]);

  const getOrderItemDetailWise = useCallback(async () => {
    const data = {
      item_system_id: order_detail_id
    }
    const result = await apipost('order/get-item-wise-order-detail', data);
    if (result && result.status === false && result.error_type === 'check_token') {
      updateUserTokenValidate(false);
    }
    if (result && result.data && result.data.status === false) {
      toast.error(result.data.message);
    }

    if (result && result.data && result.data.status === true) {
      setGetOrdersItemDetailWise(result.data.item_detail);
      setGetTotalAmount(result.data.total_amount);
    }
  }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

  useEffect(() => {
    if(!hasOrdersItemDetailWise.current){
      if (token) {
        getOrderItemDetailWise();
      }
      hasOrdersItemDetailWise.current = true;
    }
  }, [token, getOrderItemDetailWise]);

  return (
    <React.Fragment>
      
      <MobileHeader header_title={'My Item Wise Order Detail'} getCartDetails={getCartDetails}/>
      {getOrdersItemDetailWise ? (
        <div className="bodytop90">
          <section className="mob-p-0 pb-0 mobile-hide">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <h1 className="Heading-Medium">{getOrdersItemDetailWise.sales_order_no}</h1>
                </div>
              </div>
            </div>
          </section>

          {deviceType === "mobile" && (
            <section className="pt-0">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-xl-6 col-12">
                    <div className="form-group mb-0 mob-mb1">
                      <span className="icon-flag"><img src="../../../asset/img/searchThick.png" width="18" /></span>
                      <input type="text" className="form-control search-bar" placeholder="Search your need..." />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 col-12">
                    <div className="row align-items-center">
                      <div className="col-8">
                      </div>
                      {/* <div className="col-4">
                        <div className="form-group mb-0">
                          <select className="form-select fullrounded">
                            <option>Sort By</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-4">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="pt-0 mobile-hide">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between">
                <Link onClick={() => { navigate(-1); setTimeout(() => { window.scrollTo(0, 0); }, 100); }}>
                    <img src='../../../asset/img/Arrow-left.png' className="Heading-icon" height='70px'/>
                </Link>
                <div className="col-6">
                  <div className="form-group mb-0 mob-mb1">
                    <span className="icon-flag"><img src="../../../asset/img/searchThick.png" width="18" /></span>
                    <input type="text" className="form-control search-bar" placeholder="Search your need..." />
                  </div>
                </div>
                <div className="col-3">
                </div>
                {/* <div className="col-2">
                  <select className="form-select fullrounded">
                    <option>Sort By</option>
                  </select>
                </div> */}
              </div>
            </div>
          </section>

          <section className="p-0">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-6">
                  <p> {getOrdersItemDetailWise.length} items in item</p>
                </div>
                <div className="col-6 text-end">
                  <p>Amount : <strong>₹ {getTotalAmount}</strong></p>
                </div>
              </div>
            </div>
            <div className="container mb-5">
              <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                {getOrdersItemDetailWise ? getOrdersItemDetailWise.map((get_orders_item_detail_wise, index ) => {
                  return (
                    <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4"  key={index}>
                      <div className="FlatCard text-start">
                        <div className="row align-items-center">
                          <div className="col-lg-3 col-xl-3 col-3 text-center">
                            <img src={get_orders_item_detail_wise.get_item && get_orders_item_detail_wise.get_item.image ? get_orders_item_detail_wise.get_item && get_orders_item_detail_wise.get_item.image : '../../../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} className="img-fluid w-100" />
                          </div>
                          <div className="col-9">
                            <p className="mb-0">{get_orders_item_detail_wise.get_item && get_orders_item_detail_wise.get_item.Description}</p>
                            <p className="p-12 mb-0"><strong>₹ {get_orders_item_detail_wise.get_item && get_orders_item_detail_wise.total_item_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></p>
                            <div className="row mt-2">
                              <div className="col-6">
                                <p className="mb-0 text-cool-gray">Order Qty</p>
                                <p className="mb-0">{get_orders_item_detail_wise.quantity} {get_orders_item_detail_wise.get_item && get_orders_item_detail_wise.get_item.Base_Unit_of_Measure}</p>
                              </div>
                              <div className="col-6">
                                <p className="mb-0 text-cool-gray">Pending Qty</p>
                                <p className="mb-0">{get_orders_item_detail_wise.pending_quantity} {get_orders_item_detail_wise.get_item && get_orders_item_detail_wise.get_item.Base_Unit_of_Measure} </p>
                              </div>
                              <div className="col-12 mt-2">
                                <p className="mb-0 text-cool-gray">Order No</p>
                                <p className="mb-0">{get_orders_item_detail_wise.sales_order_no}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }) : null}
              </div>
            </div>
          </section>
          <section className="mob-pt-0 mob-pb-0"></section>
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}
