import React, { useEffect, useState, useCallback, useRef } from 'react';
import { apiget, apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function RequestNewProduct({ getCartDetails }) {
    const {updateUserTokenValidate, deviceType } = useAuth();

    const [itemName, setItemName] = useState('');
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState({});
    const [requestNewProductLoader, setrequestNewProductLoader] = useState('');
    const hasFetchedscrollToTop = useRef(false);
    const [get_loder, setLoder] = useState(true);
    const hasFetchedSpinner = useRef(false);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    };
    
    useEffect(() => {
      if (!hasFetchedscrollToTop.current) {
        scrollToTop();
        hasFetchedscrollToTop.current = true;
      }
    }, [scrollToTop]);
    
    useEffect(() => {
        if (!hasFetchedSpinner.current) {
            hasFetchedSpinner.current = true;
            setLoder(true);
            setTimeout(() => {
                setLoder(false);
                hasFetchedSpinner.current = true;
            }, 500);
        }
    }, []);

    const requestNewProduct = async (e) => {
        setrequestNewProductLoader(true);
        e.preventDefault();
        let data = {
            item_name: itemName,
            description: description,
        };
        const result = await apipost('request-item/add-request-item', data);
        if (result && result.status === false && result.error_type === 'check_token') {
            updateUserTokenValidate(false);
            setrequestNewProductLoader(false);
        }
        if (result && result.errors && result.errors.length > 0 && result.status === false) {
            const errorObject = {};
            result.errors.forEach(err => {
                errorObject[err.field] = err.message;
            });
            setErrors(errorObject);
            setrequestNewProductLoader(false);
        } else {
            if (result && result.data && result.data.status === true) {
                toast.success(result.data.message);
                setItemName('');
                setDescription('');
                setErrors('');
                setrequestNewProductLoader(false);
            }
        }
    };

    return (
        <React.Fragment>
            {get_loder === false ? (
                <div className="bodytop90">
                    <MobileHeader header_title={'Request New Product'} getCartDetails={getCartDetails}/>
                    <section>
                        <div className="container">
                            <h1 className="Heading-Medium mb-4">Request a item</h1>
                            <p className="p-18">Tell us if you can’t find a item and we will add it to the shop as soon as possible. <span className="text-danger">*</span></p>
                            <div className="row">
                                <div className="col-lg-5 col-xl-5 col-12">
                                    <form onSubmit={requestNewProduct}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input type="text" value={itemName} onChange={(e) => setItemName(e.target.value)} className="form-control form-control-lg" placeholder="Enter item name" />
                                                    {errors.item_name && <span className="text-danger"> {errors.item_name} </span>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <p className="p-18">Tell us more about the item <span className="text-muted">(optional)</span></p>
                                                    <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} className="form-control form-control-lg" placeholder="Add brand name, pack size, expected price..." />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group d-flex align-items-center">
                                                    {requestNewProductLoader === true ? (
                                                        <Loader spinner={'request-product'} className={'btn btn-primary pe-1 ps-1 me-2'} />
                                                    ) : (
                                                        <button type="submit" className="btn btn-primary me-2">Submit</button>
                                                    )}
                                                    <button type="reset" className="btn btn-outline-primary">Dismiss</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <Loader />
            )}
        </React.Fragment>
    )
}
