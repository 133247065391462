import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useAuth } from '../../../service/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { apipost, apiget } from '../../../service/API';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function SalesInvoice({ getCart, getCartDetails, getWishList }) {
    const { token, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate, deviceType } = useAuth();

    const [salesInvoices, setSalesInvoices] = useState('');
    const [dueSales, setDueSales] = useState('');
    const [overDue, setOverDue] = useState('');
    const [sales_invoices_type, setSalesInvoicesType] = useState('All');
    const hasFetchedscrollToTop = useRef(false);
    const timeoutRef = useRef(null);
    const hasFetchedpostedSalesInvoices = useRef(false);
    const [spinner, setSpinner] = useState(true);
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (token) {
    //         getWishList();
    //         getCart();
    //     }
    // }, [token, getWishList, getCart]);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    };
    
    useEffect(() => {
      if (!hasFetchedscrollToTop.current) {
        scrollToTop();
        hasFetchedscrollToTop.current = true;
      }
    }, [scrollToTop]);

    const getCustomerDetail = useCallback(async () => {
        const result = await apiget('user/last-login');
        if (result && result.data && result.data.status === true) {
            setDueSales(result.data.data.Balance_LCY);
            setOverDue(result.data.data.Balance_Due);
        }
    }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken]);

    const getPostedSalesInvoices = useCallback(async (salesInvoicesType) => {
        if(salesInvoicesType === undefined){
            setSpinner(true);
        }
        let data = {
            sales_invoices_type: salesInvoicesType === undefined ? 'All' : salesInvoicesType
        }
        const result = await apipost('customer/get-posted-sales-invoices', data);
        if (result && result.data && result.data.data && result.data.status === true) {
            setSpinner(false);
            setSalesInvoices(result.data.data);
            scrollToTop();
            setSalesInvoicesType(salesInvoicesType === undefined ? 'All' : salesInvoicesType);

            if (result.data.data.length < 0) {
                setSpinner(false);
            }
        }
    }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken]);

    useEffect(() => {
        if(!hasFetchedpostedSalesInvoices.current)
        {
            if (token) {
                getPostedSalesInvoices();
                getCustomerDetail();
            }
            hasFetchedpostedSalesInvoices.current = true;
        }
    }, [token]);

    const searchSalesInvoice = useCallback(async (searchInvoiceNo) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(async () => {
            let data = {
                sales_invoice_no: searchInvoiceNo === '' ? '' : searchInvoiceNo
            };
            const result = await apipost('customer/search-sales-invoice', data);
            if (result && result.status === false && result.error_type === 'check_token') {
                updateUserTokenValidate(false);
            }
            if (result && result.data && result.data.status === true) {
                setSalesInvoices(result.data.data);
            }
        }, 500);
    }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

    return (
        <React.Fragment>
            {spinner === false ? (
                <div className="bodytop90">
                    <MobileHeader header_title={'Sales Invoice'} getCartDetails={getCartDetails}/>
                    <section className="mob-pt-0 mob-pb-0">
                        <div className="container">
                            <h1 className="Heading-Medium mb-5">Sales Invoice</h1>
                            <div className="row">
                                <div className="col-lg-3 col-xl-3 col-6">
                                    <div className="card rounded12 bg-light-green text-white">
                                        <div className="card-body">
                                            <h5>Due <i className="bi bi-arrow-right"></i></h5>
                                            <h2><strong>₹ {dueSales ? dueSales : 0}</strong></h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-6">
                                    <div className="card rounded12 bg-light-red text-white">
                                        <div className="card-body">
                                            <h5>Overdue <i className="bi bi-arrow-up"></i></h5>
                                            <h2><strong>₹ {overDue ? overDue : 0}</strong></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5 align-items-center mob-mb1">
                                <div className="col-lg-6 col-xl-6 col-12">
                                    <div className="form-group mb-0">
                                        <span className="icon-flag"><img src="../../../asset/img/searchThick.png" width="18" /></span>
                                        <input type="text" id="search-sales-invoice" className="form-control search-bar" placeholder="Search Sales Invoice" onChange={(e) => searchSalesInvoice(e.target.value)}/>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 col-xl-6 col-12 justify-content-end" style={{ marginTop: deviceType === 'mobile' ? '20px' : '' }}>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="groupBySelect" className="col-form-label mb-0 me-2" style={{ marginBottom: '0' }}>Group By :</label>
                                        <select id="groupBySelect" className="form-select mb-0" style={{ maxWidth: '150px', marginLeft: '8px' }}>
                                            <option>Bill</option>
                                            <option>Month</option>
                                        </select>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>
                
                    <section className="p-0">
                        <div className="container">
                            <ul className="nav nav-tabs sales-nav-tab" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation" onClick={() => getPostedSalesInvoices('All')}>
                                    <button className={`${sales_invoices_type === 'All' ? "nav-link active" : "nav-link"}`} id="salesAll-tab" >All</button>
                                </li>
                                <li className="nav-item" role="presentation" onClick={() => getPostedSalesInvoices('Paid')}>
                                    <button className={`${sales_invoices_type === 'Paid' ? "nav-link active" : "nav-link"}`} id="salesPaid-tab" >Paid</button>
                                </li>
                                <li className="nav-item" role="presentation" onClick={() => getPostedSalesInvoices('Pending')}>
                                    <button className={`${sales_invoices_type === 'Pending' ? "nav-link active" : "nav-link"}`} id="salesPending-tab" >Pending</button>
                                </li>
                                <li className="nav-item" role="presentation" onClick={() => getPostedSalesInvoices('Overdue')}>
                                    <button className={`${sales_invoices_type === 'Overdue' ? "nav-link active" : "nav-link"}`} id="salesOverdue-tab">Overdue</button>
                                </li>
                            </ul>

                            <div className="tab-content" id="myTabContent">
                                {salesInvoices && salesInvoices.length > 0 ? (
                                    <div className="tab-pane fade show active pt-3 pb-3" id="salesAll" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                                            {salesInvoices && salesInvoices.map((get_customer_invoice, index) => {
                                                var total_paid_amount = get_customer_invoice.TotalInclTaxAmount - get_customer_invoice.Remaining_Amount;
                                                return (
                                                <Link to={`/sales-invoice-detail/${get_customer_invoice.SystemId}`} className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mb-4 text-decoration-none" key={index}>
                                                    <div className="FlatCard text-start">
                                                        <div className="card-header pe-0 ps-0 mb-3 bg-white border-bottom-dashed">
                                                            <div className="row align-items-center">
                                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-6">
                                                                    <p className="mb-0">{get_customer_invoice.No} | {new Date(get_customer_invoice.Posting_Date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 text-end">
                                                                    <h4 className="mb-0"><strong>₹ {get_customer_invoice.TotalInclTaxAmount}</strong></h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-2">
                                                            <div className="col-8">
                                                                <p className="mb-0">Due Date - {new Date(get_customer_invoice.Due_Date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                                            </div>
                                                            <div className="col-4  text-end">
                                                                <p className="mb-0 text-success">Paid Rs.{total_paid_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center mb-2">
                                                            <div className="col-4">
                                                                <p className="mb-0 p-12">
                                                                    {get_customer_invoice.Payment_Terms_Code}
                                                                    {get_customer_invoice && get_customer_invoice.date_difference && (
                                                                        <span> + {get_customer_invoice.date_difference} = {parseInt(get_customer_invoice.Payment_Terms_Code) + get_customer_invoice.date_difference}</span>
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="col-3">

                                                            </div>
                                                            {get_customer_invoice.Remaining_Amount > 0 ? (
                                                                <div className="col-5 text-end">
                                                                    <p className="mb-0 text-danger p-600">Unpaid Rs.{get_customer_invoice.Remaining_Amount}</p>
                                                                </div>
                                                            ) : (
                                                                <div className="col-5 text-end">
                                                                    <p className="mb-0 text-success p-600">Paid</p>
                                                                </div>
                                                            )}

                                                        </div>
                                                        <div className="row align-items-center mb-3">
                                                            <div className="col-8">
                                                            </div>
                                                            <div className="col-4 text-end">
                                                                <span className="me-2"><img src="../../../asset/img/whatsapp.png" width="32" /></span>
                                                                <span><img src="../../../asset/img/envelope-red.png" width="32" /></span>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer p-0 pt-2 bg-white border-top-dashed">
                                                            <div className="row align-items-center">
                                                                <div className="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-3"><h6 className="mb-0">PROMOT</h6></div>
                                                                {get_customer_invoice && get_customer_invoice.date_difference ? (
                                                                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-4">
                                                                        <p className="mb-0 p-12 text-cool-gray">Overdue by {get_customer_invoice.date_difference} days</p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-4">
                                                                        <p className="mb-0 p-12 text-cool-gray">

                                                                        </p>
                                                                    </div>
                                                                )}
                                                                <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-5">
                                                                    <div className="form-check float-end">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled={get_customer_invoice.Remaining_Amount === '0'} />
                                                                        <label className="form-check-label p-12" htmlFor="flexCheckDefault">
                                                                            Select to Pay
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <section className="mob-pt-0 mob-pb-0 mt-5">
                                      <div className="container">
                                        <div className="row">
                                          <div className="col-12 text-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}><path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z"/></svg>
                                            <h3>No Record Sales Invoice.</h3>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <Loader />
            )}
        </React.Fragment>
    )
}
