import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function SalesPaymentDetail({ getCart, getCartDetails, getWishList }) {
    const { token, isLoggedIn, isBackEndLogin, updateToken, deviceType } = useAuth();
    const {SystemId_Ledger} = useParams();
    const [customerLedgerEntry, setCustomerLedgerEntry] = useState('');
    const [customerLedgerEntrys, setCustomerLedgerEntrys] = useState('');
    const hasFetchedscrollToTop = useRef(false);
    const hasFetchedpostedLedgerEntryDetail = useRef(false);

    const navigate = useNavigate();

    // useEffect(() => {
    //     if (token) {
    //         getCart();
    //         getWishList();
    //     }
    // }, [token]);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    };
    
    useEffect(() => {
      if (!hasFetchedscrollToTop.current) {
        scrollToTop();
        hasFetchedscrollToTop.current = true;
      }
    }, [scrollToTop]);

    const getPostedLedgerEntryDetail = useCallback( async () => {
        let data = {
            SystemId_Ledger: SystemId_Ledger,
        }
        const result = await apipost('customer/get-posted-ledger-entry-detail', data);
        if (result && result.data && result.data.status === true) {
            setCustomerLedgerEntry(result.data.get_customer_ledger_entry);
            setCustomerLedgerEntrys(result.data.get_customer_ledger_entrys);
        }
    }, [token, navigate, isBackEndLogin, isLoggedIn, updateToken, SystemId_Ledger]);

    useEffect(() => {
        if (!hasFetchedpostedLedgerEntryDetail.current) {
            if(token) {
                getPostedLedgerEntryDetail();
            }
            hasFetchedpostedLedgerEntryDetail.current = true;
        }
    }, [token, getPostedLedgerEntryDetail]);

    return (
        <React.Fragment>
            {customerLedgerEntry ? (
            <div className="bodytop90">
                <MobileHeader header_title={'Payment Details'}  getCartDetails={getCartDetails}/>
                <section className="pt-3 pb-0 mobile-hide">
                  <div className="container">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-1">
                            <Link onClick={() => { navigate(-1); setTimeout(() => { window.scrollTo(0, 0); }, 100); }}>
                                <img src='../../../asset/img/Arrow-left.png' className="Heading-icon" height='70px'/>
                            </Link>
                      </div>
                      <div className="col-11">
                        <h1 className="Heading-Medium mb-3">Payment Details</h1>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="mob-pt-0 mob-pb-0">
                    <div className="container">
                        <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mb-4">
                                <div className="FlatCard text-start">
                                    <div className="card-header pe-0 ps-0 bg-white border-0">
                                        <div className="row align-items-center">
                                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-6">
                                                <p className="mb-0 p-600">{customerLedgerEntry.Customer_Name}</p>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-6 text-end">
                                                <h4 className="mb-0"><strong>₹ {customerLedgerEntry.Amount && customerLedgerEntry.Amount.toString().replace('-', '')}</strong></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col-8">
                                            <p className="mb-0">{new Date(customerLedgerEntry.PostingDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                            <p className="mb-0">Transaction ID : {customerLedgerEntry && customerLedgerEntry.DocumentNo.split('/').pop()}</p>
                                        </div>
                                        <div className="col-4  text-end">
                                            <label className="badge-regular badge-success">Fully Settled</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h2 className="Heading-Small mb-3">Settled against</h2>
                            <div className="row">
                                {customerLedgerEntrys && customerLedgerEntrys.map((get_ledger_entry_detail, index) => {
                                    return (
                                        <div key={get_ledger_entry_detail.Entry_No} className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mb-4">
                                            <div className="FlatCard text-start">
                                                <div className="card-header pe-0 ps-0 bg-white border-0">
                                                    <div className="row align-items-center">
                                                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-6">
                                                            <p className="mb-0 p-600">{get_ledger_entry_detail.Customer_Ledger_Document_No}</p>
                                                        </div>
                                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-6 text-end">
                                                            <h6 className="mb-0"><strong>₹ {get_ledger_entry_detail.Amount.toString().replace('-', '')}</strong></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mb-2">
                                                    <div className="col-7">
                                                        <p className="mb-0">Prinicipal Amount: ₹ {get_ledger_entry_detail.prinicipal_amount.toString().replace('-', '')}</p>
                                                        <p className="mb-0">Adjustment Amount: ₹ {get_ledger_entry_detail.Amount.toString().replace('-', '')}</p>
                                                        <p className="mb-0 text-muted">Due Date: {new Date(get_ledger_entry_detail.PostingDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                                    </div>
                                                    <div className="col-5 text-end">
                                                        <label className="badge-regular badge-success p-12">Fully Settled</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </section>
            </div>
            ) : (
                <Loader />
            )}
        </React.Fragment>
    )
}
