import React, { useEffect, useState } from 'react';
import { apiget, apipost } from '../../../service/API';
import { toast } from 'react-toastify';
import { useAuth } from '../../../service/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { checkUserToken } from '../../../service/CommanAPI';
import Loader from '../Layout/Loader';

export default function MyOrder({ getCart, getWishList }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accountStatementLoader, setAccountStatementLoader] = useState('');
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const { token, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate } = useAuth();

  useEffect(() => {
    if (token) {
      checkUserToken(navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate);
    }
  }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

  useEffect(() => {
    if (token) {
      getCart();
      getWishList();
    }
  }, [token, getCart, getWishList]);

  const downloadAccountStatement = async (e) => {
    setAccountStatementLoader(true);
    e.preventDefault();
    let data = {
      start_date: startDate,
      end_date: endDate
    }
    const result = await apipost('customer/get-account-statement', data);
    if (result && result.status === false && result.errors && result.errors.length > 0) {
      const errorObject = {};
      result.errors.forEach(err => {
        errorObject[err.field] = err.message;
      });
      setAccountStatementLoader(false);
      setErrors(errorObject);
    }
    if (result && result.status === false && !result.errors) {
      const error = { account_statement: result.message };

      toast.error(errors.account_statement);
      setAccountStatementLoader(false);
      setErrors(error);
    }
    if (result && result.data && result.data.status === true) {
      setErrors({});
      const linkSource = `data:application/pdf;base64,${result.data.data}`;
      const downloadLink = document.createElement("a");
      const fileName = "account_statement.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setStartDate('');
      setEndDate('');
      setAccountStatementLoader(false);

      toast.success(result.data.message);
    }
  };

  return (
    <>
      <div className="bodytop90 body-gray">
        <section>
          <div className="container">
            <h1 className="Heading-Medium mb-4">Account Statement</h1>
            <div className="row">
              <form onSubmit={downloadAccountStatement}>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">Start Date: <span className="text-danger">*</span></label>
                        <input type="date" className="form-control form-control-lg" value={startDate} onChange={(e) => setStartDate(e.target.value)} max={new Date().toISOString().split('T')[0]} id="startDate" placeholder="Start Date" />
                        {errors && errors.start_date && <span className="text-danger"> {errors && errors.start_date} </span>}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="text-cool-gray mb-2">End Date: <span className="text-danger">*</span></label>
                        <input type="date" className="form-control form-control-lg" value={endDate} onChange={(e) => setEndDate(e.target.value)} max={new Date().toISOString().split('T')[0]} id="endDate" placeholder="End Date" />
                        {errors && errors.end_date && <span className="text-danger"> {errors && errors.end_date} </span>}
                      </div>
                    </div>
                    {errors && errors.account_statement && <span className="text-danger"> {errors && errors.account_statement} </span>}
                    <div className="col-12">
                      <div className="form-group">
                        {accountStatementLoader === true ? (
                          <Loader spinner={'button'} className={'col-lg-2 btn add-btn w-100 div button'} />
                        ) : (
                          <>
                            <button type="submit" className="btn btn-primary me-2">Download</button>
                            <Link to="/" className="btn btn-outline-primary">Dismiss</Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
