import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useAuth } from '../../../service/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { apiget, apipost } from '../../../service/API';
import { toast } from 'react-toastify';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function Address() {
   const { token, isLoggedIn, updateToken, userTokenValidate, updateUserTokenValidate, deviceType } = useAuth();

   const navigate = useNavigate();
   const hasFetchedGetItem = useRef(false);
   const [deleteAddressLoader, setDeleteAddressLoader] = useState(false);
   const hasFetchedscrollToTop = useRef(false);

   const [getAddress, setGetAddress] = useState('');

   const [full_name, setFullName] = useState('');
   const [phone_number, setPhoneNumber] = useState('');
   const [pincode, setPincode] = useState('');
   const [state_id, setStateId] = useState('');
   const [city, setCity] = useState('');
   const [house_no_building_name, setHouseNoBuildingName] = useState('');
   const [address, setAddress] = useState('');
   const [type_of_address, setTypeOfAddress] = useState('');
   const [get_states, setGetStates] = useState('');
   const [errors, setErrors] = useState({});
   const [addAddressError, setAddAddressError] = useState('');

   const [addAddressLoader, setAddAddressLoader] = useState(false);

   const getAddressDetails = useCallback(async () => {
      const result = await apiget('address/get-address');
      if (result && result.status === false && result.error_type === 'check_token') {
         updateUserTokenValidate(false);
      }
      if (result && result.data.status === true) {
         setGetAddress(result.data.data);
         getStates();
      }
   }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

   useEffect(() => {
      if (token) {
         if (!hasFetchedGetItem.current) {
            getAddressDetails();
            hasFetchedGetItem.current = true;
         }
      }
   }, [token, getAddressDetails]);

   const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
   };

   useEffect(() => {
      if (!hasFetchedscrollToTop.current) {
         scrollToTop();
         hasFetchedscrollToTop.current = true;
      }
   }, [scrollToTop]);

   const deleteAddress = async (address_id) => {
      setDeleteAddressLoader(true);
      let data = {
         address_id: address_id
      }
      const result = await apipost('address/delete-address', data);
      if (result && result.status === false && result.error_type === 'check_token') {
         updateUserTokenValidate(false);
         setDeleteAddressLoader(false);
      }
      if (result && result.data.status === true) {
         toast.success(result.data.message);
         getAddressDetails();
         window.$(`#address_delete_${address_id}`).modal("hide");
         setDeleteAddressLoader(false);
      }
   };

   const addAddress = async (e) => {
      setAddAddressLoader(true);
      e.preventDefault();
      let data = {
         full_name: full_name,
         phone_number: phone_number,
         pincode: pincode,
         state_id: state_id,
         city: city,
         house_no_building_name: house_no_building_name,
         address: address,
         type_of_address: type_of_address,
      };
      const result = await apipost('address/add-address', data);
      if (result.status === 201 && result.data && result.data.message) {
         setAddAddressError(result.data.message);
         setAddAddressLoader(false);
      }
      if (result && result.status === false && result.error_type === 'check_token') {
         updateUserTokenValidate(false);
         setAddAddressLoader(false);
      }
      if (result && result.type === "credential_error" && result.status === false) {
         //Handle errors
         setErrors({});
         setAddAddressLoader(false);
      }
      if (result && result.errors && result.errors.length > 0 && result.status === false) {
         const errorObject = {};
         result.errors.forEach(err => {
            errorObject[err.field] = err.message;
         });
         setErrors(errorObject);
         setAddAddressLoader(false);
      } else {
         if (result && result.data && result.data.status === true) {
            toast.success(result.data.message);
            document.querySelector('#address-offcanvas').click();
            setAddAddressError('');
            setErrors({});
            navigate("/address");
            getAddressDetails();

            setFullName('');
            setPhoneNumber('');
            setPincode('');
            setStateId('');
            setCity('');
            setHouseNoBuildingName('');
            setAddress('');
            setTypeOfAddress('');
            setGetStates('');
            getStates();

            setAddAddressLoader(false);
            document.getElementById('address-offcanvas').click();
         }
      }
   };

   const getStates = async () => {
      const result = await apiget('address/get-states');
      if (result && result.status === false && result.error_type === 'check_token') {
         updateUserTokenValidate(false);
      }
      if (result && result.data.status === true) {
         setGetStates(result.data.data);
      }
   };

   useEffect(() => {
      if (token) {
        getStates();
      }
    }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

   return (
      <React.Fragment>
         {getAddress ? (
            <div className="bodytop90">
               <MobileHeader header_title={'Address'} />
               <section className="mob-pt-0 mob-pb-0">
                  <div className="container mb-3">
                     <div className="row align-items-center" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                        <div className="col-10"></div>
                        <div className="col-2 text-end mobile-hide">
                           <Link type="button" className="btn btn-outline-primary btn-sm" data-bs-toggle="offcanvas" data-bs-target="#addressAdd"
                              aria-controls="addressAdd">
                              Add New Address
                           </Link>
                        </div>
                        {!getAddress && deleteAddressLoader === true(<Loader />)}
                        {getAddress && getAddress.length > 0 ? (
                           <section className="mob-pt-0 mob-pb-0">
                              <div className="container">
                                 <div className="row">
                                       {getAddress && getAddress.map((get_address_details, index) => {
                                          return (
                                             <React.Fragment key={index} >
                                                <div className="col-12 mb-4">
                                                   <div className="FlatCard text-start stretch-wrapper">
                                                      <div className="card-header mb-2 bg-white border-bottom-dashed">
                                                         <div className="row align-items-center">
                                                            <div className="col-lg-9 col-xl-9 col-12">
                                                               {get_address_details.type_of_address && (
                                                                  <h3 className="p-600">{get_address_details.type_of_address}</h3>
                                                               )}
                                                            </div>
                                                            <div className="col-lg-3 col-xl-3 text-end">
                                                               <Link className="ms-2" data-bs-toggle="modal" data-bs-target={`#address_delete_${get_address_details.id}`}><i className="bi bi-trash"></i></Link>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="card-body">
                                                         <div className="row align-items-center mb-2">
                                                            <div className="col-12 mb-3">
                                                               <h5 className="p-600">Address Details</h5>
                                                               <p className="mb-0">Name: {get_address_details.full_name}</p>
                                                               <p className="mb-0">Phone Number: {get_address_details.phone_number}</p>
                                                               <p className="mb-0">City: {get_address_details.city}</p>
                                                               <p className="mb-0">Address1: {get_address_details.address}</p>
                                                               
                                                               <p className="mb-0">Address2: {get_address_details.house_no_building_name}</p>
                                                            </div>
                                                            <div className="col-12 mb-3">
                                                               <h5 className="p-600">Outlet Details</h5>
                                                               <p className="mb-0">GST Number :    {get_address_details.gst_registration_no}
                                                               </p>
                                                               <p className="mb-0">Customer Type :    {get_address_details.gst_customer_type}
                                                               </p>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>

                                                <div className="modal fade" id={`address_delete_${get_address_details.id}`} tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
                                                   <header className="mobile-back-header">
                                                      <div className="container-fluid">
                                                         <div className="row align-items-center">
                                                            <div className="col-12">
                                                               <a href="" className="btn-backarrow" data-bs-dismiss="modal" aria-label="Close"><i
                                                                  className="bi bi-chevron-left"></i></a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </header>
                                                   <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                                                      <div className="modal-content mobile-modal-content-full">
                                                         <a type="button" className="close text-end mr-1 mobile-hide" style={{ fontSize: '20px', color: 'black' }} data-bs-dismiss="modal" aria-label="Close">X</a>
                                                         <div className="modal-body h4">
                                                            Are You Sure You Want to Delete Address?
                                                         </div>
                                                         <div className="modal-footer">
                                                            <button onClick={() => deleteAddress(get_address_details.id)} type="submit" className="btn btn-primary w-100">Delete Address</button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </React.Fragment>
                                          )
                                       })}
                                 </div>
                              </div>
                           </section>
                        ) : (
                           <div className="container mt-5">
                              <div className="row">
                                 <div className="col-12 text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}><path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z" /></svg>
                                    <h3>There Are No Address.</h3>
                                 </div>
                              </div>
                           </div>
                        )}

                        <div className="offcanvas offcanvas-end" tabIndex="-1" id="addressAdd" aria-labelledby="addressAddLabel">
                           <div className="offcanvas-header">
                              <h4 className="offcanvas-title" id="accountDrawerLabel">Add New Address</h4>
                              <a type="button" id="address-offcanvas" className="close text-end mr-1 text-reset" style={{ fontSize: '20px', color: 'black' }} data-bs-dismiss="offcanvas" aria-label="Close">X</a>
                           </div>
                           <div className="offcanvas-body body-gray">
                              <div className="FlatCard bg-white text-start p-0 rounded24 mb-4">
                                 <form onSubmit={addAddress}>
                                    <div className="card-body">
                                       <div className="form-group">
                                          <label className="text-cool-gray mb-2">Full Name: <span className="text-danger">*</span></label>
                                          <input type="text" className="form-control" id="full_name" placeholder="Enter Full Name" value={full_name} onChange={(e) => setFullName(e.target.value)} />
                                          {errors.full_name && <span className="text-danger">{errors.full_name}</span>}
                                       </div>
                                       <div className="form-group">
                                          <label className="text-cool-gray mb-2">Phone Number: <span className="text-danger">*</span></label>
                                          <input type="text" inputMode="numeric" pattern="[0-9]*" className="form-control bg-white" id="phone_number" placeholder="Enter Phone Number" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} />
                                          {errors.phone_number && <span className="text-danger">{errors.phone_number}</span>}
                                       </div>
                                       <div className="form-group">
                                          <label className="text-cool-gray mb-2">Pincode: <span className="text-danger">*</span></label>
                                          <input type="text" className="form-control bg-white" id="pincode" value={pincode} placeholder="Enter Pincode" inputMode="numeric" pattern="[0-9]*"  onChange={(e) => setPincode(e.target.value)} />
                                          {errors.pincode && <span className="text-danger">{errors.pincode}</span>}
                                       </div>
                                       <div className="form-group">
                                          <label className="text-cool-gray mb-2">State: <span className="text-danger">*</span></label>
                                          <select id="address_country1" name="address[country]" className="form-control" defaultValue="0" onChange={(e) => setStateId(e.target.value)}>
                                             <option value="0" label="Select a State">Select a State</option>
                                             {get_states && get_states.map((get_state, index) => {
                                                return (
                                                   <option key={index} value={get_state.code}>{get_state.description}</option>
                                                )
                                             })}
                                          </select>
                                          {errors.state_id && <span className="text-danger">{errors.state_id}</span>}
                                       </div>
                                       <div className="form-group">
                                          <label className="text-cool-gray mb-2">City: <span className="text-danger">*</span></label>
                                          <input type="text" value={city} className="form-control bg-white" id="city" placeholder="Enter City" onChange={(e) => setCity(e.target.value)} />
                                          {errors.city && <span className="text-danger">{errors.city}</span>}
                                       </div>
                                       <div className="form-group">
                                          <label className="text-cool-gray mb-2">Address1: <span className="text-danger">*</span></label>
                                          <input type="text" className="form-control bg-white" id="address" placeholder="Enter Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                          {errors.address && <span className="text-danger">{errors.address}</span>}
                                       </div>
                                       <div className="form-group">
                                          <label className="text-cool-gray mb-2">Address2:</label>
                                          <input type="text" className="form-control bg-white" id="house_no_building_name" placeholder="Enter Address 2" value={house_no_building_name} onChange={(e) => setHouseNoBuildingName(e.target.value)} />
                                       </div>
                                       <div className="form-group">
                                          <label className="text-cool-gray mb-2">Address Type:</label>
                                          <div className="form-check">
                                             <input className="form-check-input" type="radio" name="address_type" id="Home" value="Home" checked={type_of_address === 'Home'} onChange={(e) => setTypeOfAddress(e.target.value)} />
                                             <label className="form-check-label" htmlFor="Home">
                                                Home
                                             </label>
                                          </div>
                                          <div className="form-check">
                                             <input className="form-check-input" type="radio" name="address_type" id="Work" value="Work" checked={type_of_address === 'Work'} onChange={(e) => setTypeOfAddress(e.target.value)} />
                                             <label className="form-check-label" htmlFor="Work">
                                                Work
                                             </label>
                                          </div>
                                          {addAddressError && <span className="text-danger">{addAddressError}</span>}
                                          {addAddressLoader === true ? (
                                             <Loader spinner={'add_address'} />
                                          ) : (
                                             <button type="submit" className="btn btn-primary bnt-sm w-100 mt-3">Save Address</button>
                                          )}
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         ) : (
            <Loader />
         )}
      </React.Fragment>
   )
}