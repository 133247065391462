import React, { useCallback, useEffect, useState, useRef } from 'react';

import PlayStoreBadgeFooter from '../../../asset/img/PlayStoreBadgeFooter.png'
import AppStoreBadgeFooter from '../../../asset/img/AppStoreBadgeFooter.png'
import { useAuth } from '../../../service/AuthContext';
import { apiget } from '../../../service/API';
import { toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from 'react-router-dom';

export default function Footer({show_footer, getCartDetails}) {
    
    const { token, pageDetail, pageDetails, updateAppLogo, updateWhatsappLink, updateCompanyPhoneNo, updateCompanyEmail, deviceType } = useAuth();

    const [companyName, setCompanyName] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyPhoneNo, setCompanyPhoneNo] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [linkedinLink, setLinkedinLink] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [facebookLink, setFacebookLink] = useState('');
    const [instagramLink, setInstagramLink] = useState('');
    const [googlePlayStoreLink, setGooglePlayStoreLink] = useState('');
    const [appStoreLink, setAppStoreLink] = useState('');
    const [logo, setLogo] = useState('');
    const [licenseNo, setLicenseNo] = useState('');
    const [whatsappPhoneNo, setWhatsappPhoneNo] = useState('');
    const location = useLocation();
    const hasFetchedApiCall = useRef(false);

    // Get Company Details API
    const getComapnyDetail = useCallback(async () => {
        const getComapnyDetailResult = await apiget('general-setting/get-general-setting');
        if (getComapnyDetailResult && getComapnyDetailResult.data.status === true) {
            const company_name = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'company_name');
            setCompanyName(company_name.value);

            const company_address = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'company_address');
            setCompanyAddress(company_address.value);

            const company_phone_no = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'company_phone_no');
            setCompanyPhoneNo(company_phone_no.value);
            updateCompanyPhoneNo(company_phone_no.value);

            const company_email = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'company_email');
            setCompanyEmail(company_email.value);
            updateCompanyEmail(company_email.value);

            const linkedin_link = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'linkedin_link');
            setLinkedinLink(linkedin_link.value);

            const youtube_link = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'youtube_link');
            setYoutubeLink(youtube_link.value);

            const google_play_store_link = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'google_play_store_link');
            setGooglePlayStoreLink(google_play_store_link.value);

            const app_store_link = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'app_store_link');
            setAppStoreLink(app_store_link.value);

            const logo = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'logo');
            setLogo(logo.value);
            updateAppLogo(logo.value);
            
            const license_no = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'license_no');
            setLicenseNo(license_no.value);

            const facebook_link = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'facebook_link');
            setFacebookLink(facebook_link.value);
            
            const instagram_link = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'instagram_link');
            setInstagramLink(instagram_link.value);

            const whatsapp_link = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'whatsapp_link');
            updateWhatsappLink(whatsapp_link.value);

            const whatsapp_phone_no = getComapnyDetailResult.data.data.find(get_comapny_detail => get_comapny_detail.type === 'whatsapp_phone_no');
            setWhatsappPhoneNo(whatsapp_phone_no.value);
        }
        if (getComapnyDetailResult && getComapnyDetailResult.data.status === false) {
            toast.error(getComapnyDetailResult.data.message);
        }
    }, []);

    const getPageDetails = useCallback(async () => {
        const result = await apiget('page/get-page');
        if (result && result.data && result.data.status === true) {
            pageDetails(result.data.data);
        }
    }, [pageDetails]);
  
    useEffect(() => {
      if (!hasFetchedApiCall.current) {
        getComapnyDetail();
        getPageDetails();
        hasFetchedApiCall.current = true;
      }
    }, [getComapnyDetail]);

    return ( 
        <React.Fragment>
            <footer className={!token && location.pathname === "/" ? "main-footer":"main-footer  mobile-hide"}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-xl-4 col-sm-6 col-md-6 col-12">
                            <h6 className="p-600">Company</h6>
                            <div className="address-line">
                                <p><strong>{companyName}</strong></p>
                                <p className="text-cool-gray">{companyAddress}</p>
                            </div>
                            <div className="address-line">
                                <div className="address-icon">
                                    <Link className="bi bi-telephone-fill" to={'tel:'+companyPhoneNo}></Link>
                                </div>
                                <p>+91 {companyPhoneNo}</p>
                            </div>
                            <div className="address-line">
                                <div className="address-icon">
                                    <Link to={'https://wame.pro/'+whatsappPhoneNo} className="bi bi-whatsapp" target="_blank"></Link>
                                </div>
                                <p>+91 {whatsappPhoneNo} (Whatsapp only)</p>
                            </div>
                            <div className="address-line">
                                <div className="address-icon">
                                    <Link className="bi bi-envelope-fill" to={'mailto:'+companyEmail} ></Link>
                                </div>
                                <p>{companyEmail}</p>
                            </div>

                        </div>
                        <div className="col-lg-2 col-xl-2 col-md-6 col-sm-6 col-12">
                            <h6 className="p-600">Know   More</h6>
                            <ul className="quicklinks">
                                <li><Link to="/page/corporate-announcements">Corporate Announcements</Link></li>
                                {pageDetail ? pageDetail.map((getPage) => {
                                    return (
                                        <li key={getPage.id}><Link to={`/page/${getPage.id}`} onClick={() => getPageDetails(getPage.id)} target='_blank'>{getPage.page_name}</Link></li>
                                    )
                                }) : null}
                                <li><Link to="/page/FAQ">FAQ</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12">
                            <h6 className="p-600">Follow us on</h6>
                            <ul className="quicklinks">
                                <li><Link to={linkedinLink} target='_blank'>Linkedin</Link></li>
                                <li><Link to={youtubeLink} target='_blank'>Youtube</Link></li>
                                <li><Link to={facebookLink} target='_blank'>Facebook</Link></li>
                                <li><Link to={instagramLink} target='_blank'>Instagram</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12 desktop-right">
                            <div className="mb-4">
                                <img src={logo ? logo : '../../../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} width="175" />
                            </div>
                            <div>
                                <Link to={googlePlayStoreLink} target='_blank'><img src={PlayStoreBadgeFooter} width="135" /></Link>
                                <Link to={appStoreLink} target='_blank'><img src={AppStoreBadgeFooter} width="120" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-xl-6 col-12">
                                <p className="text-indigo-500">CIN: {licenseNo}</p>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12 desktop-right">
                                <p className="text-indigo-500">Copyright &copy; Promot All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {token  && location.pathname !== '/check-out' && location.pathname !== '/customer-list' && (
                <footer className={location.pathname === '/my-cart' && getCartDetails && getCartDetails.total_items >0 ?"mobile-hide desktop-hide":"mobile-footer-nav"}>
                    <ul>
                    <li>
                        <Link to="/shop-by-category/1">
                            <img src={location.pathname.split('/')[1] === 'shop-by-category' ? '../../../asset/img/footer-nav/footernav-shop-active.png' : '../../../asset/img/footer-nav/footernav-shop.png'} />
                            Shop
                        </Link>
                    </li>
                    <li>
                        <Link to="/my-wishllist">
                            <img src={location.pathname === '/my-wishllist' ? '../../../asset/img/footer-nav/footernav-fav-active.png' : '../../../asset/img/footer-nav/footernav-fav.png'}/>
                            Wishlist
                        </Link>
                    </li>
                    <li>
                        <Link to="/">
                            <img src="../../../asset/img/footer-nav/home.png" />
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/order/my-order">
                            <img src={location.pathname.split('/')[1] === 'order' ? '../../../asset/img/footer-nav/footernav-orders-active.png' : '../../../asset/img/footer-nav/footernav-orders.png'} />
                            Orders
                        </Link>
                    </li>
                    <li>
                        <a href="#" data-bs-toggle="offcanvas" data-bs-target="#accountDrawer" aria-controls="accountDrawer">
                            <img src="../../../asset/img/footer-nav/footernav-account.png" />
                        Account
                        </a>
                    </li>
                    </ul>
                </footer>
            )}
        </React.Fragment>
    )
}
