import React from 'react'
import { Link } from 'react-router-dom';

export default function ShopByCategory({ categories }) {
  return (
    <React.Fragment>
      {categories && categories.data.length > 0 && (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="Heading-Medium">Shop by categories</h2>
              </div>
            </div>
            <ul className="shopbycategoryList">
              {categories && categories.data.map((get_category) => {
                return (
                  <li key={get_category.id}>
                    <Link to={`/shop-by-category/${get_category.id}`}>
                      <img src={get_category.category_image ? get_category.category_image : './asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = './asset/img/no_image_available.jpg'; }} className="categoryIcon" />
                      <div className="categoryTitle">
                        {get_category.category_name}
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
      )}
    </React.Fragment>
  )
}
