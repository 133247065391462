import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import $ from 'jquery';

export default function SearchResult({getCart, getCartDetails }) {
    const { token, updateToken, isLoggedIn, isBackEndLogin, updateUserTokenValidate, userTokenValidate} = useAuth();
    const [getSearchItem, setGetSearchItem] = useState('');
    const navigate = useNavigate();
    const timeoutRef = useRef(null);
    const headerRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (headerRef.current && !headerRef.current.contains(event.target)) {
          setGetSearchItem('');
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    
    const searchitem = useCallback(async (searchItem) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            let data = {
            Item_Name: searchItem === '' ? '' : searchItem
            };
            const result = await apipost('item/search-item', data);
            if (result && result.status === false && result.error_type === 'check_token') {
                updateUserTokenValidate(false);
                setGetSearchItem("");
            }
            if (result && result.status === false) {
                setGetSearchItem("");
            }
            if (result && result.data && result.data.status === true) {
                setGetSearchItem(result.data.data);
            }
        }, 500);
    }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

      useEffect(() => {
        $('#search-item').val('');
        setGetSearchItem('');
      }, [location.pathname]);


    return (
        <React.Fragment>
        <style>
            {`
                ul.shopbycategoryList li a {
                display: block;
                width: 112px;
                height: 130px;
                padding: 15px;
                background-color: #FFF;
                box-shadow: 0 1px 4px rgba(28, 28, 28, .06);
                border-radius: 12px;
                margin-right: 5px;
                margin-top: 5px;
                }

                ul.shopbycategoryList li a .categoryTitle {
                font-size: 13px;
                }

                ul.shopbycategoryList li a .categoryIcon {
                height: 60px;
                }
            `}
        </style>
            <div className="bodytop90 body-gray">
                <header className="login-header desktop-hide mobile-show" style={{ borderRadius: '0px' }}>
                    <nav className="nabar">
                        <div className="container-fluid">
                            <div className="row d-flex align-items-center justify-content-between">
                                <div className="col-lg-8 col-xl-8 col-9 change-category" ref={headerRef}>
                                    <div className="row align-items-center">
                                        <div className="col-2">
                                            <Link onClick={() => navigate(-1)} className="text-dark"><i className="bi bi-chevron-left"></i></Link>
                                        </div>
                                        <div className="col-10 p-0">
                                            <div className="form-group mb-0">
                                                <span className="icon-flag"><img src="/../../../asset/img/searchThick.png" width="18" /></span>
                                                <input type="text" onChange={(e) => searchitem(e.target.value)}  className="form-control search-bar" placeholder="Search your need..." id="search-item" />
                                                {getSearchItem && getSearchItem.length > 0  &&(
                                                <div className='search-results'>
                                                    <ul>
                                                    {getSearchItem.map((get_search_item, index) => (
                                                        <Link to={`/item-details/${get_search_item.SystemId}`} className="text-decoration-none" key={index} onClick={() => searchitem('hideResults')}>
                                                        <li className="search-item">
                                                            <div className="search-item-content">
                                                            <img src={get_search_item.image ? get_search_item.image : '/../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '/../asset/img/no_image_available.jpg'; }} width="60px"/>
                                                            <span>{get_search_item.Description}</span>
                                                            </div>
                                                        </li>
                                                        </Link>
                                                    ))}
                                                    </ul>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xl-4 col-3 text-end">
                                    <nav>
                                        <ul className="iconNavigation">
                                            <li>
                                                <Link to="/my-cart" className="active"><i className="bi bi-cart2"></i>
                                                    <div className="cart-counter">{getCartDetails ? getCartDetails.total_items : 0}</div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                <section>
                    <div className="container">
                        <div className="row align-items-center mb-3">
                            <div className="col-9">
                                <h5 className="mb-0 p-600">Recently searched</h5>
                            </div>
                            <div className="col-3 text-end">
                                <Link>clear all</Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <ul className="RecentlysearchList">
                                    <li>
                                        <Link>
                                            <i className="bi bi-clock-history p-22"></i> Tomato ketchup sachet
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <i className="bi bi-clock-history p-22"></i> Slat
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/masala-salt/kasmiri-chilli-powder.png" /> Kashmiri chilli powder
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mb-5'>
                    <div className="container">
                        <div className="row align-items-center mb-3">
                            <div className="col-12">
                                <h5 className="mb-0 p-600">Recommended for you</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <ul className="shopbycategoryList">
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/packaging-material.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                packaging Material
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/masala-salt.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Masala, Salt & Sugar
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/souces.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Souces & Seasoning
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/canned-imported.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Canned & Imported
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/cleaning.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Cleaning & Consumables
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/frozen.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Frozen & Instatnt Food
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/kitchenweare.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Kitchenware & Applinces
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/dairy.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Dairy
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/bakery.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Bakery & Chocolates
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/beverages.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Beverags & Mixes
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <img src="/../../../asset/img/category/dry-fruits-nuts.png" className="categoryIcon" />
                                            <div className="categoryTitle">
                                                Dry Fruits & Nuts
                                            </div>
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}