import React, {useEffect, useState } from 'react';
import { useAuth } from '../../../service/AuthContext';

export default function ScrollToTop() {
    const { deviceType, checkIsLoggedIn} = useAuth();
    const [showButton, setShowButton] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleScroll = () => {
          setShowButton(window.scrollY > 400);
        };
      
        window.addEventListener("scroll", handleScroll);
        
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);
      
    return (
        <React.Fragment>
            {showButton && (
                <img  className="up-arrow" src="'/../../../asset/img/up-arrow.png"  alt="up-arrow" style={{ cursor: "pointer", position: "fixed", bottom: "40px", right: "40px", borderRadius: "100%",marginBottom: deviceType === 'mobile'?checkIsLoggedIn !== 'true' ?"":"50px":"", marginRight: deviceType === 'mobile'?"-2px":""
                }}
                 onClick={scrollToTop}
                />
            )}
        </React.Fragment>
    )
}
