import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../service/AuthContext';

export default function CustomerHeader({ }) {
  const { appLogo, updateToken, isLoggedIn, updateLoginUserName, isBackEndLogin, updateUserRoleType, deviceType } = useAuth();
  const getPhoneNo = localStorage.getItem('loginUserPhoneNo');
  const loginUserName = localStorage.getItem('loginUserName');
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('loginUserName');
    localStorage.removeItem('facebook_access_token');
    localStorage.removeItem('isBackEndLogin');
    localStorage.removeItem('loginUserPhoneNo');
    localStorage.removeItem('role_type');

    updateToken(localStorage.removeItem('token'));
    updateUserRoleType('');
    isLoggedIn(localStorage.removeItem('isLoggedIn'));
    updateLoginUserName(localStorage.removeItem('loginUserName'));
    isBackEndLogin(localStorage.removeItem('isBackEndLogin'));
    // setTimeout(() => {
      navigate("/");
    // }, 100);
  };
  return (
    <React.Fragment>
      <header className="login-header">
        <nav className="nabar">
          <div className="container-fluid">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-lg-4 col-xl-4 col-12 mobile-hide">
                <div className="row align-items-center">
                  <div className="col-lg-5 col-xl-5 col-md-12 col-sm-12 col-12">
                    <Link to="/customer-list">
                      <img src={appLogo} width="150" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-xl-8 col-12">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-xl-8 col-9">
                    <div className="form-group mb-0">
                      <div className="col-2">
                        {(deviceType === 'mobile' && location.pathname === "/customer-list" &&
                          <Link type="button">
                            <p className="text-elipsis p-600 text-dark mb-0">Customer List</p>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 col-3 text-end">
                    <nav>
                      <ul className="iconNavigation">
                        <li className={deviceType === 'mobile' && location.pathname === "/customer-list" ? "" : "mobile-hide"}>
                          <a href="#" data-bs-toggle="offcanvas" data-bs-target="#accountDrawer" aria-controls="accountDrawer"><i className="bi bi-filter-right"></i></a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="offcanvas offcanvas-end" data-bs-scroll="true" tabIndex="-1" id="accountDrawer" aria-labelledby="accountDrawerLabel">
        <div className="offcanvas-header">
          <div className="col-lg-auto col-xl-auto col-2">
            <div className="avtar42">
              <img src="../../../asset/img/sidemenu_user_icon.png" />
            </div>
          </div>
          <div className="col-10">
            <h5 className="offcanvas-title" id="accountDrawerLabel">{loginUserName}</h5>
            <p className="mb-0 text-muted">+91 {getPhoneNo}</p>
          </div>
          <a type="button" className="close text-end mr-1 text-reset" style={{ fontSize: '20px', color: 'black', marginLeft: deviceType === 'mobile' ? '-15px' : '' }} data-bs-dismiss="offcanvas" aria-label="Close">X</a>
        </div>
        <div className="offcanvas-body">
          <div className="side-widget">
            <ul className="widget-nav">
              <li>
                <Link onClick={handleLogout} data-bs-dismiss="offcanvas" aria-label="Close">
                  <div className="row align-items-center">
                    <div className="col-12 p-0">
                      <img src="../../../asset/img/sidemenu_logout_v2.png" width="16" className="me-2" />
                      <span className="text-theme">Logout</span>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}
