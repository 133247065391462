import axios from "axios";
import { toast } from "react-toastify";
import { checkUserToken } from "./CommanAPI";

// Get Method Api
export const apiget = async (path, data) => {
    try {
        const nullableData = data ?? {};
        let response = await axios.get(process.env.REACT_APP_API_URL + path, {
            nullableData,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            credentials: 'include',
        });
        // Store token
        if (response.data && response.data.data && response.data.data.token && response.data.data.token !== null) {
            localStorage.setItem('token', response.data.data.token);
        }
    
        return response;
    } catch(error) {
        if (error?.response?.data?.status === false) {
            // Logout If Token Expire
            if (error?.response?.data?.error_type === 'check_token') {
                checkUserToken();
            }
            if (error?.response?.data?.message) {
                if (error?.response?.data?.error_type !== 'check_token') {
                    toast.error(error.response.data.message);
                }
                if (error?.response?.data?.error_type === 'check_token') {
                    return error.response.data;
                }
            }
        }
    }
}

// Post Method Api
export const apipost = async (path, data) => {
    try {
        const nullableData = data ?? {};
        const response = await axios.post(process.env.REACT_APP_API_URL + path, nullableData, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            credentials: 'include',
        });

        return response;
    } catch(error) {
        if (error?.response?.data?.status === false) {
            // Logout If Token Expire
            if (error?.response?.data?.error_type === 'check_token') {
                checkUserToken();
            }
            if (error?.response?.data?.status === false) {
                return error.response.data;
            }
            if (error?.response?.data?.errors) {
                return error.response.data;
            }
        }
    }
}
