import React, { useEffect, useState, useCallback, useRef } from 'react';
import { apiget } from '../../../service/API';
import Loader from '../Layout/Loader';
import { toast } from 'react-toastify';

export default function FAQ({ }) {
    
    const [faqDetails, setFaqDetails] = useState('');
    const hasFetchedscrollToTop = useRef(false);

    // Get FAQ API
    const getFAQ = useCallback(async () => {
        const getFaqResult = await apiget('general-setting/get-faq');
        if (getFaqResult && getFaqResult.data.status === true) {
            setFaqDetails(getFaqResult.data.data);

        }
        if (getFaqResult && getFaqResult.data.status === false) {
            toast.error(getFaqResult.data.message);
        }
    }, []);

    useEffect(() => {
        getFAQ();
    }, []);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    };
    
    useEffect(() => {
      if (!hasFetchedscrollToTop.current) {
        scrollToTop();
        hasFetchedscrollToTop.current = true;
      }
    }, [scrollToTop]);

    return (
        <React.Fragment>
            <style>
                {`
                    .accordion-item {
                        background-color: #fff;
                        border: none;
                        box-shadow: 0 4px 16px 0 rgba(39, 49, 68, .04);
                        margin-bottom: 24px;
                        border-radius: 4px !important;
                        border: 1px solid #cfcfcf !important;
                    }

                    .accordion-button:not(.collapsed) {
                        color: #000;
                        background-color: #FFF;
                        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
                        border-bottom-left-radius: 0px !important;
                        border-bottom-right-radius: 0px !important;
                    }
                `}
            </style>
            <div className="bodytop90">
                {faqDetails ? (
                    <React.Fragment>
                        {faqDetails && faqDetails.length > 0 ? (
                            <section>
                                <div className="container">
                                    <h2 className="Heading-Medium mb-4"> Frequently asked questions </h2>
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        {faqDetails && faqDetails.map((getFaq) => {
                                            return (
                                                <div className="accordion-item" key={getFaq.id}>
                                                    <h2 className="accordion-header" id={`flush-heading${getFaq.id}`}>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${getFaq.id}`} aria-expanded="false" aria-controls={`flush-collapse${getFaq.id}`}>
                                                            {getFaq.title}
                                                        </button>
                                                    </h2>
                                                    <div id={`flush-collapse${getFaq.id}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${getFaq.id}`} data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <div>
                                                                <p dangerouslySetInnerHTML={{ __html: getFaq.description }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>
                        ) : (
                        <section className="mob-pt-0 mob-pb-0 mt-5">
                            <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}><path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z"/></svg>
                                <h3>FAQ Not Found.</h3>
                                </div>
                            </div>
                            </div>
                        </section>
                        )}
                    </React.Fragment>
                ) : (
                    <Loader />
                )}
            </div>
        </React.Fragment>
    )
}
