import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

export default function Loader({ spinner, className }) {

    return (
        <React.Fragment>
            {/* Check Out Address */}
            {spinner === 'check-out-address' && (
                <ThreeDots
                    visible={true}
                    color={'#fff'}
                    width={20}
                    height={25}
                    wrapperStyle={{ alignItems: 'center', display: 'flex', justifyContent: 'center',width: '205px' }}
                    wrapperClass={className}
                />   
            )}
            {spinner === 'request-product' && (
                <ThreeDots
                    visible={true}
                    color={'#fff'}
                    width={20}
                    height={25}
                    wrapperStyle={{ alignItems: 'center', display: 'flex', justifyContent: 'center',width: '95px' }}
                    wrapperClass={className}
                />
            )}
            {spinner === 'add-retailer' && (
                <ThreeDots
                    visible={true}
                    color={'#fff'}
                    width={100}
                    height={24}
                    wrapperStyle={{ alignItems: 'center', display: 'flex', justifyContent: 'center',width: '91px' }}
                    wrapperClass={className}
                /> 
            )}

            {/* Check Out */}
            {spinner === 'place-order' && (
                <div className="form-group mt-3 text-end">
                    <button type="button" className="btn btn-primary pe-5 ps-5">
                        <ThreeDots
                            visible={true}
                            color={'#fff'}
                            width={91}
                            height={23}
                            wrapperStyle={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                            wrapperClass=""
                        />
                    </button>
                </div>
            )}

            {/* Add To Cart Button Loader */}
            {spinner === 'button' && (
                <div className="col-xl-5 col-lg-5 col-5">
                    <button type="button" className="btn add-btn w-100">
                        <ThreeDots
                            visible={true}
                            color={'#fff'}
                            height={14}
                            wrapperStyle={{ backgroundColor: '#e03546', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                            wrapperClass={className}
                        />
                    </button>
                </div>
            )} 

            {spinner === 'cart-button' && (
                // <div className="col-xl-3 col-lg-3 col-3">
                    <button type="button" className="btn add-btn w-100">
                        <ThreeDots
                            visible={true}
                            color={'#fff'}
                            width={100}
                            height={21}
                            wrapperStyle={{ alignItems: 'center', display: 'flex', justifyContent: 'center',width: '88px' }}
                            wrapperClass={className}
                        />
                    </button>
                // </div>
            )} 

            {spinner === 'cancel-order' && (
                // <div className="col-xl-3 col-lg-3 col-3">
                    <button type="button" className="btn add-btn w-100">
                        <ThreeDots
                            visible={true}
                            color={'#fff'}
                            width={100}
                            height={21}
                            wrapperStyle={{ alignItems: 'center', display: 'flex', justifyContent: 'center',width: '135px' }}
                            wrapperClass={className}
                        />
                    </button>
                // </div>
            )} 

            {/* Item Add To Cart Button Loader */}
            {spinner === 'item_cart_button' && (
                <div className="col-xl-3 col-lg-5 col-5">
                    <button type="button" className="btn add-btn w-100">
                        <ThreeDots
                            visible={true}
                            color={'#fff'}
                            height={14}
                            wrapperStyle={{ backgroundColor: '#e03546', alignItems: 'center', display: 'flex', justifyContent: 'center', minWidth:"90px" }}
                            wrapperClass={className}
                        />
                    </button>
                </div>
            )} 

            {spinner === 'cart-loader' && (
                <div className="col-xl-5 col-lg-5 col-5  w-100">
                    <button type="button" className="btn add-btn w-100">
                        <ThreeDots
                            visible={true}
                            color={'#fff'}
                            height={14}
                            wrapperStyle={{ backgroundColor: '#e03546', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                            wrapperClass={className}
                        />
                    </button>
                </div>
            )}

             {/* account-stsatement */}
             {spinner === 'account-stsatement' && (
                <ThreeDots
                    visible={true}
                    color={'#fff'}
                    height={23}
                    wrapperStyle={{ backgroundColor: '#e03546', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                    wrapperClass={className}
                />
            )}

            {/* Add Address Loader */}
            {spinner === 'add_address' && (
                <ThreeDots
                    visible={true}
                    height="26"
                    width="80"
                    color={'#fff'}
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ backgroundColor: '#ef4f5f', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                    wrapperClass="btn btn-primary bnt-sm w-100"
                />
            )}

            {spinner === 'user_details' && (
                <ThreeDots
                    visible={true}
                    height="26"
                    width="130"
                    color={'#fff'}
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ backgroundColor: '#ef4f5f', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                    wrapperClass="btn btn-primary bnt-sm w-100"
                />
            )}

            {spinner === 'sub_sub_category_item_loader' && (
                <div className="col-lg-9 col-xl-9 col-9">
                    <ThreeDots
                        visible={true}
                        height="300"
                        width="80"
                        color={'#ef4f5f'}
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ backgroundColor: '#f4f6fb', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                        wrapperClass=""
                    />
                </div>
            )}

            {spinner === 'category_loader' && (
                <section className="mob-pt-0 position-relative">
                    <div className="container">
                        <div className="row">
                            <ThreeDots
                                visible={true}
                                height="300"
                                width="80"
                                color={'#ef4f5f'}
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{ backgroundColor: '#f4f6fb', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                                wrapperClass=""
                            />
                        </div>
                    </div>
                </section>
            )}

            {spinner === 'sub_category_detail_loader' && (
                <section className="mob-pt-0 position-relative">
                    <div className="container">
                        <div className="row">
                            <ThreeDots
                                visible={true}
                                height="300"
                                width="80"
                                color={'#ef4f5f'}
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{ backgroundColor: '#f4f6fb', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                                wrapperClass=""
                            />
                        </div>
                    </div>
                </section>
            )}

            {spinner === 'item_loader' && (
                <ThreeDots
                    visible={true}
                    height="300"
                    width="80"
                    color={'#ef4f5f'}
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ backgroundColor: '#f4f6fb', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                    wrapperClass=""
                />
            )}

            {/* Full Page Loader */}
            {spinner === undefined && (
                <ThreeDots
                    visible={true}
                    height="80"
                    width="80"
                    color={'#e03546'}
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ backgroundColor: '#fff', height: '100vh', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
                    wrapperClass={{ className }}
                />
            )}
        </React.Fragment>
    )
}
