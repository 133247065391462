import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import Loader from '../Layout/Loader';
import $ from 'jquery';
import MobileHeader from '../Layout/MobileHeader';

export default function MyOrderDetail({ getCart, getCartDetails, getWishList, getNotifications }) {
    const { order_id } = useParams();
    const [getOrderWiseItemDetails, setGetOrderWiseItemDetails] = useState('');
    const [getOrderWiseDetails, setGetOrderWiseDetails] = useState('');
    const [getTimeDifferenceInDays, setTimeDifferenceInDays] = useState('');
    const [orderEditLoader, setOrderEditLoader] = useState(false);
    const [orderCancelLoader, setOrderCancelLoader] = useState(false);
    const [microsoftItemSystemIds, setMicrosoftItemSystemIds] = useState('');
    const hasFetchedscrollToTop = useRef(false);
    const hasFetchedGetItem = useRef(false);
    const [inputValue, setInputValue] = useState({});
    const timeoutRef = useRef(null);
    
    const navigate = useNavigate();
    const { token, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate, deviceType } = useAuth();

    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    };
    
    useEffect(() => {
        if (!hasFetchedscrollToTop.current) {
            scrollToTop();
            hasFetchedscrollToTop.current = true;
        }
    }, [scrollToTop, token]);

    useEffect(() => {
    }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

    const setPendingQuantity = (event, index) => {
        let { value } = event.target;
        if (/^[0-9]*$/.test(value) && !/^0/.test(value)) {
            if (value > 99999) {
              value = 99999;
            }
            setInputValue(prevState => ({ ...prevState, [index]: value }));
        }
    };

    const getOrderWiseDetail = async () => {
        let data = {
            order_id: order_id
        }
        const result = await apipost('order/get-order-detail', data);
        if (result && result.status === false && result.error_type === 'check_token') {
            updateUserTokenValidate(false);
        }
        if (result && result.data && result.data.status === false) {
            toast.error(result.data.message);
        }

        if (result && result.data && result.data.status === true) {
            let created_at = new Date(result.data.data.created_at);
            if (result.data.data.order_status !== 'Pending') {
                created_at = new Date(result.data.data.updated_at);
            }
            const now = new Date();
            const startDate = new Date(created_at.getFullYear(), created_at.getMonth(), created_at.getDate());
            const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

            const timeDifferenceInDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
            setTimeDifferenceInDays(timeDifferenceInDays === 0 ? `${Math.floor((now - new Date(created_at)) / (1000 * 60 * 60))} hours ago` : `${timeDifferenceInDays} days ago`);

            setGetOrderWiseItemDetails(result.data.data.orderWiseItemDetail);
            const microsoftItemSystemIds = result.data.data.orderWiseItemDetail.map(item => item.microsoft_item_system_id);
            setMicrosoftItemSystemIds(microsoftItemSystemIds)
            
            setGetOrderWiseDetails(result.data.data);
        }
    };

    const updateOrder = async (e, value, item_no, sales_order_no) => {
        setOrderEditLoader(true);
        const quantity_value = e.target.value !== "" ? e.target.value :value
        
        e.preventDefault();
        let data = {
            quantity: quantity_value,
            item_no:item_no,
            sales_order_no:sales_order_no
        };
        
        const result = await apipost('order/update-order', data);
        if (result && result.status === false && result.error_type === 'check_token') {
          updateUserTokenValidate(false);
          setOrderEditLoader(false);
          toast.error(result.data.message);
        }
  
        if (result && result.data && result.data.status === true) {
            setOrderEditLoader(false);
            getOrderWiseDetail();
            setInputValue();
            getNotifications();
            window.$(".order_edit").modal("hide");
            toast.success(result.data.message);
        }
    };

    const cancelOrder = async (e, item_no, sales_order_no) => {
        setOrderCancelLoader(true);
        e.preventDefault();
        let data = {
            item_no:item_no,
            sales_order_no:sales_order_no
        };
        
        const result = await apipost('order/cancel-order', data);
        if (result && result.status === false && result.error_type === 'check_token') {
            setOrderCancelLoader(false);
            updateUserTokenValidate(false);
            toast.error(result.data.message);
        }
  
        if (result && result.data && result.data.status === true) {
            setOrderCancelLoader(false);
            getOrderWiseDetail();
            getNotifications();
            window.$(".order_delete").modal("hide");
            window.$(".order_cancel").modal("hide");
            toast.success(result.data.message);
            if(item_no === ""){
                navigate("/order/my-order")
            }
        }
    };

    const searchMyOrderDetail = async (e, sales_order_no) => {
        // const searchMyOrderDetail = $('#search-my-order-detail').val();
        const value = e.target.value;
    
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(async () => {
          let data = {
            item_name: value === '' ? '' : value,
            order_no: sales_order_no
          };
          const result = await apipost('order/search-order-detail', data);
          if (result && result.status === false && result.error_type === 'check_token') {
            updateUserTokenValidate(false);
          }
    
          if (result && result.data && result.data.status === true) {
            setGetOrderWiseItemDetails(result.data.data);
            
          }
        }, 800);
      };

    useEffect(() => {
        if (token) {
            if (!hasFetchedGetItem.current) {
                getOrderWiseDetail();
                hasFetchedGetItem.current = true;
            }
        }
    }, [token, navigate, isLoggedIn, updateToken, order_id, updateUserTokenValidate, userTokenValidate]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours() % 12 || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

        return `${day}/${month}/${year}, ${hours}:${minutes} ${ampm}`;
    }

    return (
        <React.Fragment>
            <MobileHeader header_title={'My Order Detail'} getCartDetails={getCartDetails}/>
            {getOrderWiseItemDetails && getOrderWiseItemDetails ? (
                <div className="bodytop90">
                    <section className="mob-p-0 pb-0">
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-1">
                                    <img src='../../../asset/img/Arrow-left.png' className="Heading-icon  mobile-hide" height='70px' onClick={() => { navigate(-1); setTimeout(() => { window.scrollTo(0, 0); }, 100); }}/>
                                </div>
                                <div className="col-7">
                                    <h1 className="Heading-Medium mobile-hide">{getOrderWiseDetails.sales_order_no}</h1>
                                </div>
                                <div className="col-4">
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="modal fade order_cancel" id="order_cancel" tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                            <div className="modal-content">
                                <a type="button" className="close text-end mr-1" style={{ fontSize: '20px', color: 'black' }} data-bs-dismiss="modal" aria-label="Close">X</a>
                                <div className="modal-body h4">
                                    Are You Sure You Want Cancel Order?
                                </div>
                                <div className="text-end mb-4 mx-4">
                                    {orderCancelLoader === true ? (
                                        <>
                                            <div className="d-inline-flex align-items-center">
                                                <Loader spinner={'cancel-order'} className={'btn btn-primary'} />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={(e) => cancelOrder(e,"", getOrderWiseDetails.sales_order_no)} type="button" className="btn btn-primary">Cancel Order</button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="mob-p-0 mob-mb1">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="FlatCard text-start">
                                        <div className="card-header pe-0 ps-0 mb-3 bg-white border-bottom-dashed">
                                            <div className="row align-items-center">
                                                <div className="col-lg-4 col-xl-4 col-5">
                                                    <p className="mb-0 text-cool-gray">Order No.</p>
                                                    <p className="p-12 mb-0">{getOrderWiseDetails.sales_order_no}</p>
                                                </div>
                                                <div className="col-lg-4 col-xl-4 col-4">
                                                    <p className="mb-0 text-cool-gray">Order date</p>
                                                    <p className="p-12 mb-0">{formatDate(getOrderWiseDetails.created_at)}</p>
                                                </div>
                                                <div className="col-lg-4 col-xl-4 col-3 text-end">
                                                    <p className="mb-0 text-cool-gray">Total Amount</p>
                                                    <p className="p-12 mb-0"><strong>₹ {(parseFloat(getOrderWiseDetails.total_amount)+parseFloat(getOrderWiseDetails.total_gst_amount)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <p className="mb-0 text-cool-gray">Order Qty</p>
                                                <p className="p-12 mb-0">{getOrderWiseDetails.total_order_quantity} Pcs</p>
                                                <p className="p-12 mb-0">{getOrderWiseDetails.total_items} Items</p>
                                            </div>
                                            <div className="col-4">
                                                <p className="mb-0 text-cool-gray">{getOrderWiseDetails.order_status === "Cancel" ? 'Cancel Qty' : 'Pending Qty'}</p>
                                                <p className="p-12 mb-0">{getOrderWiseDetails.pending_quantity} Pcs</p>
                                                <p className="p-12 mb-0">{getOrderWiseDetails.total_pending_item} Items</p>
                                            </div>
                                            <div className="col-4 text-end">
                                                <p className="mb-0 text-cool-gray">{getOrderWiseDetails.order_status} {getOrderWiseDetails.order_status === 'Pending' ?  'since' : ''}</p>
                                                <p className="p-12 mb-0 text-danger">{getTimeDifferenceInDays && getTimeDifferenceInDays}</p>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-4">
                                                <div className="col-4">
                                                    {(getOrderWiseDetails.order_status === "Pending" &&
                                                        <a href="#" className="btn btn-outline-primary btn-sm">Pending</a>
                                                    )}
                                                    {(getOrderWiseDetails.order_status === "Cancel" &&
                                                        <a href="#" className="btn btn-outline-primary btn-sm">Cancel</a>
                                                    )}
                                                    {(getOrderWiseDetails.order_status === "Completed" &&
                                                        <a href="#" className="btn btn-outline-primary btn-sm">Completed</a>
                                                    )} 
                                                </div>
                                            </div>
                                            <div className="col-4">
                                            </div>
                                            <div className="col-4 text-end">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="pt-0 mt-4">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-xl-6 col-12">
                                    <div className="form-group mb-0 mob-mb1">
                                        <span className="icon-flag"><img src="../../../asset/img/searchThick.png" width="18" /></span>
                                        <input type="text" id="search-my-order-detail" className="form-control search-bar" placeholder="Search your need..." onChange={(e)=>searchMyOrderDetail(e, getOrderWiseDetails.sales_order_no)} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 col-12">
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                        </div>
                                        {/* <div className="col-4">
                                            <div className="form-group mb-0">
                                                <select className="form-select fullrounded">
                                                    <option>Sort By</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-4">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="p-0">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-6">
                                    <p>{getOrderWiseDetails.total_items} items in item</p>
                                </div>
                                <div className="col-6 text-end mb-3">
                                {getOrderWiseDetails.order_status === "Pending" && microsoftItemSystemIds.every(id => id !== null ) && getOrderWiseItemDetails.every(item => item.item_order_status === "Pending") ?(
                                    <Link className={deviceType === "mobile" ? "col-4 text-end mb-2" :"col-4 text-end"} data-bs-toggle="modal" data-bs-target="#order_cancel" >
                                        <i className="bi bi-trash text-danger" style={{ height: '200px', width: '200px', fontSize: '20px' }}></i>Order Cancel
                                    </Link>
                                ) : (
                                    <div className="col-4 text-end"></div>
                                )}
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            {getOrderWiseItemDetails.length > 0 ? (
                                <div className="row">
                                    {getOrderWiseItemDetails ? getOrderWiseItemDetails.map((get_order_wise_details, index) => {
                                        return (
                                            <div key={index} className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                                                <div className="FlatCard text-start">
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-3 col-xl-3 col-3 text-center">
                                                            <img src={get_order_wise_details.image ? get_order_wise_details.image : "../../../asset/img/no_image_available.jpg"} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} className="img-fluid w-100" />
                                                        </div>
                                                        <div className="col-9">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="mb-0">{get_order_wise_details.Description}</p>
                                                                {(get_order_wise_details.pending_quantity !== 0 && get_order_wise_details.item_order_status === "Pending" && get_order_wise_details.microsoft_item_system_id !== null &&
                                                                    <div className="d-flex ms-auto">
                                                                        <Link data-bs-toggle="modal" data-bs-target={`#order_edit${get_order_wise_details.microsoft_item_system_id}`} className="me-2">
                                                                            <i className="bi bi-pencil-square"></i>
                                                                        </Link>
                                                                        
                                                                        <Link data-bs-toggle="modal" data-bs-target={`#order_delete${get_order_wise_details.microsoft_item_system_id}`} className="me-2">
                                                                            <i className="bi bi-trash text-danger"></i>
                                                                        </Link>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <p className="p-12 mb-0"><strong>₹ {get_order_wise_details.purchase_price === null ? 0 : get_order_wise_details.purchase_total_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></p>
                                                            <div className="row mt-2">
                                                                <div className="col-6">
                                                                    <p className="mb-0 text-cool-gray">Order Qty</p>
                                                                    <p className="mb-0">{get_order_wise_details.quantity} {get_order_wise_details.Base_Unit_of_Measure}</p>
                                                                </div>
                                                                <div className="col-6">
                                                                    <p className="mb-0 text-cool-gray">{get_order_wise_details.item_order_status === "Cancel" ? 'Cancel Qty' : 'Pending Qty'}</p>
                                                                    {/* {get_order_wise_details.item_order_status !== "Cancel" && ( */}
                                                                        <p className="mb-0">{get_order_wise_details.pending_quantity} {get_order_wise_details.Base_Unit_of_Measure} </p>
                                                                    {/* )} */}
                                                                </div>
                                                                <div className="col-12 mt-2">
                                                                    {(get_order_wise_details.item_order_status === "Pending" &&
                                                                        <p className="mb-0 text-warning">Partially dispatched</p>
                                                                    )}
                                                                    {(get_order_wise_details.item_order_status === "Cancel" &&
                                                                        <p className="mb-0 text-danger"> Cancel</p>
                                                                    )}
                                                                    {(get_order_wise_details.item_order_status === "Completed" &&
                                                                        <p className="mb-0 text-success"> Fully Delivered</p>
                                                                    )}                          
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal fade order_edit" id={`order_edit${get_order_wise_details.microsoft_item_system_id}`} tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
                                                    <header className="mobile-back-header">
                                                        <div className="container-fluid">
                                                        <div className="row align-items-center">
                                                            <div className="col-12">
                                                            <a href="" className="btn-backarrow" data-bs-dismiss="modal" aria-label="Close"><i
                                                                className="bi bi-chevron-left"></i></a>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </header>
                                                    <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                                                        <div className="modal-content">
                                                            <a type="button" className="close text-end mr-1" style={{ fontSize: '20px', color: 'black' }} data-bs-dismiss="modal" aria-label="Close">X</a>
                                                            <div className="modal-body mb-3">
                                                                <div className="form-group">
                                                                    <span className="h5">   {get_order_wise_details.Description}</span>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-4">
                                                                        <p className="mb-0 text-cool-gray">Order Qty</p>
                                                                        <input type="text" className="form-control mt-1" placeholder="Enter Quantity" value={`${get_order_wise_details.quantity} ${get_order_wise_details.Base_Unit_of_Measure}`} disabled/>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <p className="mb-0 text-cool-gray">Pending Qty</p>
                                                                        <input type="text" className="form-control mt-1" inputMode="numeric" pattern="[0-9]*"
                                                                        id="pending_quantity"  placeholder="Enter Pending Quantity" value= {inputValue && inputValue[get_order_wise_details.microsoft_item_system_id] !== undefined ? inputValue[get_order_wise_details.microsoft_item_system_id] : get_order_wise_details.pending_quantity}
                                                                        onChange={(e) => setPendingQuantity(e, get_order_wise_details.microsoft_item_system_id)} />
                                                                    </div>
                                                                    <div className='col-2 mt-4'>
                                                                        {orderEditLoader === true ? (
                                                                            <div className="d-inline-flex align-items-center">
                                                                                <Loader spinner={'cart-button'} className={'btn btn-primary'} />
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <button type="button" className="btn btn-primary  mx-1" value={inputValue && inputValue[get_order_wise_details.microsoft_item_system_id] !== undefined ? inputValue[get_order_wise_details.microsoft_item_system_id] : get_order_wise_details && get_order_wise_details.quantity} onClick={(e) => updateOrder(e, inputValue && inputValue[get_order_wise_details.microsoft_item_system_id], get_order_wise_details.No, getOrderWiseDetails.sales_order_no)}> Submit </button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal fade order_delete" id={`order_delete${get_order_wise_details.microsoft_item_system_id}`} tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">                    
                                                    <div className="modal-dialog modal-dialog-centered mobile-full-modal">
                                                        <div className="modal-content">
                                                            <a type="button" className="close text-end mr-1" style={{ fontSize: '20px', color: 'black' }} data-bs-dismiss="modal" aria-label="Close">X</a>
                                                            <div className="modal-body h4">
                                                                Are You Sure You Want Cancel Order?
                                                            </div>
                                                            <div className="text-end mb-4 mx-4">
                                                                {orderCancelLoader === true ? (
                                                                    <>
                                                                        <div className="d-inline-flex align-items-center">
                                                                            <Loader spinner={'cancel-order'} className={'btn btn-primary'} />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button type="button" className="btn btn-primary" onClick={(e) => cancelOrder(e, get_order_wise_details.No, getOrderWiseDetails.sales_order_no)}> Cancel Order </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            ):(
                                <section className="mob-pt-0 mob-pb-0 mt-5">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-12 text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}>
                                          <path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z" />
                                        </svg>
                                        <h3>There Are No My Order Detail.</h3>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                            )}
                        </div>
                    </section>
                    <div className="text-center mt-3">
                    </div>
                    <section style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                        <div className="container">
                            <div className="FlatCard border text-start">
                                <div className="d-flex flex-wrap px24 py8 border-bottom mb-2">
                                    <h5>Payment Summary</h5>
                                </div>
                                <div className="no-gutters">
                                    <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0">Total Amount</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0">₹ {getOrderWiseDetails.total_amount}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0">Total Tax</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0">₹ {getOrderWiseDetails.total_gst_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0">Total Shipping</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0">₹ 00</p>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0">Total Coupon Discount</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0"> ₹ 00</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between relative px24 py8 border-top mt-2">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0">Amount Payable</h5>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0"> Rs. {(parseFloat(getOrderWiseDetails.total_amount)+parseFloat(getOrderWiseDetails.total_gst_amount)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <Loader />
            )}
        </React.Fragment>
    )
}
