import React, { useCallback, useEffect, useState, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { toast } from 'react-toastify';
import { apiget, apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Layout/Loader';

export default function Cart({ getCart, getCartDetails, getWishList, getWishListItems, getNotifications }) {
  const { token, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate, isBackEndLogin, deviceType } = useAuth();
  const cartItems = getCartDetails.get_cart;

  const [activeAddress, setActiveAddress] = useState('');
  const [getAddress, setGetAddress] = useState('');
  const [delivery_address_id, setDeliveryAddressId] = useState('');
  const [getPhoneNo, setGetPhoneNo] = useState(localStorage.getItem('loginUserPhoneNo'));
  const [errors, setErrors] = useState({});
  const [addAddressError, setAddAddressError] = useState('');
  const [full_name, setFullName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [pincode, setPincode] = useState('');
  const [state_id, setStateId] = useState('');
  const [city, setCity] = useState('');
  const [house_no_building_name, setHouseNoBuildingName] = useState('');
  const [address, setAddress] = useState('');
  const [type_of_address, setTypeOfAddress] = useState('');
  const [get_states, setGetStates] = useState('');
  const [placeOrderLoader, setPlaceOrderLoader] = useState(false);
  const [addAddressLoader, setAddAddress] = useState(false);
  const [addNewAddressLoader, setaddNewAddressLoader] = useState(false);
  const hasFetchedscrollToTop = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    getCart();
    getWishList();
  }, []);


  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  };

  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);

  const editAddressStatusChange = async (address_id) => {
    let data = {
      address_id: address_id
    }
    const result = await apipost('address/change-default-address', data);
    if (result && result.status === false && result.error_type === 'check_token') {
      updateUserTokenValidate(false);
    }
    if (result && result.data.status === true) {
      getAddressDetails();
    }
  };

  const getAddressDetails = useCallback(async () => {
    const result = await apiget('address/get-address');
    if (result && result.status === false && result.error_type === 'check_token') {
      updateUserTokenValidate(false);
    }
    if (result && result.data && result.data.status === true) {
      const getActiveAddress = result.data.data.find(get_address => get_address.default === 1);
      setActiveAddress(getActiveAddress);
      setGetAddress(result.data.data);
    }
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

  useEffect(() => {
    if (token) {
      getAddressDetails();
    }
  }, [token, getAddressDetails]);

  const handlePlaceOrder = async () => {
    setPlaceOrderLoader(true);
    try {
      let loginUserName = '';
      if (localStorage.getItem('role_type') == 'frontend') {
        loginUserName = localStorage.getItem('customerLoginName');
      } else {
        loginUserName = localStorage.getItem('loginUserName');
      }
      
      let data = {
        total_amount: getCartDetails.amount_payable,
        quantity: getCartDetails.total_quantity,
        address_id: activeAddress && activeAddress.id.toString(),
        address_code: activeAddress && activeAddress.code,
        order_by: loginUserName
      }
      const result = await apipost('order/check-out', data);
      if (result?.status === 201 && result?.data && result?.data?.message) {
        toast.error(result.data.message);
        setPlaceOrderLoader(false);
      }
      if (result && result.status === false) {
        toast.error(result.message);
      }
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
        setPlaceOrderLoader(false);
      }
      if (result && result.errors && result.errors.length > 0 && result.status === false) {
        const errorObject = {};
        result.errors.forEach(err => {
          errorObject[err.field] = err.message;
        });
        setErrors(errorObject);
        setPlaceOrderLoader(false);
      }
      if (result && result.data && result.data.status === true) {
        setErrors({});
        toast.success(result.data.message);
        getCart();
        getNotifications();
        setAddAddressError('');
        navigate('/order/my-order');

        setPlaceOrderLoader(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPlaceOrderLoader(false);
    }
  };

  const addAddress = async (e) => {
    setaddNewAddressLoader(true);
    setAddAddress(false);
    e.preventDefault();
    let data = {
      full_name: full_name,
      phone_number: phone_number,
      pincode: pincode,
      state_id: state_id,
      city: city,
      house_no_building_name: house_no_building_name,
      address: address,
      type_of_address: type_of_address,
    };
    const result = await apipost('address/add-address', data);
    if (result.status === 201 && result.data && result.data.message) {
      setaddNewAddressLoader(false);
      setAddAddressError(result.data.message);
      setAddAddress(false);
    }
    if (result && result.status === false && result.error_type === 'check_token') {
      setaddNewAddressLoader(false);
      updateUserTokenValidate(false);
      setAddAddress(false);
    }
    if (result && result.type === "credential_error" && result.status === false) {
      setaddNewAddressLoader(false);
      //Handle errors
      setErrors({});
      setAddAddress(false);
    }
    if (result && result.errors && result.errors.length > 0 && result.status === false) {
      setaddNewAddressLoader(false);
      const errorObject = {};
      result.errors.forEach(err => {
        errorObject[err.field] = err.message;
      });
      setErrors(errorObject);
      setAddAddress(false);
    } else {
      if (result && result.data && result.data.status === true) {
        setaddNewAddressLoader(false);
        toast.success(result.data.message);
        const offcanvasElement = document.querySelector('#address-offcanvas');
        if (offcanvasElement) {
          offcanvasElement.click();
        } else {
          console.error("Element with ID 'address-offcanvas' not found.");
        }
        setAddAddressError('');
        setErrors({});
        getAddressDetails();
        setFullName('');
        setPhoneNumber('');
        setPincode('');
        setStateId('');
        setCity('');
        setHouseNoBuildingName('');
        setAddress('');
        setTypeOfAddress('');
        setGetStates('');
        getStates();
        setAddAddress(false);
      }
      setAddAddress(false);
    }
  };

  useEffect(() => {
    if (token) {
      getStates();
    }
  }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

  const getStates = async () => {
    const result = await apiget('address/get-states');
    if (result && result.status === false && result.error_type === 'check_token') {
      updateUserTokenValidate(false);
    }
    if (result && result.data.status === true) {
      setGetStates(result.data.data);
    }
  };

  return (
    <React.Fragment>
      {getCartDetails && getAddress && cartItems ? (
        placeOrderLoader === false ? (
          addAddressLoader === false ? (
            <React.Fragment>
              <div className="bodytop90 body-gray">
                {deviceType === "mobile" && (
                  <header className="login-header">
                    <nav className="nabar">
                      <div className="container-fluid">
                        <div className="row d-flex align-items-center justify-content-between">
                          <div className="col-lg-8 col-xl-8 col-12">
                            <div className="row align-items-center">
                              <div className="col-lg-8 col-xl-8 col-7 change-category">
                                <div className="row align-items-center">
                                  <div className="col-2">
                                    <Link onClick={() => navigate(-1)} className="text-dark"><i className="bi bi-chevron-left"></i></Link>
                                  </div>
                                  <div className="col-10 p-0">
                                    <Link type="button">
                                      <p className="text-elipsis p-600 text-dark mb-0">Check Out</p>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-xl-4 col-5 text-end">
                                <nav>
                                  <ul className="iconNavigation">
                                    <li>
                                      <Link to="/my-cart" className="active"><i className="bi bi-cart2"></i>
                                        <div className="cart-counter">{getCartDetails ? getCartDetails.total_items : 0}</div>
                                      </Link>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </header>
                )}
                <section className="pt-3 pb-0 mobile-hide">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-10">
                        <h1>{getCartDetails.total_items} Items in cart</h1>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="mob-p-0">
                  <div className="container">
                    <div className="row">
                      {/* <!--cart items--> */}
                      <div className="col-lg-8 col-xl-8 col-12">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item cart-accordian-item">
                            <h2 className="accordion-header" id="cartheading-1">
                              <button className="accordion-button cart-accordian-btn border-radius-imp cart-accordian-btn-first" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-4" aria-expanded="true" aria-controls="collapse-4" style={{ borderBottomLeftRadius: '24px', borderBottomRightRadius: '24px' }}>
                                <div className="step-counter">1</div> <p className="mb-0"><label className="mb-2">Login With</label> <br></br> +91 {getPhoneNo} <i className="bi bi-check-circle-fill text-success"></i></p>
                              </button>
                            </h2>
                          </div>
                            <form>
                            <div className="accordion-item cart-accordian-item">
                              <h2 className="accordion-header" id="cartheading-2">
                                <button className="accordion-button cart-accordian-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                  <div className="step-counter">2</div>Address Details<span className="ms-3">{errors.address_id && <span className="text-danger">{errors.address_id}</span>}</span>
                                </button>
                              </h2>
                              <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="cartheading-2" data-bs-parent="#accordionExample">
                                {getAddress ? getAddress.map((get_address_details, index) => {
                                  return (
                                      <div key={index} className="accordion-body">
                                        <ul className="catitemList">
                                          <li>
                                            <div className="row">
                                              <div className="col-lg-9 col-xl-9 col-12">
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id={`inlineRadio${index}`}
                                                    value={delivery_address_id}
                                                    checked={get_address_details.default === 1}
                                                    onClick={() => {
                                                      editAddressStatusChange(get_address_details.id); // Ensure correct usage of parameters
                                                    }}
                                                    onChange={() => {
                                                      const updatedAddress = [...getAddress];
                                                      updatedAddress[index].default = Number(!get_address_details.default);
                                                      setDeliveryAddressId(get_address_details.id);
                                                    }}
                                                  />
                                                  <label className="form-check-label" htmlFor={`inlineRadio${index}`}>
                                                    <p className="mb-2 p-600">
                                                      {get_address_details.full_name}
                                                      {get_address_details.type_of_address && (
                                                        <span className="address-type  mx-2">{get_address_details.type_of_address}</span>
                                                      )}
                                                    </p>
                                                    <p>
                                                    {get_address_details.address && get_address_details.address + ','} {get_address_details.house_no_building_name && get_address_details.house_no_building_name + ','} {get_address_details.city && get_address_details.city + ','} {get_address_details.description && get_address_details.description + '-'} {get_address_details.pincode}
                                                    </p>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                  )
                                }) : null}
                              </div>
                            </div>
                          </form><div className="accordion-item cart-accordian-item">
                            <h2 className="accordion-header" id="cartheading-3">
                              <button className="accordion-button cart-accordian-btn collapsed" id="address-offcanvas" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                <span className="text-primary"><i className="bi bi-plus-lg"></i> Add New Address</span>
                              </button>
                            </h2>
                            <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="cartheading-3"
                              data-bs-parent="#accordionExample">
                              <form onSubmit={addAddress}>
                                <div className="accordion-body">
                                  <div className="row">
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                      <div className="form-group">
                                        <input type="text" className="form-control" id="full_name" placeholder="Enter Full Name *" value={full_name}  onChange={(e) => setFullName(e.target.value)} />
                                        {errors.full_name && <span className="text-danger">{errors.full_name}</span>}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                      <div className="form-group">
                                        <input type="text" className="form-control" inputMode="numeric" pattern="[0-9]*" id="phone_number" placeholder="Enter Phone Number *" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} />
                                        {errors.phone_number && <span className="text-danger">{errors.phone_number}</span>}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                      <div className="form-group">
                                        <input type="text" className="form-control" id="pincode" placeholder="Enter Pincode *"  value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                        {errors.pincode && <span className="text-danger">{errors.pincode}</span>}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                      <div className="form-group">
                                        <select id="address_country1" name="address[country]" className="form-select" defaultValue="0" onChange={(e) => setStateId(e.target.value)}>
                                          <option value="0" label="Select a State *">Select a State</option>
                                          {get_states && get_states.map((get_state, index) => {
                                            return (
                                              <option key={index} value={get_state.code}>{get_state.description}</option>
                                            )
                                          })}
                                        </select>
                                        {errors.state_id && <span className="text-danger">{errors.state_id}</span>}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                      <div className="form-group">
                                        <input className="form-control" id="city" value={city} placeholder="Enter City *" onChange={(e) => setCity(e.target.value)} />
                                        {errors.city && <span className="text-danger">{errors.city}</span>}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                      <div className="form-group">
                                        <textarea className="form-control" placeholder="Enter Address *" value={address} onChange={(e) => setAddress(e.target.value)} />
                                        {errors.address && <span className="text-danger">{errors.address}</span>}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                      <div className="form-group">
                                        <textarea className="form-control" id="house_no_building_name" placeholder="Enter Address 2" value={house_no_building_name} onChange={(e) => setHouseNoBuildingName(e.target.value)} />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                      <label className="text-cool-gray mb-2">Address Type:</label>
                                      <div className="form-group">
                                      <select  id="address_type" name="address_type" className="form-select"  defaultValue="0" onChange={(e) => setTypeOfAddress(e.target.value)}  >
                                          <option value="0">Select Address Type</option>
                                          <option value="Home">Home</option>
                                          <option value="Work">Work</option>
                                      </select>
                                      </div>
                                      {addAddressError && <span className="text-danger">{addAddressError}</span>}
                                    </div>
                                    
                                    <div className="col-12 text-end">
                                      <div className="form-group mt-3 text-lg-end">
                                        {addNewAddressLoader === true ?
                                          <Loader spinner={'check-out-address'} className={'btn btn-primary pe-5 ps-5 float-end'} />:<button type="submit" className="btn btn-primary pe-5 ps-5">Save Address</button>
                                        } 
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="accordion-item cart-accordian-item">
                            <h2 className="accordion-header" id="cartheading-6">
                              <button className="accordion-button cart-accordian-btn collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                                <div className="step-counter">3</div> Order Summary
                              </button>
                            </h2>
                            <div id="collapse-6" className="accordion-collapse collapse" aria-labelledby="cartheading-6"
                              data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                {getCartDetails && getCartDetails.get_categories.map((get_category, index) => {
                                  return (
                                    <div key={index} className="accordion accordion-flush" id="accordionFlushExample">
                                      <div className="accordion-item payment-accordian-item">
                                        <h2 className="accordion-header" id="UPIPayment">
                                          <button className="accordion-button payment-accordian-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${index}`} aria-expanded="false" aria-controls="flush-collapseTwo">
                                            {get_category.category_name} ({get_category.category_count})
                                          </button>
                                        </h2>
                                        <div id={`flush-collapse-${index}`} className="accordion-collapse collapse" aria-labelledby="UPIPayment" data-bs-parent="#accordionFlushExample">
                                          <div className="accordion-body">
                                            <ul className="catitemList">
                                              {cartItems ? cartItems.map((cart_item, index) => {

                                                const validPrice = cart_item.get_item.Prices.find(System_Id => System_Id.SystemId === cart_item.sales_price_system_id);

                                                const price = validPrice && validPrice.Unit_Price;
                                                const item_total_price = price * cart_item.quantity;
                                                return (
                                                  get_category.category_id === cart_item.get_item.category_id && (
                                                    <li key={cart_item.get_item.Description}>
                                                      <div className="row align-items-center">
                                                        <div className="col-lg-2 col-xl-2 col-3 text-center">
                                                          <img src={cart_item.get_item.image ? cart_item.get_item.image : "../../../asset/img/no_image_available.jpg"} onError={(e) => { e.target.onerror = null; e.target.src = '../../../asset/img/no_image_available.jpg'; }} width="52" />
                                                        </div>
                                                        <div className="col-lg-10 col-xl-10 col-9">
                                                          <div className="row align-items-center">
                                                            <div className="col-lg-10 col-xl-10 col-sm-9 col-md-9 col-12">
                                                              <h5 className="mb-0">{cart_item.get_item.Description}</h5>
                                                              <p className="mb-0 text-muted">Multiples of {cart_item.qtyPerUnitOfMeasure} {cart_item.get_item.Base_Unit_of_Measure}</p>
                                                              <p>₹{price} * {cart_item.quantity} = {item_total_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )
                                                )
                                              }) : null}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item cart-accordian-item">
                            <h2 className="accordion-header" id="cartheading-5">
                              <button className="accordion-button cart-accordian-btn collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                                <div className="step-counter">4</div> Payment Options
                              </button>
                            </h2>
                            <div id="collapse-5" className="accordion-collapse collapse" aria-labelledby="cartheading-5"
                              data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                  <div className="accordion-item payment-accordian-item">
                                    <h2 className="accordion-header" id="cashonDelivery">
                                      <button className="accordion-button payment-accordian-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" defaultChecked />
                                        <span className='ms-1'>Credit</span>
                                      </button>
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--Product Gallery--> */}
                      {getCartDetails && getCartDetails.total_items >= 1 && (
                        <div className="col-lg-4 col-xl-4 col-12 mobile-hide">
                          <div className="cartSummary-table">
                            <div className="card-body">
                              <div className="no-gutters">
                                <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0">Item total</p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0">₹{getCartDetails && getCartDetails.total_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0">Product discount</p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0 text-success">- ₹00.00</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0">GST + Cess</p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0">₹{getCartDetails && getCartDetails.total_gst_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0">Delivery charge</p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <p className="mb-0"><img src="../../../asset/img/truck_free.png" width="24" /> FREE</p>
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer bg-white">
                                <div className="d-flex flex-wrap justify-content-between relative px24 py8">
                                  <div className="d-flex align-items-center">
                                    <h5><strong>Total</strong></h5>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <h5><strong>₹{(parseFloat(getCartDetails && getCartDetails.total_amount)+parseFloat(getCartDetails.total_gst_amount)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></h5>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap">
                                  <Link onClick={handlePlaceOrder} className="btn btn-primary w-100">
                                    <div className="d-flex flex-wrap justify-content-between">
                                      <div className="d-flex align-items-center">Place Order</div>
                                      <div className="d-flex align-items-center">
                                        <i className="bi bi-chevron-right"></i>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </section>

                {getCartDetails.total_items  > 0 && (
                  <footer className="mobile-footer-nav">
                    <div className="row pt-2 pb-2 ps-3 pe-4 align-items-center">
                      <div className="col-5">
                        <h5 className="mb-0"><strong>₹{(parseFloat(getCartDetails && getCartDetails.total_amount)+parseFloat(getCartDetails.total_gst_amount)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></h5>
                      </div>
                      <div className="col-7">
                        <Link onClick={handlePlaceOrder} className="btn btn-primary w-100" >
                          <div className="d-flex flex-wrap justify-content-between">
                            <div className="d-flex align-items-center p-15 text-center">Place Order</div>
                            <div className="d-flex align-items-center"><i className="bi bi-chevron-right"></i></div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </footer>
                )}
              </div>
            </React.Fragment>
          ) : (
            <Loader />
          )
        ) : (
          <Loader />
        )
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}