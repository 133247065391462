import React, { useEffect, useState, useCallback, useRef } from 'react';
import { apiget, apipost } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function ProfileSetting({ getCartDetails }) {
    const { token, isLoggedIn, updateToken, userTokenValidate, updateUserTokenValidate, deviceType } = useAuth();
    const navigate = useNavigate();

    const [getUser, setGetUser] = useState('');
    const [full_name, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobilePhoneNo, setMobilePhoneNo] = useState('');
    const hasFetchedscrollToTop = useRef(false);
    const hasFetchedUserDetails = useRef(false);
    const [userDetailsLoader, setUserDetailsLoader] = useState(false);
    const [errors, setErrors] = useState({});

    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    };
    
    useEffect(() => {
      if (!hasFetchedscrollToTop.current) {
        scrollToTop();
        hasFetchedscrollToTop.current = true;
      }
    }, [scrollToTop]);

    const getUserDetails = useCallback(async () => {
        const result = await apiget('user/last-login');
        if (result && result.status === false && result.error_type === 'check_token') {
            updateUserTokenValidate(false);
        }
        if (result && result.data.status === true) {
            setEmail(result.data.data.E_Mail);
            setFullName(result.data.data.Name);
            setMobilePhoneNo(result.data.data.Mobile_Phone_No);
            setGetUser(result.data.data);
        }
    }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

    const editUserDetails = async (e) => {
        setUserDetailsLoader(true);
        e.preventDefault();
        let data = {
            Name: full_name,
            E_Mail: email,
        };
        const result = await apipost('user/edit-user-profile', data);
        if (result && result.status === false && result.error_type === 'check_token') {
            updateUserTokenValidate(false);
            setUserDetailsLoader(false);
        }
        if (result && result.type === "credential_error" && result.status === false) {
            setErrors({});
            setUserDetailsLoader(false);
        }
        if (result && result.errors && result.errors.length > 0 && result.status === false) {
            const errorObject = {};
            result.errors.forEach(err => {
                errorObject[err.field] = err.message;
            });
            setErrors(errorObject);
            setUserDetailsLoader(false);
        } else {
            if (result && result.data && result.data.status === true) {
                toast.success(result.data.message);
                setErrors({});
                getUserDetails();
                setFullName('');
                setEmail('');
                setUserDetailsLoader(false);
                document.getElementById('profileEdit-offcanvas').click();
            }
        }
    };

    useEffect(() => {
      if (!hasFetchedUserDetails.current) {
        if (token) {
            getUserDetails();
        }
        hasFetchedUserDetails.current = true;
      }
    }, [token, getUserDetails]);

    return (
        <React.Fragment>
            {getUser && getUser ? (
                <div className="bodytop90">
                    <MobileHeader header_title={'Profile Setting'} getCartDetails={getCartDetails}/>
                    <section className="mob-pt-0 mob-pb-0">
                        <div className="container mb-3 mobile-hide">
                            <div className="row align-items-center">
                                <div className="col-10">
                                    <h1 className="Heading-Medium mb-3">Profile Setting </h1>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="FlatCard text-start stretch-wrapper">
                                        <div className="card-header mb-2 bg-white border-bottom-dashed">
                                            <div className="row align-items-center">
                                                <div className="col-lg-9 col-xl-9 col-12">
                                                    <h3 className="p-600">{getUser.Name}</h3>
                                                </div>
                                                <div className="col-lg-3 col-xl-3 text-end">
                                                    <a href="#" data-bs-toggle="offcanvas" data-bs-target="#profileEdit"
                                                        aria-controls="profileEdit"><i className="bi bi-pencil-square"></i> Edit</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row align-items-center mb-2">
                                                <div className="col-12 mb-3">
                                                    <h5 className="p-600">Personal Details</h5>
                                                    <p className="mb-0">{getUser.Name}</p>
                                                    <p className="mb-0">{getUser.Mobile_Phone_No}</p>
                                                    <p className="mb-0">{getUser.E_Mail}</p>
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <h5 className="p-600">Outlet Details</h5>
                                                    <p className="mb-0">PAN Number : {getUser.P_A_N_No} <i className="bi bi-patch-check-fill text-success"></i> </p>
                                                    <p className="mb-0">GST Number : {getUser.GST_Registration_No} <i className="bi bi-patch-check-fill text-success"></i> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="profileEdit" aria-labelledby="profileEditLabel">
                        <div className="offcanvas-header">
                            <h4 className="offcanvas-title" id="accountDrawerLabel">Edit details</h4>
                            <a type="button" id="profileEdit-offcanvas" className="close text-end mr-1 text-reset" style={{ fontSize: '20px', color: 'black' }} data-bs-dismiss="offcanvas" aria-label="Close">X</a>
                        </div>
                        <div className="offcanvas-body body-gray">
                            <div className="FlatCard bg-white text-start p-0 rounded24 mb-4">
                                <div className="card-header mb-2 bg-white pt-3 pb-3" style={{ borderTopRightRadius: '24px', borderTopLeftRadius: '24px' }}>
                                    <div className="row align-items-center">
                                        <div className="col-2 text-end">
                                            <img src='../../../asset/img/footer-nav/contact-details.svg' />
                                        </div>
                                        <div className="col-10">
                                            <h5 className="Heading-vSmall mb-0">Personal details</h5>
                                            <p className="text-cool-gray mb-0">for your account</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={editUserDetails}>
                                        <div className="form-group">
                                            <label className="text-cool-gray mb-2">User Name</label>
                                            <input type="text" className="form-control" id="full_name" value={full_name} onChange={(e) => setFullName(e.target.value)} />
                                            {errors.Name && <span className="text-danger">{errors.Name}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label className="text-cool-gray mb-2">Login phone number</label>
                                            <input type="text" className="form-control bg-white" id="phone_number" value={mobilePhoneNo} onChange={(e) => setMobilePhoneNo(e.target.value)} disabled/>
                                            {errors.Mobile_Phone_No && <span className="text-danger">{errors.Mobile_Phone_No}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label className="text-cool-gray mb-2">Email address</label>
                                            <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            {errors.E_Mail && <span className="text-danger">{errors.E_Mail}</span>}
                                        </div>
                                        
                                        {userDetailsLoader === true ? (
                                            <Loader spinner={'user_details'} />
                                        ) : (
                                        <   button type="submit" className="btn btn-primary bnt-sm w-100">Update</button>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </React.Fragment>
    );
}
