import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useAuth } from '../../../service/AuthContext';
import { apipost } from '../../../service/API';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function SalesPayment({ getCart, getCartDetails, getWishList }) {
  const { token, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate, deviceType } = useAuth();

  const [postedLedgerEntries, setPostedLedgerEntries] = useState('');
  const hasFetchedscrollToTop = useRef(false);
  const hasFetchedpostedLedgerEntry = useRef(false);
  const [spinner, setSpinner] = useState(false);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (token) {
  //     getCart();
  //     getWishList();
  //   }
  // }, [token]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  };

  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);

  const getPostedLedgerEntry = useCallback(async () => {
    setSpinner(true);
    const result = await apipost('customer/get-posted-ledger-entries');
    if (result && result.data && result.data.status === true) {
      setSpinner(false);
      setPostedLedgerEntries(result.data.data);
    }
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken]);

  const searchCustomer = useCallback(async (document_no) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(async () => {
      let data = {
        DocumentNo: document_no === '' ? '' : document_no
      };
      const result = await apipost('customer/search-payment', data);
      if (result && result.status === false && result.error_type === 'check_token') {
        updateUserTokenValidate(false);
      }
      if (result && result.data && result.data.status === true) {
        setPostedLedgerEntries(result.data.data);
      }
    }, 500);
  }, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

  useEffect(() => {
    if (!hasFetchedpostedLedgerEntry.current) {
      if (token) {
        getPostedLedgerEntry();
      }
      hasFetchedpostedLedgerEntry.current = true;
    }
  }, [token]);

  return (
    <React.Fragment>
      {postedLedgerEntries && spinner === false ? (
        <div className="bodytop90">
          <MobileHeader header_title={'Payment'}  getCartDetails={getCartDetails}/>
          <section className="mob-pt-0 mob-pb-0">
            <div className="container mobile-hide">
              <h1 className="Heading-Medium">Payment</h1>
              <div className="row mt-5 align-items-center mob-mb1">
                <div className="col-lg-6 col-xl-6 col-12">
                  <div className="form-group mb-0 mob-mb1">
                    <span className="icon-flag"><img src="../../../asset/img/searchThick.png" width="18" /></span>
                    <input type="text" className="form-control search-bar" placeholder="Search your need..." onChange={(e) => searchCustomer(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="container desktop-hide mobile-show">
              <div className="row align-items-center mob-mb1">
                <div className="col-auto">
                  <label htmlFor="inputPassword6" className="col-form-label">Group By :</label>
                </div>
                <div className="col-lg-2 col-xl-2 col-md-5 col-sm-5 col-9">
                  <select className="form-select">
                    <option>Bill</option>
                    <option>Date</option>
                  </select>
                </div>
              </div>
            </div>
          </section>
          <section>
            {postedLedgerEntries && postedLedgerEntries.length > 0 ? (
              <div className="container">
                <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                  {postedLedgerEntries && postedLedgerEntries.map((get_ledger_entry, index) => {
                    return (
                      <Link to={`/sales-payment-detail/${get_ledger_entry.SystemId_Ledger}`} key={get_ledger_entry.Entry_No} className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4 text-decoration-none">
                        <div className="FlatCard text-start">
                          <div className="card-header pe-0 ps-0 mb-3 bg-white border-bottom-dashed">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <p className="mb-0">{get_ledger_entry.Customer_Name}</p>
                              </div>
                              <div>
                                <h4 className="mb-0"><strong>₹ {get_ledger_entry.Amount && get_ledger_entry.Amount.toString().replace('-', '')}</strong></h4>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center mb-2">
                            <div className="col-8">
                              <p className="mb-0">{new Date(get_ledger_entry.PostingDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                              <p className="mb-0">Transaction ID : {get_ledger_entry.DocumentNo.split('/').pop()}</p>
                            </div>
                            <div className="col-4  text-end">
                              <label className="badge-regular badge-success">Clear</label>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            ) : (
              <section className="mob-pt-0 mob-pb-0 mt-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}><path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z" /></svg>
                      <h3>No Record Sales Payment.</h3>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </section>
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}
