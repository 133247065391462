import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../service/AuthContext';
import { apipost } from '../../../service/API';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function SalesInvoiceDetail({ getCart, getCartDetails, getWishList }) {
    const { token, isLoggedIn, updateToken, deviceType } = useAuth();

    const { sales_invoice_header_system_id } = useParams();
    const [salesInvoiceHeaderDetail, setSalesInvoiceHeaderDetail] = useState('');
    const [salesInvoice, setSalesInvoice] = useState('');
    const [salesInvoiceHeaderLedger, setSalesInvoiceHeaderLedger] = useState('');
    const hasFetchedscrollToTop = useRef(false);
    const haspostedSalesInvoiceDetail = useRef(false);

    const navigate = useNavigate();

    // useEffect(() => {
    //     if (token) {
    //         getWishList();
    //         getCart();
    //     }
    // }, [token, getWishList, getCart]);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    };
    
    useEffect(() => {
      if (!hasFetchedscrollToTop.current) {
        scrollToTop();
        hasFetchedscrollToTop.current = true;
      }
    }, [scrollToTop]);

    const getPostedSalesInvoiceDetail = useCallback(async () => {
        let data = {
            sales_invoice_header_system_id: sales_invoice_header_system_id
        }
        const result = await apipost('customer/get-posted-sales-invoice-detail', data);
        if (result && result.data && result.data.status && result.data.status === true) {
            setSalesInvoiceHeaderDetail(result.data.get_sales_invoice_header);
            setSalesInvoice(result.data.get_sales_invoice);
            setSalesInvoiceHeaderLedger(result.data.get_sales_invoice_header_ledger);
        }
    }, [token, navigate, isLoggedIn, updateToken, sales_invoice_header_system_id]);

    useEffect(() => {
      if (!haspostedSalesInvoiceDetail.current) {
        if (token) {
            getPostedSalesInvoiceDetail();
        }
        haspostedSalesInvoiceDetail.current = true;
      }
    }, [token, getPostedSalesInvoiceDetail]);

    return (
        <React.Fragment>
            {salesInvoiceHeaderDetail && salesInvoice ? (
                <div className="bodytop90 body-padding-bottom">
                    <MobileHeader header_title={'Sales Invoice Detail'} getCartDetails={getCartDetails}/>
                    <section className="pt-3 pb-0 mobile-hide">
                      <div className="container">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-1">
                              <Link onClick={() => { navigate(-1); setTimeout(() => { window.scrollTo(0, 0); }, 100); }}>
                                  <img src='../../../asset/img/Arrow-left.png' className="Heading-icon" height='70px'/>
                              </Link>
                          </div>
                          <div className="col-11">
                            <h2>Voucher No: {salesInvoiceHeaderDetail.No}</h2>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="mob-pt-0 mob-pb-0">
                        <div className="container">
                            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mb-4">
                                <div className="FlatCard text-start">
                                    <div className="card-header pe-0 ps-0 mb-3 bg-white border-bottom-dashed">
                                        <div className="row align-items-center">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-6">
                                                <p className="mb-0">{salesInvoiceHeaderDetail.No} | {new Date(salesInvoiceHeaderDetail.Posting_Date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6 text-end">
                                                <h4 className="mb-0"><strong>₹ {salesInvoiceHeaderDetail.TotalInclTaxAmount}</strong></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col-8">
                                            <p className="mb-0">Due Date - {new Date(salesInvoiceHeaderDetail.Due_Date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                        </div>
                                        <div className="col-4  text-end">
                                            <p className="mb-0 text-success">Paid Rs.{salesInvoiceHeaderDetail.TotalInclTaxAmount - salesInvoiceHeaderDetail.Remaining_Amount}</p>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <div className="col-4">
                                            <p className="mb-0 p-12">
                                                {salesInvoiceHeaderDetail.Payment_Terms_Code}
                                            </p>
                                        </div>
                                        <div className="col-3">

                                        </div>
                                        {salesInvoiceHeaderDetail.Remaining_Amount > 0 ? (
                                            <div className="col-5 text-end">
                                                <p className="mb-0 text-danger p-600">Unpaid Rs.{salesInvoiceHeaderDetail.Remaining_Amount}</p>
                                            </div>
                                        ) : (
                                            <div className="col-5 text-end">
                                                <p className="mb-0 text-danger p-600">Paid</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-8">
                                        </div>
                                        <div className="col-4 text-end">
                                            <Link className="me-2"><img src="../../../asset/img/whatsapp.png" width="32" /></Link>
                                            <Link><img src="../../../asset/img/envelope-red.png" width="32" /></Link>
                                        </div>
                                    </div>
                                    <div className="card-footer p-0 pt-2 bg-white border-top-dashed">
                                        <div className="row align-items-center">
                                            <div className="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-3"><h6 className="mb-0">PRAMOT</h6></div>
                                            {salesInvoiceHeaderDetail && salesInvoiceHeaderDetail.date_difference ? (
                                                <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-4">
                                                    <p className="mb-0 p-12 text-cool-gray">Overdue by {salesInvoiceHeaderDetail.date_difference} days</p>
                                                </div>
                                            ) : (
                                                <div className="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-4">
                                                    <p className="mb-0 p-12 text-cool-gray">

                                                    </p>
                                                </div>
                                            )}
                                            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-5">
                                                <div className="form-check float-end">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled={salesInvoiceHeaderDetail.Remaining_Amount === '0'} />
                                                    <label className="form-check-label p-12" htmlFor="flexCheckDefault">
                                                        Select to Pay
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="p-0">
                        <div className="container">
                            <ul className="nav nav-tabs sales-nav-tab" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="Itemdetails-tab" data-bs-toggle="tab" data-bs-target="#Itemdetails" type="button" role="tab" aria-controls="Itemdetails" aria-selected="true">Item Details</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="Schemes-tab" data-bs-toggle="tab" data-bs-target="#Schemes" type="button" role="tab" aria-controls="Schemes" aria-selected="false">Schemes <span className="badge-counter">1</span></button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active pt-3 pb-3" id="Itemdetails" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="FlatCard rounded12 text-start border mb-4">
                                        <ul className="ItemsList-sales">
                                            {salesInvoice && salesInvoice.map((get_sales_invoice) => {
                                                return (
                                                    <li key={get_sales_invoice.SystemId_Lines}>
                                                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                            <div className="flex-item align-items-center">
                                                                <p className="mb-1">{get_sales_invoice.No}</p>
                                                                <p className="mb-1">{get_sales_invoice.Description}</p>
                                                                <p className="mb-1">{get_sales_invoice.Quantity_Base > 0 ? get_sales_invoice.Quantity_Base + get_sales_invoice.Base_Unit_of_Measure + '| Rate/pc: ' : ''} <strong> {get_sales_invoice.Unit_Price_Base > 0 ? '₹ ' + get_sales_invoice.Unit_Price_Base : ''}</strong></p>
                                                            </div>
                                                            <div className="flex-item align-items-center  text-end">
                                                                <h5 className="mb-1">{get_sales_invoice.Line_Amount > 0 ? '₹ ' + get_sales_invoice.Line_Amount : ''}</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                            <li>
                                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                    <div className="flex-item align-items-center">
                                                        <strong>Net Amount</strong>
                                                    </div>
                                                    <div className="flex-item align-items-center  text-end">
                                                        <h5><strong>₹ {salesInvoiceHeaderDetail.Amount}</strong></h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                    <div className="flex-item align-items-center">
                                                        <strong>Tax Amount</strong>
                                                    </div>
                                                    <div className="flex-item align-items-center  text-end">
                                                        <h5><strong>₹ {salesInvoiceHeaderDetail.GSTAmount}</strong></h5>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                    <div className="flex-item align-items-center">
                                                        <h5><strong>Invoice Amount</strong></h5>
                                                    </div>
                                                    <div className="flex-item align-items-center  text-end">
                                                        <h5><strong>₹ {salesInvoiceHeaderDetail.TotalInclTaxAmount}</strong></h5>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {salesInvoiceHeaderLedger.length > 0 && (
                                        <div className="FlatCard rounded12 text-start border mb-4">
                                            <ul className="ItemsList-sales">
                                                <li>
                                                    {salesInvoiceHeaderLedger && salesInvoiceHeaderLedger.map((get_sales_invoice_header_ledger, index) => (
                                                        <div key={get_sales_invoice_header_ledger.SystemId} className="d-flex flex-wrap justify-content-between align-items-center">
                                                            <div className="flex-item align-items-center">
                                                                <p className="mb-1">{get_sales_invoice_header_ledger.DocumentNo} <span className="ms-5">{get_sales_invoice_header_ledger.PostingDate}</span>  <span className="ms-5">{Math.abs(get_sales_invoice_header_ledger.Amount).toFixed(2)}</span></p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    {salesInvoiceHeaderDetail.Remaining_Amount > 0 && (
                                        <div className="row justify-content-center">
                                            <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12">
                                                <Link to="/ammount-to-pay" type="button" className="btn btn-primary w-100 mb-5">Pay Now</Link>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row justify-content-center mobile-hide">
                                        <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12">
                                            <div className="FlatCard fullrounded">
                                                <div className="card-body">
                                                    <ul className="buttonLisitng">
                                                        <li><Link><img src="../../../asset/img/pdf-red-icon.png" width="48" /></Link></li>
                                                        <li><Link><img src="../../../asset/img/whatsapp-green-icon.png" width="48" /></Link></li>
                                                        <li><Link><img src="../../../asset/img/envelope-red-icon.png" width="48" /></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {deviceType === "mobile" && (
                                        <div className="row justify-content-center" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                                            <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12">
                                                <div className="FlatCard fullrounded">
                                                    <ul className="buttonLisitng">
                                                        <li><a href="#"><img src="../../../asset/img/pdf-red-icon.png" width="35"/></a></li>
                                                        <li><a href="#"><img src="../../../asset/img/whatsapp-green-icon.png" width="35"/></a></li>
                                                        <li><a href="#"><img src="../../../asset/img/envelope-red-icon.png" width="35"/></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="tab-pane fade pt-3 pb-3" id="Schemes" role="tabpanel" aria-labelledby="profile-tab">

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <Loader />
            )}
        </React.Fragment>
    )
}
