import React, { useEffect, useState, useCallback, useRef } from 'react'
import {useNavigate } from 'react-router-dom';
import { apiget } from '../../../service/API';
import { useAuth } from '../../../service/AuthContext';
import Loader from '../Layout/Loader';
import MobileHeader from '../Layout/MobileHeader';

export default function AmmountToPay( getCart, getCartDetails, getWishList) {
  const { token, updateUserTokenValidate } = useAuth();

  const [getInvoices, setGetInvoices] = useState('');
  const [getTotalAmount, setTotalAmount] = useState('');
  const hasFetchedscrollToTop = useRef(false);
  const hasFetcheduserDetails = useRef(false);
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  };

  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);

  const getUserDetails = useCallback(async () => {
    const result = await apiget('customer/ammount-to-pay');

    if (result && result.status === false) {
      updateUserTokenValidate(false);
    }
    if (result && result.data.status === true) {
      setGetInvoices(result.data.data);
      setTotalAmount(result.data.total_amount);
    }
  }, [updateUserTokenValidate]);

  useEffect(() => {
    if (!hasFetcheduserDetails.current) {
      if (token) {
        getUserDetails();
      }
      hasFetcheduserDetails.current = true;
    }
  }, [token, getUserDetails]);

  return (
    <React.Fragment>
      {getInvoices && getInvoices.length > 0 && getTotalAmount ? (
        <div className="bodytop90 body-padding-bottom">
          <MobileHeader header_title={'Ammount To Pay'}   getCartDetails={getCart.getCartDetails}/>
          <section className="mob-pt-0 mob-pb-0">
            <div className="container">
              <div className="row justify-content-center align-items-center mb-2">
                <div className="col-1"> 
                    <img src='../../../asset/img/Arrow-left.png' className="Heading-icon  mobile-hide" height='70px' 
                    onClick={() => { navigate(-1); setTimeout(() => { window.scrollTo(0, 0); }, 100); }}/>
                </div> 
                <div className="col-11">
                  <h1 className="Heading-Medium mobile-hide">Ammount To Pay</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-7 col-sm-6 col-md-4 col-lg-2">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Enter Amount"></input>
                  </div>
                </div>
              </div>
              <p>You will get benefit of Rs. 5676.33</p>
              <div className="row align-items-center mb-4">
                <div className="col-lg-2 col-xl-2 col-5">
                  <div className="FlatCard p-0">
                    <div className="card-header bg-white">
                      Rs. {getTotalAmount}
                    </div>
                    <div className="card-footer bg-light-green text-white">
                      Full amount
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-xl-10 col-7">
                  Pay in full & get benefit of Rs. 12,323.40
                </div>
              </div>
              <div className="border-top border-bottom pt-3 pb-3 mb-4">
                <p className="mb-0">Invoice details: 2 invoices selected</p>
              </div>

              <div className="row justify-content-center">
                {getInvoices && getInvoices.map((get_invoice) => {
                  return (
                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mb-4" key={get_invoice.id}>
                      <div className="FlatCard text-start">
                        <div className="row align-items-center mb-2">
                          <div className="col-6">
                            <p><strong>{get_invoice.No}</strong></p>
                            <p className="mb-0">Inv Amount: Rs. {get_invoice.TotalInclTaxAmount}</p>
                            <p className="mb-0 text-muted">
                              Date: {new Date(get_invoice.Posting_Date). toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/,/g, '')}
                            </p>
                          </div>
                          <div className="col-6  text-end">
                            <p><strong>Rs. {get_invoice.Remaining_Amount}</strong></p>
                            <p className="text-muted mb-2">Due Date: {new Date(get_invoice.Due_Date). toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/,/g, '')}</p>
                            <label className="badge-regular badge-success p-12">Will be fully settled</label>
                          </div>
                        </div>

                        <div className="card-footer p-0 pt-2 bg-white border-top-dashed">
                          <div className="row align-items-center">
                            <div className="col-10">
                              <label className="badge-green text-success">Discount: Rs. 3848.82 (5% CD)</label>
                            </div>

                            <div className="col-2">
                              <div className="form-check float-end">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" defaultChecked />
                                <label className="form-check-label p-12" htmlFor="flexCheckDefault">
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12">
                  <button type="button" className="btn btn-primary w-100 mb-5">Pay Now</button>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}
