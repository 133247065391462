import React from 'react';
import OwlCarousel from 'react-owl-carousel';

export default function Banner({ banners }) {
  // Banner Owl Carousel Setting
  const BannerOwlCarouselSetting = {
    loop: false,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      },
    }
  }

  return (
    <React.Fragment>
      <section className="Desktop-Slider">
        <div className="container">
          <OwlCarousel className="homepage-carousel owl-carousel owl-theme owl-loaded owl-drag" {...BannerOwlCarouselSetting}>
            {banners && banners.map((get_banners) => {
              if (get_banners.banner_type === "With Login") {
                return (
                  <div className="item" key={get_banners.id}>
                    <img src={get_banners.image ? get_banners.image : '../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../asset/img/no_image_available.jpg'; e.target.style.width = '1300px'; e.target.style.height = '170px';}} className="img-fluid" />
                  </div>
                )
              }
            })}
          </OwlCarousel>
        </div>
      </section>

      <section className="Mobile-Slider">
        <div className="container">
          <OwlCarousel className="homepage-carousel owl-carousel owl-theme owl-loaded owl-drag" {...BannerOwlCarouselSetting}>
            {banners && banners.map((get_banners) => {
              if (get_banners.banner_type === "With Login") {
                return (
                  <div className="item" key={get_banners.id}>
                    <img src={get_banners.image ? get_banners.image : '../asset/img/no_image_available.jpg'} onError={(e) => { e.target.onerror = null; e.target.src = '../asset/img/no_image_available.jpg'; }} className="img-fluid" />
                  </div>
                )
              }
            })}
          </OwlCarousel>
        </div>
      </section>
    </React.Fragment>
  )
}
