import React, { useCallback, useState, useEffect, useRef } from "react";
import { apiget, apipost } from "../../../service/API";
import { toast } from "react-toastify";
import { useAuth } from "../../../service/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Layout/Loader";

export default function CustomerList() {
  const { token, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate, updateLoginUserName, isBackEndLogin, deviceType, getCustomerType } = useAuth();
  const hasFetchedscrollToTop = useRef(false);
  const hasFetchedGetCustomer = useRef(false);
  const [getCustomer, setGetCustomer] = useState("");
  const timeoutRef = useRef(null);
  const [searchLoader, setSearchLoader] = useState(false);
  const navigate = useNavigate();

  const getCustomers = useCallback(async () => {
    const result = await apipost("customer/get-customer");
    if (result && result.status === false && result.error_type === "check_token") {
      updateUserTokenValidate(false);
    }
    if (result && result.data && result.data.status === false) {
      toast.error(result.data.message);
    }

    if (result && result.data && result.data.status === true) {
      setGetCustomer(result.data.data);
    }
  }, [token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate]);

  const handleLogin = useCallback(
    async (mobile_phone_no) => {
      let data = {
        Mobile_Phone_No: mobile_phone_no,
        otp: "334455",
      };
      const result = await apipost("verify-otp", data);

      if (result && result.status === false && result.error_type === "check_token") {
        updateUserTokenValidate(false);
      }
      if (result && result.data && result.data.status === false) {
        toast.error(result.data.message);
      }

      if (result && result.data && result.data.status === true) {
        const customer_token = localStorage.getItem("token");
        const customer_login_name = localStorage.getItem("loginUserName");
        const customer_phone_no = localStorage.getItem("loginUserPhoneNo");
        localStorage.setItem("customer_token", customer_token);
        localStorage.setItem("role_type", "frontend");
        localStorage.setItem("customerLoginName", customer_login_name);
        localStorage.setItem("customerPhoneNo", customer_phone_no);
        localStorage.setItem('customer_type', result.data.data.customer_type);
        localStorage.setItem("token", result.data.data.token);
        localStorage.setItem("loginUserName", result.data.data.Name);
        localStorage.setItem("loginUserPhoneNo", result.data.data.Mobile_Phone_No);
        localStorage.setItem("loginUserPhoneNo", result.data.data.Mobile_Phone_No);
        localStorage.setItem("isLoggedIn", "true");
        getCustomerType(result.data.data.customer_type);
        updateLoginUserName(result.data.data.Name);
        isLoggedIn("true");
        isBackEndLogin("true");
        navigate("/");
      }
    },
    [ token, navigate, isLoggedIn, updateToken, updateUserTokenValidate, userTokenValidate ]
  );

  const searchCustomer = useCallback(async (customerName) => {
    setSearchLoader(true);

    if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(async () => {
        let data = {
          customer_name: customerName === '' ? '' : customerName
        };
        const result = await apipost('customer/search-customer', data);
        if (result && result.status === false && result.error_type === 'check_token') {
            setSearchLoader(false);
            updateUserTokenValidate(false);
        }
        if (result && result.data && result.data.status === true) {
            setSearchLoader(false);
            setGetCustomer(result.data.data);
        }
    }, 500);
}, [token, navigate, isLoggedIn, isBackEndLogin, updateToken, updateUserTokenValidate, userTokenValidate]);

  useEffect(() => {
    if (!hasFetchedGetCustomer.current) {
      getCustomers();
      hasFetchedGetCustomer.current = true;
    }
  }, [getCustomers]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  useEffect(() => {
    if (!hasFetchedscrollToTop.current) {
      scrollToTop();
      hasFetchedscrollToTop.current = true;
    }
  }, [scrollToTop]);

  return (
    <React.Fragment>
      {getCustomer ? (
        <div className="bodytop90">
              <section className="mob-pt-0 mob-pb-0">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-xl-3 col-6">
                      <div className="card rounded12 bg-light-green text-white">
                        <div className="card-body">
                          <h5>
                            Due <i className="bi bi-arrow-right"></i>
                          </h5>
                          <h2>
                            <strong>₹ {getCustomer.total_due}</strong>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-xl-3 col-6">
                      <div className="card rounded12 bg-light-red text-white">
                        <div className="card-body">
                          <h5>
                            Overdue <i className="bi bi-arrow-up"></i>
                          </h5>
                          <h2>
                            <strong>₹ {getCustomer.total_overdue}</strong>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-0">
                    <div className="row mt-5 align-items-center mob-mb1">
                      <div className="col-lg-6 col-xl-6 col-12">
                        <div className="form-group mb-0 mob-mb1">
                          <span className="icon-flag"><img src="../../../asset/img/searchThick.png" width="18" /></span>
                          <input type="text" className="form-control search-bar" placeholder="Search your need..." onChange={(e) => searchCustomer(e.target.value)}/>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-12 justify-content-end" style={{ marginTop: deviceType === 'mobile' ? '20px' : '' }}>
                          <div className="d-flex align-items-center">
                          <label htmlFor="groupBySelect" className="col-form-label mb-0 me-2" style={{ marginBottom: '0' }}>Short By :</label>
                              <select id="groupBySelect" className="form-select mb-0" style={{ maxWidth: '252px', marginLeft: '8px' }}>
                                  <option>Company Name (A-Z)</option>
                                  <option>Company Name (Z-A)</option>
                                  <option>Amount (Ascending)</option>
                                  <option>Amount (Descending)</option>
                              </select>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mob-mb1 mt-3">
                    <ul className="MyorderTab mob-mb1">
                      <li><a href="#" className="active">Detailed Summary</a></li>
                      <li><a href="#">Ageing Summary</a></li>
                    </ul>
                  </div>
                </div>
              </section>
              
          {getCustomer.get_customers.length > 0 ? (
            <React.Fragment>
              <section className="p-0">
                <div className="container">
                  <div className="row" style={{ marginBottom: deviceType === 'mobile' ? '75px' : '' }}>
                    {getCustomer.get_customers.map((get_customer) => (
                      <div key={get_customer.id} className="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                        <div className="FlatCard text-start">
                          <div className="card-header pe-0 ps-0 mb-3 bg-white border-bottom-dashed">
                            <div className="row">
                              <div className="col-7">
                                <p className="mb-0">{get_customer.Name}</p>
                                <p className="p-12 mb-0"><strong>Due</strong>: <strong>₹ {get_customer.Balance_LCY}</strong></p>
                              </div>
                              <div className="col-3 text-end">
                                <p className="p-10 mb-0 text-success">Short Payment</p>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-3">
                              <p className="p-12 mb-0 text-cool-gray">Oldest Due</p>
                              <p className="p-12 mb-0">1867 Days</p>
                            </div>
                            <div className="col-3">
                              <p className="p-12 mb-0 text-cool-gray">Avg. Collection</p>
                              <p className="p-12 mb-0">1565 Days</p>
                            </div>
                            <div className="col-3">
                              <p className="p-12 mb-0 text-cool-gray">Credit Period</p>
                              <p className="p-12 mb-0">{get_customer.Payment_Terms_Code}</p>
                            </div>
                            <div className="col-3">
                              <p className="p-12 mb-0 text-cool-gray">Last Payment</p>
                              {/* <p className="p-12 mb-0">{formatDate(get_customer.SystemModifiedAt)}</p> */}
                              <p className="p-12 mb-0">28 Jul 2024</p>
                            </div>
                          </div>
                          <div className="col-12 mt-3 d-grid">
                            <button type="submit" className="btn btn-primary" onClick={() => handleLogin(get_customer.Mobile_Phone_No)}>Order</button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </React.Fragment>
          ) : (
            <section className="mob-pt-0 mob-pb-0 mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 512" style={{ width: '50px', height: '50px' }}>
                      <path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.7-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.3 28.66 185.31 0 256 0zm17.75 342.25h29.15v29.32h-93.79v-29.32h28.76v-92.34h-28.76v-29.32h64.64v121.66zm-27.94-150.37c-7.08-.05-13.12-2.53-18.2-7.56-5.08-5.01-7.56-11.11-7.56-18.25 0-7.01 2.48-13.06 7.56-18.08 5.08-5.02 11.12-7.55 18.2-7.55 6.95 0 12.99 2.53 18.08 7.55 5.13 5.02 7.67 11.07 7.67 18.08 0 4.72-1.2 9.07-3.56 12.94-2.36 3.93-5.45 7.07-9.31 9.37-3.87 2.3-8.17 3.45-12.88 3.5zm171.9-97.59C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.33 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.38 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z" />
                    </svg>
                    <h3>There Are No Customer.</h3>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}
